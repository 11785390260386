import React, { useEffect, useState } from "react";
import { getInventoryCampaigns } from "../../services/micro";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import TablePagePlaceholder from "../../components/TablePagePlaceholder";
import { getToken } from "../../helpers/get-token";
import { useNavigate } from "react-router-dom";

export default function PublisherQueue() {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const refresh = urlParams.get('refresh');

  if (refresh) {
    navigate('/pub/inventory/queue');
  }

  const [data, setData] = useState([]);
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/inventory-campaigns/all?token=${token}`;
  
  useEffect(() => {
    getInventoryCampaigns().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("You don't have permission to access this page.");
    });
  }, []);

  return (
    <React.Fragment>
      { data?.table ?
        <DataTable 
          title="Queue"
          data={data}
          endpoint={endpoint}
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/queue"
          new_btn_title="New Inventory Campaign"
          new_btn_redirect="/pub/invetory/campaign/new"
          edit_redirect="/pub/invetory/campaign/edit"
          redirect="/pub/invetory/queue"
        />
        :
        <TablePagePlaceholder
          title="Queue"
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/queue"
          new_btn_title="New Inventory Campaign"
          new_btn_redirect="/pub/invetory/campaign/new"
          edit_redirect="/pub/invetory/campaign/edit"
          redirect="/pub/invetory/queue"
        />
      }
    </React.Fragment>
  )
}