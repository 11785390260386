import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Placeholder } from "react-bootstrap";
import { getPeriodRevenue } from "../../../services/micro";
import { min } from "moment/moment";

function getCSSVariableValue(variableName) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
}

export default function RevenueChart(props) {
  const { currentPeriod, previousPeriod } = props;
  const [response, setResponse] = useState(null);
  const [colors, setColors] = useState({ chartPrimary: '', chartSecondary: '' });
  const [isOneDayPicked, setIsOneDayPicked] = useState(false);

  // console.log(currentPeriod, previousPeriod);
  useEffect(() => {
    setColors({
      chartPrimary: getCSSVariableValue('--chart-secondary'),
      chartSecondary: getCSSVariableValue('--primary')
    });
  }, []);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (currentPeriod[0] && currentPeriod[1] && previousPeriod[0] && previousPeriod[1]) {
        getPeriodRevenue(currentPeriod, previousPeriod).then((res) => {
          setResponse(res.data);
        });
      }
    }
  }, [currentPeriod, previousPeriod]);

  useEffect(() => {
    const currentDate = new Date(response?.periods[0].data[0][0]);
    const previousDate = new Date(response?.periods[1].data[0][0]);
    
    if ((currentDate - previousDate) / (1000 * 60 * 60 * 24) === 1) {
      setIsOneDayPicked(true);
    } else {

      setIsOneDayPicked(false);
    }
  }, [response])

  const optionRevenue = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: false,
      zoom: { enabled: false },
    },
    colors: [colors.chartSecondary, colors.chartPrimary],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: { top: 100 },
      yaxis: { lines: { show: false } },
    },
    stroke: {
      curve: "smooth",
      width: [2, 0.25],
    },
    xaxis: {
      type: "datetime",
      axisBorder: { show: false },
      labels: {
        hideOverlappingLabels: false,
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
        formatter: function (val) {
          const date = new Date(val);
          return date.getUTCDate(); // Use UTC date
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        const currentDateValue = w.globals.seriesX[0][dataPointIndex];
        const currentDate = new Date(currentDateValue);

        // Format the current date to 'day month' (e.g., "1 Aug")
        const formattedCurrentDate = currentDate.toLocaleString('en-US', { day: 'numeric', month: 'short' });
        // Calculate the previous date by subtracting the length of seriesX[0] from currentDate
        const daysToSubtract = isOneDayPicked ? 1 : w.globals.seriesX[0].length;
        const previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate() - daysToSubtract);

        // Format the previous date to 'day month' (e.g., "1 Aug")
        const formattedPreviousDate = previousDate.toLocaleString('en-US', { day: 'numeric', month: 'short' });

        return (
          '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
          '<span class="apexcharts-tooltip-marker" style="background-color: var(--column-chart);"></span>' +
          '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
          '<div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">' +
          formattedCurrentDate +
          ': </span><span class="apexcharts-tooltip-text-y-value">$' +
          Number(series[0][dataPointIndex] ? series[0][dataPointIndex] : 0).toLocaleString() +
          '.00</span></div></div></div>' +
          '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 2; display: flex;">' +
          '<span class="apexcharts-tooltip-marker" style="background-color: var(--primary);"></span>' +
          '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
          '<div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">' +
          formattedPreviousDate +
          ': </span><span class="apexcharts-tooltip-text-y-value">$' +
          Number(series[1][dataPointIndex] ? series[1][dataPointIndex] : 0).toLocaleString() +
          '.00</span></div></div></div>'
        );
      },
    }


  };

  if (response && response.periods.length !== 0) {
    optionRevenue.xaxis.tickAmount = isOneDayPicked ? 1 : 15
    optionRevenue.xaxis.categories = response.periods[0].data.map((period) => period[0]);
    //place single date label in the middle
    optionRevenue.xaxis.labels.offsetX = isOneDayPicked ? 145 : -10;
    optionRevenue.yaxis.max = response.max_y;

    return (
      <React.Fragment>
        <Card className="card-one">
          <Card.Body className="overflow-hidden px-0 pb-3" id={response.max_y}>
            <div className="finance-info p-3 p-xl-4">
              <label className="fs-sm fw-medium mb-2 mb-xl-1 subtext">
                Revenue This Period
              </label>
              <h1 className="finance-value">
                <span>$</span>
                {response.total_revenue_for_current_period}
              </h1>

              <h4 className="finance-subvalue mb-3 mb-md-2">
                <i className="ri-arrow-up-line text-primary"></i>
                <span className="text-primary">
                  {response.diff_in_percentage}%
                </span>
                <small className="subtext">vs last period</small>
              </h4>
            </div>

            <ReactApexChart
              series={response.periods.map((period) => ({
                name: period.name,
                data: period.data.map((data) => data[1]),
              }))}
              options={optionRevenue}
              type="area"
              height={400}
              className="apex-chart-two"
            />
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
          <Placeholder style={{ height: 430, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
