import React, { createContext, useState, useEffect, useContext } from 'react';

export const LocalStorageKeyNames = {
    publisherId: 'publisher_id',
    advertiserId: 'advertiser-id',
    abbreviation: 'abbreviation',
    token: 'token',
    sidebarSkin: "sidebar-skin",
    skinMode: 'skin-mode',
};

export const LocalStorageContext = createContext();

export const LocalStorageProvider = ({ children }) => {
    const [localStorageData, setLocalStorageData] = useState(() => {
        const keys = Object.values(LocalStorageKeyNames);
        const data = {}; 
        
        keys.forEach((key) => {
            data[key] = localStorage.getItem(key);
        });

        return data;
    });

    const updateLocalStorageProperty = (key, value) => {
        setLocalStorageData((prevData) => {
            const newData = { ...prevData, [key]: value };

            localStorage.setItem(key, value); 

            return newData;
        });
    };

    const removeLocalStorageProperty = (key) => {
        setLocalStorageData((prevData) => {
            const newData = { ...prevData };
            delete newData[key];

            localStorage.removeItem(key); 

            return newData;
        });
    };


    return (
        <LocalStorageContext.Provider
            value={{ localStorageData, updateLocalStorageProperty, removeLocalStorageProperty }}
        >
            {children}
        </LocalStorageContext.Provider>
    );
};

export const useLocalStorageContext = () => useContext(LocalStorageContext);
