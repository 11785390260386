import { createNewCampaign, createNewCustomer } from "../services/adv";
import { createInventoryCampaign, createUser, loadImages } from "../services/micro";
import { createNewCategory, createNewInventory, createNewWebsite, createPublisherInventory } from "../services/pub";

async function createNewRecord(table_name, values) {
    try {
        if (!table_name) {
            throw new Error("Table name is required");
        }

        if (!values) {
            throw new Error("Values are required");
        }

        for (let key in values) {
            if (values[key] === null) {
                delete values[key];
            }
        }

        let response;

        if (table_name === 'publisher_categories') {
            response = await createNewCategory(values);
        }   
        
        if (table_name === 'publisher_websites') {
            response = await createNewWebsite(values);
        }

        if (table_name === 'publisher_inventory') {
            response = await createNewInventory(values);
        }

        if (table_name === 'clients_accounts') {
            response = await createNewCustomer(values);
        }

        if (table_name === 'client_campaigns') {
            response = await createNewCampaign(values);
        }

        if (table_name === 'users') {
            response = await createUser(values);
        }

        if (table_name === 'inventory_campaigns') {
            // const imagesResponse = await loadImages(values.screenshot_files)
            
            // if (imagesResponse?.success) {
            //     values.screenshot_files = imagesResponse.data.map(img=>img.id)
            // }

            response = await createInventoryCampaign(values);
        }

        return response;
    } catch (error) {
        console.log(error);
    }
}

export default createNewRecord;
