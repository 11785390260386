import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Offcanvas,
  Form,
  OverlayTrigger,
  Modal,
  Row,
  Badge,
  Tooltip,
  Alert,
  Container,
  Placeholder,
} from "react-bootstrap";
import { Grid } from "gridjs-react";
import { toast } from "react-toastify";
import { html } from "gridjs";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import {
  editCampaign,
  getCampaign,
  getEstimatedPosition,
} from "../../services/adv";
import { modules } from "../../modules";
import { blankPageMap } from "../../modules/maps";
import {
  editInventoryCampaign,
  getInventoryCampaignByInventoryAndCampaign,
  getPeriodLivePPCAuctions,
  verifyCustomerToken,
} from "../../services/micro";
import {
  placeholderLogoModule
} from "../../modules/placeholderLogo";
import {
  AuctionCampaignStatusCardModule
} from "../../modules/AuctionCampaignStatusCard";
import { mixpanelInstance } from "../../mixpanel/instance";
import { GetPubAbbr } from "../../helpers/get-pub-abbr";
import pageSvg from "../../assets/svg/server_down.svg";
import getMinCpc from "../../services/micro/get_min_cpc";
import { AuctionCreditBalanceCardModule } from "../../modules/AuctionCreditBalanceCard";
import { AuctionMonthlyBudgetCardModule } from "../../modules/AuctionMonthlyBudgetCard";
import getShortMonthName from "../../helpers/get-short-month-name";
import { compareStrNumbers } from "../../helpers/compare-str-numbers";
import { getPublisher } from "../../services/pub";
import { formatThousand } from "../../helpers/thousand-number-format";

function getDaysDiff(ppc_invoice_day, date1, date2) {
  date1 = new Date(date1);
  date2 = new Date(date2);

  if (date1.getDate() < ppc_invoice_day) {
    date2.setMonth(date2.getMonth() - 1);
  }

  if (date1.getDate() == ppc_invoice_day) {
    return Math.round((date2 - date1) / (1000 * 3600 * 24));
  }

  const diffInDays = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
  return Math.round(diffInDays);
}

const REVIEW_STATUS = 3;
const DRAFT_STATUS = 4;
const LIVE_STATUS = 1;
const REJECTED_STATUS = 2;

const HTR_STAGE_PUBLISHER_ID = 10;
const HTR_PUBLISHER_ID = 67;
const DEFAULT_ID = 1;

function returnMessage(isHTR, estimatedMissedClickMax, estClickOpportunity, topBid, cpc) {
  const estClickMin = Math.ceil(estimatedMissedClickMax / 2)

  if (isHTR) {
    return (<div>You're missing out on <strong>{estClickMin}-{estimatedMissedClickMax}</strong> clicks/month. Increase your bid by <strong>${(topBid + 1) - cpc
    }</strong> to generate <strong>{estClickOpportunity}x</strong> more clicks while staying under your target budget.</div>)
  } else {
    return (<div>You're missing out on <strong>~{estimatedMissedClickMax}</strong> clicks per month. Bid <strong>${
      (topBid + 1) - cpc
    }</strong> more to receive about <strong>{estClickOpportunity}x</strong> the click volume.</div>)
  }

}

function renderStatus(status, publisherId, isPopup = false, opacity = "") {
  if (status === "DRAFT" || status === DRAFT_STATUS) {
    const color = publisherId === HTR_PUBLISHER_ID ? "bg-primary" : "bg-secondary";

    if (isPopup) {
      return (
        <div className="auction-queue-item-content-status-column">
          <div className="auction-queue-item-content-status">
            <div className="text-succes fs-xs" style={{ marginTop: "-3px" }}>Draft</div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "2px" }}>
              <div className={color} style={{ width: "20px", height: "6px", margin: "0 2px" }}></div>
              <div style={{ width: "20px", height: "6px", backgroundColor: "#6e7985", margin: "0 2px", opacity: ".2" }}></div>
              <div style={{ width: "20px", height: "6px", backgroundColor: "#6e7985", margin: "0 2px", opacity: ".2" }}></div>
            </div>
          </div>
        </div>
      )
    }

    return html(
      `
      <div class="auction-queue-item-content-status-column" ${opacity}>
        <div class="auction-queue-item-content-status">
          <div class="text-succes fs-xs" style="margin-top: -3px;">Draft</div>
          <div style="display: flex;justify-content: center;margin-top: 2px;">
            <div class="${color}" style="width: 20px;height: 6px;margin: 0 2px;"></div>
            <div style="width: 20px;height: 6px;background-color: #6e7985;margin: 0 2px;opacity: .2;"></div>
            <div style="width: 20px;height: 6px;background-color: #6e7985;margin: 0 2px;opacity: .2;"></div>
          </div>
        </div>
      </div>
      `
    );
  } else if (status === "REVIEW" || status === REVIEW_STATUS) {
    const color = publisherId === HTR_PUBLISHER_ID ? "bg-primary" : "bg-warning";

    if (isPopup) {
      return (
        <div className="auction-queue-item-content-status-column">
          <div className="auction-queue-item-content-status">
            <div className="text-succes fs-xs" style={{ marginTop: "-3px" }}>Under Review</div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "2px" }}>
              <div className={color} style={{ width: "20px", height: "6px", margin: "0 2px" }}></div>
              <div className={color} style={{ width: "20px", height: "6px", margin: "0 2px" }}></div>
              <div style={{ width: "20px", height: "6px", backgroundColor: "#6e7985", margin: "0 2px", opacity: ".2" }}></div>
            </div>
          </div>
        </div>
      )
    }

    return html(
      `
      <div class="auction-queue-item-content-status-column" ${opacity}>
        <div class="auction-queue-item-content-status">
          <div class="text-succes fs-xs" style="margin-top: -3px;">Under Review</div>
          <div style="display: flex;justify-content: center;margin-top: 2px;">
            <div class="${color}" style="width: 20px;height: 6px;margin: 0 2px;"></div>
            <div class="${color}" style="width: 20px;height: 6px;margin: 0 2px;"></div>
            <div style="width: 20px;height: 6px;background-color: #6e7985;margin: 0 2px;opacity: .2;"></div>
          </div>
        </div>
      </div>
      `
    );
  } else {
    const color = publisherId === HTR_PUBLISHER_ID ? "bg-primary" : "bg-success";

    if (isPopup) {
      return (
        <div className="auction-queue-item-content-status-column">
          <div className="auction-queue-item-content-status">
            <div className="text-succes fs-xs" style={{ marginTop: "-3px" }}>Approved</div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "2px" }}>
              <div className={color} style={{ width: "20px", height: "6px", margin: "0 2px" }}></div>
              <div className={color} style={{ width: "20px", height: "6px", margin: "0 2px" }}></div>
              <div className={color} style={{ width: "20px", height: "6px", margin: "0 2px" }}></div>
            </div>
          </div>
        </div>
      )
    }

    return html(
      `
      <div class="auction-queue-item-content-status-column" ${opacity}>
        <div class="auction-queue-item-content-status">
          <div class="text-succes fs-xs" style="margin-top: -3px;">Approved</div>
          <div style="display: flex;justify-content: center;margin-top: 2px;">
            <div class="${color}" style="width: 20px;height: 6px;margin: 0 2px;"></div>
            <div class="${color}" style="width: 20px;height: 6px;margin: 0 2px;"></div>
            <div class="${color}" style="width: 20px;height: 6px;margin: 0 2px;"></div>
          </div>
        </div>
      </div>
      `
    );
  }
};


export default function AdvertiserPpc({ isIframe }) {
  const urlParams = new URLSearchParams(window.location.search);
  const campaignId = urlParams.get("campaignId");
  const token = urlParams.get("token");

  const isIframed = window !== window.parent;

  const [inventoryCampaign, setInventoryCampaign] = useState(null);
  const [estimatedPosition, setEstimatedPosition] = useState(null);
  const [campaign, setCampaign] = useState({});
  const [newBudget, setNewBudget] = useState(0);
  const [updatePage, setUpdatePage] = useState(0);
  const [updateBudget, setUpdateBudget] = useState(0);
  const [ppcAuctions, setPpcAuctions] = useState([]);
  const [publisherId, setPublisherId] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [closedAlert, setClosedAlert] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [oldInventoryCampaign, setOldInventoryCampaign] = useState(null);
  const [isTokenVerified, setIsTokenVerified] = useState(null);
  const [loading, setLoading] = useState(true);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [canPositionBeEstimated, setCanPositionBeEstimated] = useState(false);

  const gridRef = useRef();
  const publisherIdByAbbr = GetPubAbbr();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      verifyCustomerToken(token).then((response) => {
        if (response && response.success) {
          setIsTokenVerified(true);
          getCampaign(campaignId, token).then((res) => {
            if (!res || res.success === false) {
              return modules(10, blankPageMap);
            }

            setPublisherId(Number(response?.customer?.publisher_id));
            getPublisher(response?.customer?.publisher_id).then((res) => {
              if (res.success) {
                setPublisher(res.data);
              }

            }).catch((err) => {
              console.log(err);
            });

            const pubId = localStorage.getItem("publisher_id");

            if (!pubId) {
              localStorage.setItem(
                "publisher_id",
                response?.customer?.publisher_id
              );
              window.location.reload();
            }

            setCampaign({ ...res.data, ...res?.table });
            setNewBudget(res.data.ppc_monthly_budget);
            const currentPeriod = [
              new Date().setDate(new Date().getDate() - 30),
              new Date(),
            ];
            getPeriodLivePPCAuctions(currentPeriod, [], campaignId).then(
              (res) => {
                setPpcAuctions(res);
              }
            );
          });
        } else {
          return setIsTokenVerified(false);
        }
      });
    }
  }, [token]);

  const navigateTo404 = () => {
    if (!isIframe) {
      const publisherIdByAbbr = GetPubAbbr();
      return navigate("pages/error-404?pub=" + publisherIdByAbbr);
    }
  };

  useEffect(() => {
    const getCampaignData = async () => {
      try {
        let res;
        if (isIframe && isTokenVerified) {
          res = await getCampaign(campaignId, token);
        } else if (!isIframe) {
          res = await getCampaign(campaignId);
        }

        // if (isIframe && res.success === false) {
        //   const publisher_id = localStorage.getItem("publisher_id");
        //   return modules(Number(publisher_id), blankPageMap);
        // }

        if (localStorage.getItem("publisher_id")) {
          setPublisherId(Number(localStorage.getItem("publisher_id")));
          getPublisher(Number(localStorage.getItem("publisher_id"))).then((res) => {
            console.log(res);
            if (res.success) {
              setPublisher(res.data);
            }

          }).catch((err) => {
            console.log(err);
          });
        }

        if (!res || res.success === false) {
          return navigateTo404();
        }

        setCampaign({ ...res.data, ...res?.table });
        setNewBudget(res.data.ppc_monthly_budget);

        const currentPeriod = [
          new Date().setDate(new Date().getDate() - 30),
          new Date(),
        ];

        const ppcAuctions = await getPeriodLivePPCAuctions(
          currentPeriod,
          [],
          campaignId
        );
        setPpcAuctions(ppcAuctions);
        localStorage.removeItem("clickedDeactivate");
        localStorage.removeItem("clickedActivate");
      } catch (error) {
        console.log("Error fetching campaign", error);
        if (!isIframe) {
          navigateTo404();
        } else if (isIframe) {
          setErrorStatus(404);
        } else {
          console.log("Error fetching campaign", error);
          toast.error("Error fetching campaign");
        }
      }
    };

    getCampaignData();
  }, [isIframe, isTokenVerified, campaignId, token]);

  useEffect(() => {
    const getCampaignData = async () => {
      try {
        let res;
        if (isIframe && isTokenVerified) {
          res = await getCampaign(campaignId, token);
        } else if (!isIframe) {
          res = await getCampaign(campaignId);
        }

        if (localStorage.getItem("publisher_id")) {
          setPublisherId(Number(localStorage.getItem("publisher_id")));
          getPublisher(Number(localStorage.getItem("publisher_id"))).then((res) => {
            if (res.success) {
              setPublisher(res.data);
            }

          }).catch((err) => {
            console.log(err);
          });
        }

        if (!res || res.success === false) {
          return navigateTo404();
        }

        setCampaign({ ...res.data, ...res?.table });
        setNewBudget(res.data.ppc_monthly_budget);
      } catch (error) {
        console.log("Error fetching campaign", error);
        if (!isIframe) {
          navigateTo404();
        } else if (isIframe) {
          setErrorStatus(404);
        } else {
          console.log("Error fetching campaign", error);
          toast.error("Error fetching campaign");
        }
      }
    };

    getCampaignData();
  }, [updateBudget]);

  useEffect(() => {
    const getPPCData = async () => {
      try {
        const currentPeriod = [
          new Date().setDate(new Date().getDate() - 30),
          new Date(),
        ];

        getPeriodLivePPCAuctions(currentPeriod, [], campaignId)
          .then((newPpcAuctions) => {
            setPpcAuctions(newPpcAuctions);

            localStorage.removeItem("clickedDeactivate");
            localStorage.removeItem("clickedActivate");

            setLoading(false);
          })
          .catch((err) => {
            console.log("Error fetching PPC auctions");
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);

        if (!isIframe) {
          navigateTo404(campaignId);
        } else if (isIframe) {
          setErrorStatus(404);
        } else {
          console.log("Error fetching campaign", error);
          toast.error("Error fetching campaign");
        }
      }
    };

    getPPCData();
  }, [updatePage]);

  const [showBottom, setShowBottom] = useState(false);
  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = () => {
    mixpanelInstance.track(`Open montly budget popup for ${campaign.name}`, {
      campaignId,
    });

    setShowBottom(true);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    if (gridRef?.current && !show) {
      const gridjsInstance = gridRef.current.getInstance();
      gridjsInstance.on("rowClick", (...args) => {
        const values = args[1]._cells.map((cell, index) => {
          return {
            value: cell.data,
            column: ppcAuctions.columns[index],
          };
        });

        const inventory_id = values[0].value;

        let top_bid = 0;
        if (
          publisherId === HTR_PUBLISHER_ID ||
          publisherId === HTR_STAGE_PUBLISHER_ID ||
          publisherId === DEFAULT_ID
        ) {
          top_bid = values[5].value;
        } else {
          top_bid = values[8].value;
        }

        getInventoryCampaignByInventoryAndCampaign(
          inventory_id,
          campaignId
        ).then((res) => {
          if (res && res.success) {
            if (args[0].target.localName === "input") {
              if (args[0].target.checked) {
                handleActivateBid(res.data.id, res.data.inventory_campaign_status);
              } else {
                handleDeactivateBid(res.data.id);
              }
            } else {
              setShow(true);

              // draft only
              if (res.data.inventory_campaign_status === DRAFT_STATUS) {
                setInventoryCampaign({
                  ...res.data,
                  cpc: Number(top_bid.replace("$", "")) + Number(publisher?.min_increment),
                  top_bid,
                });
              } else {
                setInventoryCampaign({
                  ...res.data,
                  top_bid,
                });
              }

              setOldInventoryCampaign({ ...res.data });

              mixpanelInstance.track(
                `Open bid management popup for ${campaign.name}`,
                {
                  campaignId,
                }
              );

              getEstimatedPosition(res.data.id, res.data.cpc)
                .then((res) => {
                  setEstimatedPosition(res.data);
                })
                .catch((err) => {
                  console.log("Error fetching estimated position");
                });
            }
          } else {
            toast.warning("Cannot open bid management popup");
          }
        });
      });
    }
  }, [gridRef, ppcAuctions]);

  const handleSave = () => {
    setLoading(true);

    if (inventoryCampaign.custom_url.length > 0) {
      if (inventoryCampaign.custom_url.length > 255) {
        toast.warn("Custom URL can't be longer than 255 characters");
        return false;
      }

      if (inventoryCampaign.custom_url.includes(" ")) {
        toast.warn("Custom URL can't contain spaces");
        return false;
      }

      if (!inventoryCampaign.custom_url.includes("http")) {
        toast.warn("Custom URL must contain http or https");
        return false;
      }

      if (inventoryCampaign.custom_url.includes("http://")) {
        toast.warn("Custom URL must contain https");
        return false;
      }

      if (!inventoryCampaign.custom_url.includes(".")) {
        toast.warn("Custom URL must contain a domain");
        return false;
      }
    }

    const trackInventoryCampaignChanging = (message) => {
      mixpanelInstance.track(message, {
        campaignId,
        inventoryCampaignId: inventoryCampaign.id,
        cpc: Number(inventoryCampaign.cpc),
        custom_url: inventoryCampaign.custom_url,
        ppc_bid_active: inventoryCampaign.ppc_bid_active,
      });
    };

    editInventoryCampaign(inventoryCampaign.id, {
      cpc: Number(inventoryCampaign.cpc),
      custom_url: inventoryCampaign.custom_url,
      ppc_bid_active: inventoryCampaign.ppc_bid_active,
    })
      .then((res) => {
        setShow(false);

        toast.success("Queue item saved successfully");

        trackInventoryCampaignChanging(
          `inventoryCampaign ${inventoryCampaign.id} saved successfully`
        );

        setUpdatePage(updatePage + 1);
      })
      .catch((err) => {
        toast.error("Error saving queue item");

        trackInventoryCampaignChanging(
          `Error saving inventoryCampaign ${inventoryCampaign.id}`
        );
      });
  };

  const handleSubmitBid = () => {
    setLoading(true);

    let submit_data = {
      cpc: Number(inventoryCampaign.cpc),
      custom_url: inventoryCampaign.custom_url,
    };

    if (inventoryCampaign.inventory_campaign_status !== 1) {
      submit_data = {
        ...submit_data,
        inventory_campaign_status: REVIEW_STATUS,
      };
    }

    editInventoryCampaign(inventoryCampaign.id, submit_data)
      .then((res) => {
        setShow(false);

        toast.success("Bid submitted successfully");

        setUpdatePage(updatePage + 1);
      })
      .catch((err) => {
        toast.error("Error submitting bid");
      });
  };

  const handleWithdrawBid = () => {
    setLoading(true);

    editInventoryCampaign(inventoryCampaign.id, {
      inventory_campaign_status: DRAFT_STATUS,
    })
      .then((res) => {
        setShow(false);

        toast.success("Bid withdrawn successfully");

        setUpdatePage(updatePage + 1);
      })
      .catch((err) => {
        toast.error("Error withdrawing bid");
      });
  };

  const trackBidChange = (message, inventoryCampaignId) => {
    mixpanelInstance.track(
      `${message} for inventory campaign ${inventoryCampaignId}`,
      {
        campaignId,
        inventoryCampaignId,
      }
    );
  };

  const handleDeactivateBid = (id) => {
    setLoading(true);

    const isClicked = localStorage.getItem("clickedDeactivate");

    if (!isClicked) {
      localStorage.setItem("clickedDeactivate", "true");

      editInventoryCampaign(id, {
        ppc_bid_active: false,
      })
        .then((res) => {
          toast.success("Bid deactivated successfully");
          trackBidChange("Bid deactivated successfully", id);

          setUpdatePage(updatePage + 1);
        })
        .catch((err) => {
          toast.error("Error deactivating bid");
          localStorage.removeItem("clickedDeactivate");
          trackBidChange("Error deactivating bid", id);
        });
    }
  };

  const handleActivateBid = (id, status_id) => {
    setLoading(true);

    const isClicked = localStorage.getItem("clickedActivate");

    if (!isClicked) {
      localStorage.setItem("clickedActivate", "true");

      let updateObj = {
        ppc_bid_active: true,
      };

      if (status_id === DRAFT_STATUS) {
        updateObj = {
          ...updateObj,
          inventory_campaign_status: REVIEW_STATUS,
        };
      }

      editInventoryCampaign(id, updateObj)
        .then((res) => {
          toast.success("Bid activated successfully");
          trackBidChange("Bid activated successfully", id);
          setUpdatePage(updatePage + 1);
        })
        .catch((err) => {
          toast.error("Error activating bid");
          localStorage.removeItem("clickedActivate");
          trackBidChange("Error activating bid", id);
        });
    }
  };

  const onSaveBudget = () => {
    setBudgetLoading(true);

    mixpanelInstance.track(`Try of montly budget change for ${campaign.name}`, {
      newMonthlyBudget: newBudget,
      campaignId,
    });

    const newBudgetValue = document.getElementById("blockBudget").value;

    if (newBudgetValue < 250) {
      toast.warn("Monthly budget can't be lower than $250");
      setBudgetLoading(false);
      return;
    }

    editCampaign(campaignId, {
      ppc_monthly_budget: Number(newBudgetValue),
    })
      .then((res) => {
        if (res.success === false) {
          toast.error("Error saving budget");
          return;
        }

        setShowBottom(false);
        toast.success("Budget saved successfully");

        mixpanelInstance.track(
          `Montly budget successfully changed for ${campaign.name}`,
          {
            newMonthlyBudget: newBudget,
            campaignId,
          }
        );

        setUpdateBudget(updateBudget + 1);
        setBudgetLoading(false);
      })
      .catch((err) => {
        toast.error("Error saving budget");
        setBudgetLoading(false);
      });
  };

  const onChangeMonthlyBudget = (e) => {
    setTimeout(() => {
      if (e.target.value < publisher?.min_budget || isNaN(e.target.value)) {
        toast.warn("Monthly budget can't be lower than $" + publisher?.min_budget);
        const blockBudget = document.getElementById("blockBudget");
        blockBudget.value = publisher?.min_budget;
        setNewBudget(publisher?.min_budget);
        return;
      }

      const blockBudget = document.getElementById("blockBudget");
      blockBudget.value = e.target.value;
      setNewBudget(e.target.value);
      return;
    }, 2000);
  };

  const updateCPC = (sign) => {
    const change = sign === "plus" ? Number(publisher?.min_increment) : -Number(publisher?.min_increment);
    const newCpc = Number((Number(inventoryCampaign.cpc) + change).toFixed(1));

    setInventoryCampaign({ ...inventoryCampaign, cpc: newCpc });

    const blockCpc = document.getElementById("blockCpc");
    blockCpc.value = newCpc;

    mixpanelInstance.track(
      `Change cpc for ${campaign.name} in bid management popup`,
      {
        campaignId,
        sign,
        newCpc,
      }
    );
  };

  const onChangeCpc = (e) => {
    setTimeout(() => {
      getMinCpc(inventoryCampaign.id).then((res) => {
        let updatedCpc = 0;

        if (Number(e.target.value) < res.data || isNaN(e.target.value)) {
          toast.warn("CPC can't be lower than the minimum CPC");
          setInventoryCampaign({ ...inventoryCampaign, cpc: res.data });
          const blockCpc = document.getElementById("blockCpc");
          blockCpc.value = res.data;
          updatedCpc = res.data;
        } else {
          const newCpc = Number(e.target.value);
          setInventoryCampaign({ ...inventoryCampaign, cpc: newCpc });
          const blockCpc = document.getElementById("blockCpc");
          blockCpc.value = newCpc;
          updatedCpc = newCpc;
        }

        getEstimatedPosition(
          inventoryCampaign.id,
          updatedCpc,
          inventoryCampaign.ppc_bid_active
        )
          .then((res) => {
            setEstimatedPosition(res.data);
            setCanPositionBeEstimated(true);
          })
          .catch((err) => {
            console.log("Error fetching estimated position");
          });
      });
    }, 2000);
  };

  const onChangeCpcByButton = (sign) => {
    setTimeout(() => {
      getMinCpc(inventoryCampaign.id).then((res) => {
        if (sign === "minus" && Number(inventoryCampaign.cpc) <= res.data) {
          toast.warn("CPC can't be lower than the minimum CPC");
          setInventoryCampaign({ ...inventoryCampaign, cpc: res.data });
          const blockCpc = document.getElementById("blockCpc");
          blockCpc.value = res.data;
          return;
        }

        updateCPC(sign);

        console.log("inventoryCampaign.cpc", inventoryCampaign.cpc);
        console.log("publisher?.min_increment", publisher?.min_increment);


        let diff = 0;
        if (sign === "plus") {
          diff = Number(publisher?.min_increment);
        } else {
          diff = -Number(publisher?.min_increment);
        }

        console.log("diff", diff);

        getEstimatedPosition(
          inventoryCampaign.id,
          Number((Number(inventoryCampaign.cpc) + diff).toFixed(1)),
          inventoryCampaign.ppc_bid_active
        )
          .then((res) => {
            setEstimatedPosition(res.data);
            setCanPositionBeEstimated(true);
          })
          .catch((err) => {
            console.log("Error fetching estimated position");
          });
      });
    }, 200);
  };

  const onChangePPCActive = (e) => {
    mixpanelInstance.track(
      `Change ppc active toggle for ${campaign.name} in bid management popup`,
      {
        campaignId,
        ppcActive: e.target.checked,
      }
    );

    console.log("inventoryCampaign #2", inventoryCampaign);

    setInventoryCampaign({
      ...inventoryCampaign,
      ppc_bid_active: e.target.checked,
    });

    getEstimatedPosition(
      inventoryCampaign.id,
      Number(inventoryCampaign.cpc),
      e.target.checked
    )
      .then((res) => {
        setEstimatedPosition(res.data);
        setCanPositionBeEstimated(true);
      })
      .catch((err) => {
        console.log("Error fetching estimated position");
      });
  };

  const onChangeCustomUrl = (e) => {
    setInventoryCampaign({ ...inventoryCampaign, custom_url: e.target.value });
  };

  const formatColumns = (columns) => {
    const formatedColumns = columns.map((column) => {
      const rowOppacity = (row) => {
        let ppc_bid_active = null;
        if (
          publisherId === HTR_PUBLISHER_ID ||
          publisherId === HTR_STAGE_PUBLISHER_ID ||
          publisherId === DEFAULT_ID
        ) {
          ppc_bid_active = row?.cells[7]?.data || false
        } else {
          ppc_bid_active = row?.cells[9]?.data || false
        }

        if (!ppc_bid_active) {
          return `style="opacity: 0.5;"`;
        }

        if (!campaign.ppc_active) {
          return `style="opacity: 0.5;"`;
        }

        return "";
      };

      // display none for the ID column
      if (column === "ID") {
        return {
          name: column,
          hidden: true,
        };
      }

      if (column === "Content Status") {
        return {
          name: column,
          sort: false,
          formatter: (_, row) => {
            let status = null;
            if (
              publisherId === HTR_PUBLISHER_ID ||
              publisherId === HTR_STAGE_PUBLISHER_ID ||
              publisherId === DEFAULT_ID
            ) {
              status = row.cells[6].data;
            } else {
              status = row.cells[8].data;
            }

            const opacity = rowOppacity(row);

            return renderStatus(status, publisherId, false, opacity);
          },
        };
      } else if (column === "PPC") {
        return {
          name: column,
          sort: false,
          formatter: (_, row) => {
            let ppc_bid_active = null;
            if (
              publisherId === HTR_PUBLISHER_ID ||
              publisherId === HTR_STAGE_PUBLISHER_ID ||
              publisherId === DEFAULT_ID
            ) {
              ppc_bid_active = row?.cells[7]?.data || false;
            } else {
              ppc_bid_active = row?.cells[9]?.data || false;
            }

            let status = null;
            if (
              publisherId === HTR_PUBLISHER_ID ||
              publisherId === HTR_STAGE_PUBLISHER_ID ||
              publisherId === DEFAULT_ID
            ) {
              status = row.cells[6].data;
            } else {
              status = row.cells[8].data;
            }

            const opacity = rowOppacity(row);
            let filter = "";

            if (opacity) {
              filter = `style="opacity: 0.5;"`;
            } else {
              filter = opacity;
            }

            let warning = "";
            if (!ppc_bid_active) {
              warning = `style="border-color: #d22d3d;"`;
            } else {
              if (
                publisherId === HTR_PUBLISHER_ID ||
                publisherId === HTR_STAGE_PUBLISHER_ID
              ) {
                warning = `style="border-color: #4C5ECF;"`;
              } else {
                warning = `style="border-color: #0cb785;"`;
              }
            }

            let className = "";
            if (!ppc_bid_active) {
              className = "unchecked bg-danger";
            } else {
              if (
                publisherId === HTR_PUBLISHER_ID ||
                publisherId === HTR_STAGE_PUBLISHER_ID
              ) {
                className = "bg-primary";
              } else {
                className = "bg-success";
              }
            }

            if (status !== "DRAFT") {
              filter = "";
            }

            if (ppc_bid_active) {
              return html(`
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input ${className}" ${warning} ${filter} checked>
                </div>
              `);
            } else {
              return html(`
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input ${className}" ${warning} ${filter}>
                </div>
              `);
            }
          },
        };
      } else if (column === "Your Bid") {
        return {
          name: column,
          sort: false,
          formatter: (_, row) => {
            const col = row.cells[columns.indexOf(column)];
            let opacity = rowOppacity(row);

            let bid_cpc = Number(col.data.replace("$", ""));

            // if draft
            if (row.cells[6].data === "DRAFT") {
              if (publisher) {
                bid_cpc = 0;
                const top_bid = row.cells[5].data.replace("$", "");
                bid_cpc += Number(publisher.min_increment);
                bid_cpc += Number(top_bid);
              }
            }

            return html(`<span ${opacity}>$${Number(bid_cpc).toFixed(2)}</span>`);
          },
        };
      } else if (column === "Position") {
        return {
          name: column,
          sort: false,
          formatter: (_, row) => {
            const col = row.cells[columns.indexOf(column)];
            // if bid is not active, hide the position
            let ppc_bid_active = null;
            if (
              publisherId === HTR_PUBLISHER_ID ||
              publisherId === HTR_STAGE_PUBLISHER_ID ||
              publisherId === DEFAULT_ID
            ) {
              ppc_bid_active = row?.cells[7]?.data || false
            } else {
              ppc_bid_active = row?.cells[9]?.data || false
            }

            let opacity = "";

            if (!campaign.ppc_active) {
              opacity = `style="opacity: 0.5"`;
            }

            opacity = ppc_bid_active ? opacity : `style="opacity: 0;"`;

            const fullPosition = col.data;

            const bids_amount = Number(fullPosition.split("@")[2]);

            let currentPos = Number(fullPosition.split("@")[0]);
            let estimatedPos = Number(fullPosition.split("@")[1]);



            if (campaign.ppc_active && !campaign.ppc_paused) {
              return html(
                `<div ${opacity} class="d-flex align-items-center">
                  <span class="text-success fw-bold" style="margin-left: 5px;">#${estimatedPos}</span>
                </div>`
              );
            }

            if (currentPos > bids_amount || currentPos === 0) {
              currentPos = "Not shown";
            }

            if (estimatedPos > bids_amount || estimatedPos === 0) {
              estimatedPos = "Not shown";
            }

            let status = null;
            if (
              publisherId === HTR_PUBLISHER_ID ||
              publisherId === HTR_STAGE_PUBLISHER_ID ||
              publisherId === DEFAULT_ID
            ) {
              status = row.cells[6].data;
            } else {
              status = row.cells[8].data;
            }

            if (currentPos !== "Not shown") {
              currentPos = "#" + currentPos;
            }

            if (estimatedPos !== "Not shown") {
              estimatedPos = "#" + estimatedPos;
            }

            if (estimatedPos === "Not shown") {
              return html(
                `<div ${opacity} class="d-flex align-items-center">
                  <small ${opacity} class="text-danger">Not shown</small>
                </div>`
              );
            }

            if (currentPos !== "Not shown" && estimatedPos == currentPos) {
              return html(
                `<div ${opacity} class="d-flex align-items-center">
                  <span ${opacity} class="text-success fw-bold" style="margin-left: 5px">${estimatedPos}</span>
                </div>`
              );
            }

            if (status == "DRAFT") {
              return html(
                `<div class="d-flex align-items-center">
                  <small ${currentPos == "Not shown" ? 'class="text-danger"' : ""} ${opacity}>${currentPos}${"  "}</small>
                  <i ${opacity} class="ri-arrow-right-line ml-2 mr-2" data-bs-toggle="tooltip" title="Estimated position"></i>
                  <span ${opacity} class="text-success fw-bold">${"  "}${estimatedPos}</span>
                </div>`
              );
            }

            return html(
              `<div class="d-flex align-items-center">
                <small ${opacity} ${currentPos == "Not shown" ? 'class="text-danger"' : ""} style="margin-right: 5px">${currentPos}${"  "}</small>
                <i ${opacity} class="ri-arrow-right-line ml-2 mr-2" data-bs-toggle="tooltip" title="Estimated position"></i>
                <span ${opacity} class="text-success fw-bold">${"  "}${estimatedPos}</span>
              </div>`
            );
          },
        };
      } else {
        return {
          name: column,
          sort: column === "Name" ? true : false,
          formatter: (_, row) => {
            const col = row.cells[columns.indexOf(column)];
            let opacity = rowOppacity(row);

            if (column === "Views" || column === "Your Bid") {
              let ppc_bid_active = null;
              if (
                publisherId === HTR_PUBLISHER_ID ||
                publisherId === HTR_STAGE_PUBLISHER_ID ||
                publisherId === DEFAULT_ID
              ) {
                ppc_bid_active = row?.cells[7]?.data || false
              } else {
                ppc_bid_active = row?.cells[9]?.data || false
              }

              if (!campaign.ppc_active) {
                opacity = `style="opacity: 0.5;"`;
              }

              opacity = ppc_bid_active ? opacity : `style="opacity: 0;"`;
            }

            if (col.data == "gathering data...") {
              return html(`<small ${opacity}>Gathering data...</small>`);
            }

            return html(`<span ${opacity}>${col.data}</span>`);
          },
        };
      }
    });

    // add empty column for the button
    formatedColumns.push({
      name: "",
      sort: false,
      formatter: (_, row) => {
        return html(
          `<div class="d-flex align-items-center justify-content-center">
            <button class="btn btn-outline-primary btn-sm fs-11">
              Update Bid
            </button>
          </div>`
        );
      }
    });

    // put ppc column before views

    const ppcIndex = formatedColumns.findIndex((c) => c.name === "PPC");
    const viewsIndex = formatedColumns.findIndex((c) => c.name === "Views");

    if (ppcIndex !== -1 && viewsIndex !== -1) {
      const ppcColumn = formatedColumns[ppcIndex];
      formatedColumns.splice(ppcIndex, 1);
      formatedColumns.splice(viewsIndex, 0, ppcColumn);
    }

    return formatedColumns;
  };

  const day2 = new Date().setMonth(new Date().getMonth() + 1);
  const days_diff = getDaysDiff(
    campaign.ppc_invoice_day,
    new Date(),
    new Date(day2).setDate(campaign.ppc_invoice_day)
  );

  if (isIframe && isTokenVerified === false) {
    return modules(10, blankPageMap);
  }

  if (isIframe && !campaignId) {
    return modules(10, blankPageMap);
  }

  const campaignCategories = campaign?.related_categories_ids?.map(
    (category) => {
      const label = campaign?.columns
        ?.find((c) => c.name === "related_categories_ids")
        ?.options?.find((o) => o.value === category)?.label;
      return label;
    }
  );

  if ((isIframe && isTokenVerified === true) || !isIframe) {
    let customStyle = {};

    if (
      publisherId === HTR_PUBLISHER_ID ||
      publisherId === HTR_STAGE_PUBLISHER_ID ||
      publisherId === DEFAULT_ID
    ) {
      customStyle = {
        paddingLeft: "46px",
        paddingRight: "46px",
        paddingTop: "15px",
        paddingBottom: "15px",
      };
    }

    if (errorStatus === 404 && isIframe) {
      return (
        <div className="content">
          <Container>
            <Row className="gx-5">
              <Col lg="5" className="d-flex flex-column align-items-center">
                <h1 className="error-number">404</h1>
                <h2 className="error-title">Page Not Found</h2>
                <p className="error-text">
                  The page you were looking for doesn't exist. You may have
                  mistyped the address or the page may have moved.
                </p>
                {isIframed &&
                  <Link
                    to="/dashboard/sales"
                    className="btn btn-primary btn-error"
                  >
                    Back to Dashboard
                  </Link>
                }
              </Col>
              <Col xs="8" lg="6" className="mb-5 mb-lg-0">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-100"
                  aria-label="svg image"
                ></object>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    // Flags for alerts
    let isNoBidsFlag = ppcAuctions?.rows &&
      ppcAuctions.rows?.filter(
        (row) => row[7]
      )?.length === 0;

    let isCreditBalanceSmallerThanTopBid = Object.keys(campaign).length &&
      campaign?.ppc_credits_monthly_spend < inventoryCampaign?.top_bid &&
      campaign.ppc_active;

    let isCampaignInactive = Object.keys(campaign).length && !campaign?.ppc_active;
    let isCampaignPaused = Object.keys(campaign).length && campaign?.ppc_paused;

    // Flags for publisher
    const isHTR = publisherId === HTR_PUBLISHER_ID || publisherId === HTR_STAGE_PUBLISHER_ID;
    const isDefault = publisherId === DEFAULT_ID;

    //

    const estimatePosition = (canPositionBeEstimated || (campaign.ppc_active && !campaign.ppc_paused));

    // Cannot have multiple alerts at the same time
    if (isNoBidsFlag) {
      isCreditBalanceSmallerThanTopBid = false;
      isCampaignInactive = false;
      isCampaignPaused = false;
    } else if (isCreditBalanceSmallerThanTopBid) {
      isNoBidsFlag = false;
      isCampaignInactive = false;
      isCampaignPaused = false;
    } else if (isCampaignInactive) {
      isNoBidsFlag = false;
      isCreditBalanceSmallerThanTopBid = false;
      isCampaignPaused = false;
    } else if (isCampaignPaused) {
      isNoBidsFlag = false;
      isCreditBalanceSmallerThanTopBid = false;
      isCampaignInactive = false;
    }

    return (
      <React.Fragment>
        {isIframe ? <></> : <Header onSkin={setSkin} />}
        <div
          className={isIframe ? "" : "main main-app p-3 p-lg-4"}
          style={isIframe ? customStyle : {}}
        >
          {isIframe ? (
            <></>
          ) : (
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div>
                <ol className="breadcrumb fs-sm mb-1">
                  <li className="breadcrumb-item">
                    <Link href="#">ADV Home</Link>
                  </li>
                  <li className="breadcrumb-item">{campaign.name}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    PPC Auctions
                  </li>
                </ol>
                <h2 className="main-title mb-0">
                  Pay-Per-Click (PPC) Auctions
                </h2>
              </div>
            </div>
          )}

          <Row className="g-3">
            <Col md="12" xl="4">
              {
                AuctionCampaignStatusCardModule(publisherIdByAbbr, {
                  campaign, campaignCategories, publisherIdByAbbr, currentSkin
                })
              }
            </Col>
            <Col md="6" xl="4">
              {
                AuctionCreditBalanceCardModule(publisherIdByAbbr, {
                  campaign
                })
              }
            </Col>
            <Col md="6" xl="4" key={updateBudget}>
              {
                AuctionMonthlyBudgetCardModule(publisherIdByAbbr, {
                  campaign, budgetLoading, handleShowBottom, days_diff
                })
              }
            </Col>
            {
              isNoBidsFlag ?
                (
                  (isHTR || isDefault) ?
                    (
                      <Col md="12">
                        <div class="fade alert alert-dismissible htr-alert show mb-0">
                          <button
                            type="button"
                            class="btn-close"
                            aria-label="Close alert"
                            onClick={() => setClosedAlert(true)}
                          ></button>
                          <div class="banner">
                            <h3 class="banner-title">
                              You don't have any active bids at the moment.
                            </h3>
                            <p class="banner-text">
                              Please activate your bids to start bidding on PPC
                              inventory.
                            </p>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <Col md="12">
                        <Alert variant="warning" dismissible className="mb-0">
                          <strong>You don't have any active bids</strong> at the
                          moment. Please activate your bids to start bidding on PPC
                          inventory.
                        </Alert>
                      </Col>
                    )
                ) : (
                  <></>
                )
            }
            {
              isCreditBalanceSmallerThanTopBid ?
                (
                  (isHTR || isDefault) ? (
                    !closedAlert ? (
                      <Col md="12">
                        <div class="fade alert alert-dismissible htr-alert show mb-0">
                          <button
                            type="button"
                            class="btn-close"
                            aria-label="Close alert"
                            onClick={() => setClosedAlert(true)}
                          ></button>
                          <div class="banner">
                            <h3 class="banner-title">
                              You don't have enough credits to continue bidding at
                              the moment (your current credit total is $0).
                            </h3>
                            <p class="banner-text">
                              Please top up on the settings page to bid on PPC
                              inventory.
                            </p>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )
                  ) : (
                    <Col md="12">
                      <Alert variant="warning" dismissible className="mb-0">
                        <strong>You don't have enough credits</strong> to continue
                        bidding at the moment (your current credit total is $0).
                        Please top up on the settings page to bid on PPC inventory.
                      </Alert>
                    </Col>
                  )
                ) : (
                  <></>
                )
            }
            {
              isCampaignInactive ?
                (
                  (isHTR || isDefault) ? (
                    !closedAlert ? (
                      <Col md="12">
                        <div className="fade alert alert-dismissible htr-alert show mb-0">
                          <button
                            type="button"
                            class="btn-close"
                            aria-label="Close alert"
                            onClick={() => setClosedAlert(true)}
                          ></button>
                          <div class="banner">
                            <h3 class="banner-title">
                              Your campaign is inactive at the moment.
                            </h3>
                            <p class="banner-text">
                              Please top-up and activate your campaign to start
                              bidding on PPC inventory
                            </p>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )
                  ) : (
                    <Col md="12">
                      <Alert variant="danger" dismissible className="mb-0">
                        <strong>Your campaign is inactive</strong> at the moment.
                        Please top-up and activate your campaign to start bidding on
                        PPC inventory.
                      </Alert>
                    </Col>
                  )
                ) : (
                  <></>
                )
            }
            {isCampaignPaused ?
              (
                (isHTR || isDefault) ? (
                  !closedAlert ? (
                    <Col md="12">
                      <div className="fade alert alert-dismissible htr-alert show mb-0">
                        <button
                          type="button"
                          class="btn-close"
                          aria-label="Close alert"
                          onClick={() => setClosedAlert(true)}
                        ></button>
                        <div class="banner">
                          <h3 class="banner-title">
                            Your campaign is paused at the moment.
                          </h3>
                          <p class="banner-text">
                            Please top-up and activate your campaign to start bidding on PPC inventory.
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )
                ) : (
                  <Col md="12">
                    <Alert variant="warning" dismissible className="mb-0">
                      <strong>Your campaign is paused</strong> at the moment.
                      Please top-up and activate your campaign to start bidding on PPC inventory.
                    </Alert>
                  </Col>
                )
              ) : (
                <></>
              )
            }
            <Col md="12">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">PPC Bids</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Modal show={show} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton className="card-one pb-2">
                      <Modal.Title>
                        <div className="d-flex align-items-center">
                          <span>{inventoryCampaign?.inventory?.name}</span>
                        </div>
                        <p
                          className="d-flex gap-2 mb-0"
                          style={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#6e7985",
                          }}
                        >
                          {(HTR_PUBLISHER_ID !== publisherId &&
                            HTR_STAGE_PUBLISHER_ID !== publisherId &&
                            DEFAULT_ID !== publisherId)
                            ? inventoryCampaign?.inventory?.related_categories_ids
                              ?.map((category) => {
                                return campaign?.columns
                                  ?.find(
                                    (c) => c.name === "related_categories_ids"
                                  )
                                  ?.options?.find((o) => o.value === category)
                                  ?.label;
                              })
                              .join(", ")
                            : ""}
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="card-one pt-0">
                      <Row>
                        <Col
                          sm="12"
                          className="text-left"
                          onClick={() =>
                            mixpanelInstance.track(
                              "Placement 1 button clicked in bid management popup",
                              {
                                campaignId,
                              }
                            )
                          }
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              inventoryCampaign?.inventory ? (
                                <Tooltip placement="bottom">
                                  {inventoryCampaign?.inventory.anchor_name}
                                </Tooltip>
                              ) : (
                                <></>
                              )
                            }
                          >
                            <a
                              href={inventoryCampaign?.inventory.url}
                              target="_blank"
                              className="btn btn-sm btn-outline-primary me-1"
                            >
                              Placement 1
                              <i
                                className="ri-external-link-line"
                                style={{ marginLeft: "5px" }}
                              ></i>
                            </a>
                          </OverlayTrigger>
                          {inventoryCampaign?.alt_versions &&
                            inventoryCampaign?.alt_versions.length > 0 &&
                            inventoryCampaign?.alt_versions.map(
                              (alt_version, index) => {
                                return (
                                  <OverlayTrigger
                                    key={index}
                                    placement="bottom"
                                    overlay={
                                      alt_version?.anchor_name ? (
                                        <Tooltip placement="bottom">
                                          {alt_version?.anchor_name}
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )
                                    }
                                  >
                                    <a
                                      href={alt_version.custom_url}
                                      target="_blank"
                                      className="btn btn-sm btn-outline-primary me-1"
                                    >
                                      {`Placement ${index + 2}`}
                                      <i
                                        className="ri-external-link-line"
                                        style={{ marginLeft: "5px" }}
                                      ></i>
                                    </a>
                                  </OverlayTrigger>
                                );
                              }
                            )}
                        </Col>
                        <Col sm="12">
                          <div className="divider mt-2">
                            <span>Bid Management</span>
                          </div>
                        </Col>
                        <Col sm="12">
                          <Form>
                            <Row className="">
                              <Col sm="6">
                                <div className="text-center">
                                  <Form.Label
                                    htmlFor="blockCpc"
                                    className="dollar-sign"
                                  >
                                    $
                                  </Form.Label>
                                  <Form.Control
                                    id="blockCpc"
                                    placeholder={inventoryCampaign?.min_bid}
                                    onChange={(e) => onChangeCpc(e)}
                                    defaultValue={
                                      inventoryCampaign?.cpc ||
                                      inventoryCampaign?.min_bid
                                    }
                                    type="text"
                                    className="cpc-field"
                                  />
                                  <Button
                                    variant="white"
                                    className="button-icon-button minus"
                                    onClick={() => onChangeCpcByButton("minus")}
                                  >
                                    <i className="ri-indeterminate-circle-line button-icon"></i>
                                  </Button>
                                  <Button
                                    variant="white"
                                    className="button-icon-button plus"
                                    onClick={() => onChangeCpcByButton("plus")}
                                  >
                                    <i className="ri-add-circle-line button-icon"></i>
                                  </Button>
                                </div>
                                <div className="auction-info">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip placement="bottom">
                                        Activate your PPC bid to boost your
                                        position.
                                      </Tooltip>
                                    }
                                  >
                                    <div className="auction-info-item">
                                      {
                                        inventoryCampaign?.ppc_bid_active ? (
                                          <i className="ri-cursor-line"></i>
                                        ) : (
                                          <i className="ri-cursor-line text-danger"></i>
                                        )
                                      }
                                      {
                                        inventoryCampaign?.ppc_bid_active ? (
                                          <span className="">PPC Bid Active</span>
                                        ) : (
                                          <span className="text-danger">PPC Bid Inactive</span>
                                        )
                                      }
                                      <span className="switch fw-bold">
                                        <div class="form-check form-switch">
                                          <input
                                            type="checkbox"
                                            className={
                                              inventoryCampaign?.ppc_bid_active
                                                ? "form-check-input bg-primary"
                                                : "form-check-input bg-danger unchecked"
                                            }
                                            checked={
                                              inventoryCampaign?.ppc_bid_active
                                            }
                                            onChange={(e) => onChangePPCActive(e)}
                                          />
                                        </div>
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip placement="bottom">
                                        {inventoryCampaign?.inventory_campaign_status ===
                                          REVIEW_STATUS ? (
                                          "We're currently reviewing this bid. You will feature on this inventory if/when it is approved."
                                        ) : (
                                          <></>
                                        )}
                                        {inventoryCampaign?.inventory_campaign_status ===
                                          DRAFT_STATUS ? (
                                          "You need to submit this bid for review to feature on this inventory."
                                        ) : (
                                          <></>
                                        )}
                                        {inventoryCampaign?.inventory_campaign_status ===
                                          LIVE_STATUS ? (
                                          "You are currently live on this inventory. As long as your position is high enough you will be featured."
                                        ) : (
                                          <></>
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    <div className="auction-info-item">
                                      <i className="ri-information-line"></i>
                                      <span>Content Status</span>
                                      <span className="switch">
                                        <div style={{ marginRight: "10px" }}>
                                          {
                                            renderStatus(
                                              inventoryCampaign?.inventory_campaign_status,
                                              publisherId,
                                              true
                                            )
                                          }
                                        </div>
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                  <div className="auction-info-item">
                                    <i className="ri-auction-line"></i>
                                    <span>Position {estimatePosition ? "Estimated" : "Current"}</span>
                                    <span className="number fw-bold text-success inline-block">
                                      {
                                        !estimatePosition ?
                                          estimatedPosition?.bid_amount <= estimatedPosition?.estimated_position ?
                                            // if the bid amount is less than the estimated position
                                            (
                                              <div class="d-flex align-items-center">
                                                <small class="text-danger">
                                                  Not shown
                                                </small>
                                              </div>
                                            )
                                            :
                                            // if the bid amount is less than the current position
                                            (
                                              "#" + estimatedPosition?.estimated_position
                                            )
                                          :
                                          estimatedPosition?.current_position != estimatedPosition?.estimated_position ? (
                                            estimatedPosition?.bid_amount <= estimatedPosition?.estimated_position ?
                                              // if the bid amount is less than the estimated position
                                              (
                                                <div class="d-flex align-items-center">
                                                  <small class="text-danger">
                                                    Not shown
                                                  </small>
                                                </div>
                                              )
                                              : estimatedPosition?.bid_amount <= estimatedPosition?.current_position ?
                                                // if the bid amount is less than the current position
                                                (
                                                  <div class="d-flex align-items-center">
                                                    <small
                                                      class="text-danger"
                                                      style={{ marginRight: 5 }}
                                                    >
                                                      Not shown
                                                    </small>
                                                    <i
                                                      class="ri-arrow-right-line"
                                                      data-bs-toggle="tooltip"
                                                      title="Estimated position"
                                                    ></i>
                                                    <span
                                                      class="text-success fw-bold"
                                                      style={{ marginLeft: 5 }}
                                                    >
                                                      #
                                                      {
                                                        estimatedPosition?.estimated_position
                                                      }
                                                    </span>
                                                  </div>
                                                  // if the bid amount is greater than the current position
                                                ) : (
                                                  <div class="d-flex align-items-center">
                                                    {
                                                      estimatedPosition?.current_position ?
                                                        // if the current position is defined
                                                        (
                                                          <>
                                                            <small
                                                              class="text-danger"
                                                              style={{ marginRight: 5 }}
                                                            >
                                                              {"#" + estimatedPosition?.current_position}
                                                            </small>
                                                            <i
                                                              class="ri-arrow-right-line"
                                                              data-bs-toggle="tooltip"
                                                              title="Estimated position"
                                                            ></i>
                                                            <span
                                                              class="text-success fw-bold"
                                                              style={{ marginLeft: 5 }}
                                                            >
                                                              #
                                                              {
                                                                estimatedPosition?.estimated_position
                                                              }
                                                            </span>
                                                          </>
                                                        )
                                                        // if the current position is not defined
                                                        :
                                                        (
                                                          <>
                                                            <span
                                                              class="text-success fw-bold"
                                                              style={{ marginLeft: 5 }}
                                                            >
                                                              #
                                                              {
                                                                estimatedPosition?.estimated_position
                                                              }
                                                            </span>
                                                          </>
                                                        )
                                                    }
                                                  </div>
                                                )
                                            // if the bid amount is greater than the estimated position
                                          ) : (
                                            "#" +
                                            estimatedPosition?.estimated_position
                                          )
                                      }
                                    </span>
                                  </div>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip placement="bottom">
                                        The minimum required bid for this
                                        inventory.
                                      </Tooltip>
                                    }
                                  >
                                    <div className="auction-info-item">
                                      <i className="ri-coins-line"></i>
                                      <span>Minimum Bid</span>
                                      <span className="number">
                                        ${inventoryCampaign?.min_bid}
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip placement="bottom">
                                        The current top bid on this inventory.
                                      </Tooltip>
                                    }
                                  >
                                    <div className="auction-info-item">
                                      <i className="ri-vip-line"></i>
                                      <span>Current Top Bid</span>
                                      <span className="number">
                                        {inventoryCampaign?.top_bid}
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip placement="bottom">
                                        The amount of views this inventory
                                        received over the last 30 days.
                                      </Tooltip>
                                    }
                                  >
                                    <div className="auction-info-item">
                                      <i className="ri-funds-line"></i>
                                      <span>30 Day Views</span>
                                      <span className="number">
                                        {formatThousand(inventoryCampaign?.stat?.total_views)}
                                      </span>
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              </Col>
                              <Col sm="6" className="d-none d-sm-block">
                                <div className="auction-preview">
                                  <Row>
                                    <Col xs="12">
                                      <div id="bid-management">
                                        <div className="mb-3">
                                          <span className="section-title">
                                            Auction Preview
                                          </span>
                                          {!inventoryCampaign?.ppc_bid_active && (
                                            <Badge
                                              bg="danger"
                                              className="ms-2 inline-block"
                                            >
                                              PPC Inactive
                                            </Badge>
                                          )}
                                        </div>
                                        <ol className="vendor-list">
                                          {estimatedPosition?.inventory_campaigns?.map(
                                            (item, index) => {
                                              return (
                                                <li
                                                  key={index}
                                                  data-position={item.position}
                                                  className={
                                                    item.id ===
                                                      inventoryCampaign?.id
                                                      ? "vendor current"
                                                      : "vendor"
                                                  }
                                                >
                                                  <div className="d-sm-flex align-items-center">
                                                    <div
                                                      className="avatar avatar-xs"
                                                      style={{ minWidth: 20 }}
                                                    >
                                                      {item.id ===
                                                        inventoryCampaign?.id ? (
                                                        campaign?.logo_file ? (
                                                          <img
                                                            src={
                                                              campaign?.logo_file
                                                            }
                                                            alt="logo"
                                                            className="avatar-img"
                                                          />
                                                        ) : (
                                                          placeholderLogoModule(
                                                            publisherIdByAbbr,
                                                            currentSkin,
                                                            item.logo
                                                          )
                                                        )
                                                      ) : (
                                                        <div className="avatar-img" />
                                                      )}
                                                    </div>
                                                    <span className="ms-2 fs-sm">
                                                      {/* // TODO: 
                                                          - if placement name is defined in alt ver => show it
                                                          - check why the est list is not showing
                                                        */}
                                                      {item.id ===
                                                        inventoryCampaign?.id ? (
                                                        <strong>
                                                          {item.inventory_name}
                                                        </strong>
                                                      ) : (
                                                        <span>
                                                          {item.inventory_name}
                                                        </span>
                                                      )}
                                                      {item.id ===
                                                        inventoryCampaign?.id &&
                                                        inventoryCampaign.inventory_campaign_status ===
                                                        REVIEW_STATUS ? (
                                                        <OverlayTrigger
                                                          placement="bottom"
                                                          overlay={
                                                            <Tooltip placement="bottom">
                                                              We’re currently
                                                              reviewing this
                                                              bid. You will
                                                              feature on this
                                                              inventory if/when
                                                              it is approved.
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span className="badge bg-outline-warning ms-2">
                                                            Under Review
                                                          </span>
                                                        </OverlayTrigger>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {item.id ===
                                                        inventoryCampaign?.id &&
                                                        inventoryCampaign.inventory_campaign_status ===
                                                        DRAFT_STATUS ? (
                                                        <OverlayTrigger
                                                          placement="bottom"
                                                          overlay={
                                                            <Tooltip placement="bottom">
                                                              You need to submit
                                                              this bid for
                                                              review to feature
                                                              on this inventory.
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span className="badge bg-outline-secondary ms-2">
                                                            Draft
                                                          </span>
                                                        </OverlayTrigger>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {item.id ===
                                                        inventoryCampaign?.id &&
                                                        inventoryCampaign.inventory_campaign_status ===
                                                        LIVE_STATUS ? (
                                                        <OverlayTrigger
                                                          placement="bottom"
                                                          overlay={
                                                            <Tooltip placement="bottom">
                                                              You are currently
                                                              live on this
                                                              inventory. As long
                                                              as your position
                                                              is high enough you
                                                              will be featured.
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span className="badge bg-outline-success ms-2">
                                                            Approved
                                                          </span>
                                                        </OverlayTrigger>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {item.id ===
                                                        inventoryCampaign?.id &&
                                                        inventoryCampaign.inventory_campaign_status ===
                                                        REJECTED_STATUS ? (
                                                        <span className="badge bg-outline-danger ms-2">
                                                          Rejected
                                                        </span>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </span>
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            {
                              ((
                                estimatedPosition?.current_position &&
                                estimatedPosition?.current_position > 1
                              ) || (
                                  estimatedPosition?.estimated_position &&
                                  estimatedPosition?.estimated_position > 1
                                )) &&
                              campaign?.ppc_active &&
                              estimatedPosition?.missed_clicks?.est_missed_clicks &&
                              estimatedPosition?.missed_clicks?.est_click_opportunity && (
                                <Row className="mt-2 mb-2">
                                  <Col sm="12">
                                    <div class="ppc-htr-alert show mb-0">
                                      <div class="items-center w-100 d-flex align-items-center">
                                        <div class="icon" style={{ marginRight: 10 }}>
                                          <i class="ri-information-line"
                                            style={{ fontSize: 20 }}
                                          ></i>
                                        </div>
                                        {returnMessage(isHTR,
                                          estimatedPosition?.missed_clicks?.est_missed_clicks,
                                          estimatedPosition?.missed_clicks?.est_click_opportunity,
                                          estimatedPosition?.top_bid,
                                          inventoryCampaign?.cpc)}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              )
                            }
                            <Row className="mt-2">
                              <Col sm="12">
                                <div className="divider mt-2">
                                  <span>Landing Page URL</span>
                                  {/* <span>Landing Page URL(s)</span> */}
                                </div>
                              </Col>
                              <Col sm="12" className="text-center">
                                <Form className="row align-items-center">
                                  <div className="col-12">
                                    <div className="input-group">
                                      {/* <Form.Select className="input-group-select location-select" id="inlineFormSelectPref">
                                        <option selected>Choose...</option>
                                        <option value="1">Location 1</option>
                                        <option value="2">Location 2</option>
                                        <option value="3">Location 3</option>
                                      </Form.Select> */}
                                      <Form.Control
                                        type="url"
                                        id="landingPageLink"
                                        placeholder={campaign?.ppc_default_url}
                                        value={inventoryCampaign?.custom_url}
                                        onChange={(e) => onChangeCustomUrl(e)}
                                      />
                                      {/* <Button
                                        variant="outline-primary"
                                        onClick={() => onSaveCustomUrl()}
                                        disabled={
                                          oldInventoryCampaign?.custom_url ===
                                          inventoryCampaign?.custom_url
                                        }
                                      >
                                        Save
                                      </Button> */}
                                    </div>
                                  </div>
                                </Form>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                        <Col sm="12" className="text-center">
                          {inventoryCampaign?.inventory_campaign_status !==
                            DRAFT_STATUS &&
                            inventoryCampaign?.inventory_campaign_status !==
                            REVIEW_STATUS ? (
                            <Button
                              variant={
                                oldInventoryCampaign?.cpc ===
                                  inventoryCampaign?.cpc &&
                                  oldInventoryCampaign?.custom_url ===
                                  inventoryCampaign?.custom_url &&
                                  oldInventoryCampaign?.ppc_bid_active ===
                                  inventoryCampaign?.ppc_bid_active
                                  ? "outline-primary"
                                  : "primary"
                              }
                              className="block w-100 mt-3"
                              onClick={handleSave}
                              disabled={
                                oldInventoryCampaign?.cpc ===
                                inventoryCampaign?.cpc &&
                                oldInventoryCampaign?.custom_url ===
                                inventoryCampaign?.custom_url &&
                                oldInventoryCampaign?.ppc_bid_active ===
                                inventoryCampaign?.ppc_bid_active
                              }
                            >
                              {oldInventoryCampaign?.cpc !==
                                inventoryCampaign?.cpc
                                ? inventoryCampaign?.ppc_bid_active
                                  ? "Set Bid"
                                  : "Save (Bid Inactive)"
                                : "Save"}
                            </Button>
                          ) : (
                            <>
                              {inventoryCampaign?.inventory_campaign_status ===
                                DRAFT_STATUS ? (
                                <>
                                  <Row className="g-2">
                                    <Col sm="6">
                                      <Button
                                        variant="outline-primary"
                                        className="block w-100 mt-3"
                                        onClick={handleSave}
                                        disabled={
                                          oldInventoryCampaign?.cpc ===
                                          inventoryCampaign?.cpc &&
                                          oldInventoryCampaign?.custom_url ===
                                          inventoryCampaign?.custom_url &&
                                          oldInventoryCampaign?.ppc_bid_active ===
                                          inventoryCampaign?.ppc_bid_active
                                        }
                                      >
                                        Save
                                      </Button>
                                    </Col>
                                    <Col sm="6">
                                      <Button
                                        variant="primary"
                                        className="block w-100 mt-3"
                                        onClick={handleSubmitBid}
                                        disabled={
                                          inventoryCampaign?.ppc_bid_active ===
                                          false
                                        }
                                      >
                                        Submit Bid
                                      </Button>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}

                              {inventoryCampaign?.inventory_campaign_status ===
                                REVIEW_STATUS ? (
                                <>
                                  <Row className="g-2">
                                    <Col sm="6">
                                      <Button
                                        variant="outline-primary"
                                        className="block w-100 mt-3"
                                        onClick={handleSave}
                                        disabled={
                                          oldInventoryCampaign?.cpc ===
                                          inventoryCampaign?.cpc &&
                                          oldInventoryCampaign?.custom_url ===
                                          inventoryCampaign?.custom_url &&
                                          oldInventoryCampaign?.ppc_bid_active ===
                                          inventoryCampaign?.ppc_bid_active
                                        }
                                      >
                                        Save
                                      </Button>
                                    </Col>
                                    <Col sm="6">
                                      <Button
                                        variant="danger"
                                        className="block w-100 mt-3"
                                        onClick={handleWithdrawBid}
                                      >
                                        Withdraw Bid
                                      </Button>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          {!campaign.ppc_active && (
                            <Alert variant="danger" className="mt-3 mb-0">
                              <strong>Your campaign is inactive</strong> at the
                              moment. This is just a preview of the auction.
                            </Alert>
                          )}
                          {
                            campaign.ppc_active && campaign.ppc_paused && (
                              <Alert variant="warning" className="mt-3 mb-0">
                                <strong>Your campaign is paused</strong> at the
                                moment. This is just a preview of the auction.
                              </Alert>
                            )}
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>
                  <Offcanvas
                    show={showBottom}
                    onHide={handleCloseBottom}
                    placement="bottom"
                    className="card-one"
                    style={{ minHeight: "200px" }}
                  >
                    <Offcanvas.Header closeButton style={{ marginRight: 20 }}>
                      <Col xl="12" className="m-auto">
                        <Row
                          className="justify-content-center"
                          style={{ width: "100%", marginLeft: 20 }}
                        >
                          <Offcanvas.Title style={{ width: "max-content" }}>
                            {campaign.name} - Monthly Budget
                          </Offcanvas.Title>
                        </Row>
                      </Col>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Row className="mb-3">
                        <Col xl="12">
                          <Form>
                            <Col sm="12">
                              <Row className="justify-content-center align-items-center">
                                <Form.Label
                                  htmlFor="blockCpc"
                                  className="mb-0"
                                  style={{
                                    width: "max-content",
                                  }}
                                >
                                  $
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  id="blockBudget"
                                  defaultValue={
                                    Number(campaign?.ppc_monthly_budget?.replace(',', '')) || publisher?.default_budget
                                  }
                                  style={{ width: "200px" }}
                                  onChange={(e) => onChangeMonthlyBudget(e)}
                                />
                                <Button
                                  variant="primary"
                                  style={{
                                    width: "max-content",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => onSaveBudget()}
                                >
                                  Save Budget
                                </Button>
                              </Row>
                            </Col>
                            <Col sm="12">
                              <Row className="justify-content-end">
                                <Col xl="12">
                                  <span className="d-block text-muted fs-11 ff-secondary lh-4 mt-4 text-center">
                                    Your spend this month:{" "}
                                    <b>${campaign.ppc_monthly_spend}</b>
                                  </span>
                                </Col>
                                <span className="d-block text-muted fs-11 ff-secondary lh-4 text-center">
                                  Your next billing day:{" "}
                                  <b>
                                    {campaign.ppc_invoice_day}.{" "}
                                    {getShortMonthName(new Date())}
                                  </b>{" "}
                                  (in {days_diff} days)
                                </span>
                              </Row>
                            </Col>
                          </Form>
                        </Col>
                      </Row>
                    </Offcanvas.Body>
                  </Offcanvas>
                  {ppcAuctions && ppcAuctions.columns && loading && (
                    <React.Fragment>
                      <Placeholder
                        as={Card.Title}
                        animation="glow"
                        style={{ color: "var(--bs-body-color)" }}
                      >
                        <Placeholder style={{ height: 430, width: "100%" }} />
                      </Placeholder>
                    </React.Fragment>
                  )}
                  {ppcAuctions && ppcAuctions.columns && !loading && (
                    <div className="table-responsive cursor-pointer">
                      <Grid
                        ref={gridRef}
                        data={ppcAuctions.rows}
                        columns={formatColumns(ppcAuctions.columns)}
                        // columns={ppcAuctions.columns}
                        search={true}
                        pagination={{
                          enabled: true,
                          limit: 15,
                        }}
                        resizable={true}
                        sort={true}
                        className={{
                          table: "table table-hover mb-0",
                        }}
                      />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {isIframe ? <></> : <Footer />}
        </div>
      </React.Fragment>
    );
  }
}
