import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import deleteRecord from "../helpers/delete-record";
import editRecord from "../helpers/edit-record";
import getRecord from "../helpers/get-record";
import simpleTopList from "../assets/svg/simple_top_list.svg";
import topListOverview from "../assets/svg/top_list_overview.svg";
import simpleDynamicBanner from "../assets/svg/simple_dynamic_banner.svg";
import dynamic3PackBanner from "../assets/svg/dynamic_3pack_banner.svg";
import { loadImages } from "../services/micro";
import validateForRequired from "../helpers/validateForRequired";
import { createInventoryCampaignAltVerision, createPublisherInventoryAltVerision, deleteInventoryCampaignAltVersion, deletePublisherInventoryAltVersion, editInventoryCampaignAltVersion, editPublisherInventoryAltVersion, getAllInventoryCampaignAltVersion, getAllPublisherInventoryAltVersion } from "../services/pub";
import { useLocation, useNavigate } from "react-router-dom";
import { GetPubAbbr } from "../helpers/get-pub-abbr";

const PUBLISHER_INVENTORY = "publisher_inventory";
const INVENTORY_CAMPAIGNS = "inventory_campaigns";

const MAIN_INFORMATION_TAB = "main-information";
const LANGUAGES_TAB = "languages";

const STAGE_HTR_ID = 10;
const PRODUCTION_HTR_ID = 67;

export default function RecordEdit({
  values,
  table_name,
  title,
  redirect,
  breadcrumb,
  item_name,
}) {
  const [tableStructure, setTableStructure] = useState({});
  const [columnsValues, setColumnsValues] = useState(values);
  const [validationResults, setValidationResults] = useState({});
  const [secondaryPlacements, setSecondaryPlacements] = useState([]);
  const [tab, setTab] = useState(MAIN_INFORMATION_TAB);
  const user = useSelector((state) => state.user);
  const publisher_id = user.publisher_id;

  const navigate = useNavigate();

  const changeTab = (tab) => {
    setTab(tab);
  }

  const addInventorySecondaryPlacement = () => {
    setSecondaryPlacements([...secondaryPlacements, {
      id: new Date().getTime(),
      name: "",
      anchor_name: "",
      url: "",
      primary_category_id: "",
      primary_language_id: "",
      is_new: true
    }]);
    window.scrollTo(0, document.body.scrollHeight);
  }

  const addInventoryCampaignSecondaryPlacement = () => {
    setSecondaryPlacements([...secondaryPlacements, {
      id: new Date().getTime(),
      name: "",
      anchor_name: "",
      url: "",
      primary_category_id: "",
      primary_language_id: "",
      is_new: true
    }]);
    window.scrollTo(0, document.body.scrollHeight);
  }

  const removeInventoryCampaignSecondaryPlacement = (placement) => {
    const id = placement.id;

    if (placement.is_new) {
      setSecondaryPlacements(secondaryPlacements.filter(item => item.id !== placement.id));
      return;
    } else {
      deleteInventoryCampaignAltVersion(placement.id).then((res) => {
        if (res?.success) {
          toast.success("Secondary Placement deleted successfully!");
          setSecondaryPlacements(secondaryPlacements.filter(placement => placement.id !== id));
        } else {
          toast.error("Secondary Placement deleting failed!");
        }
      });
    }
  }

  const removeInventorySecondaryPlacement = (placement) => {
    const id = placement.id;

    if (placement.is_new) {
      setSecondaryPlacements(secondaryPlacements.filter(item => item.id !== placement.id));
      return;
    } else {
      deletePublisherInventoryAltVersion(placement.id).then((res) => {
        if (res.success) {
          toast.success("Secondary Placement deleted successfully!");
          setSecondaryPlacements(secondaryPlacements.filter(placement => placement.id !== id));
        } else {
          toast.error("Secondary Placement deleting failed!");
        }
      });
    }
  }

  useEffect(() => {
    console.log("values", values);
    getRecord(values.id, table_name).then((res) => {
      console.log("res", res);
      if ((res?.code < 200 || res?.code >= 300) || res === null || res === undefined) {
        const publisherIdByAbbr = GetPubAbbr();
        return navigate("pages/error-404?pub=" + publisherIdByAbbr);
      }

      setTableStructure(res.table);

      let newColumnsValues = res.data;
      Object.keys(newColumnsValues).forEach((key) => {
        if (
          res.table.columns.find((column) => column.name === key)?.type ===
          "date"
        ) {
          newColumnsValues[key] = new Date(newColumnsValues[key])
            .toISOString()
            .split("T")[0];
        } else if (
          res.table.columns.find((column) => column.name === key)?.type ===
          "number"
        ) {
          newColumnsValues[key] = Number(newColumnsValues[key]);
        } else if (
          res.table.columns.find((column) => column.name === key)?.type ===
          "boolean"
        ) {
          newColumnsValues[key] = Boolean(newColumnsValues[key]);
        } else if (
          res.table.columns.find((column) => column.name === key)?.type ===
          "text"
        ) {
          newColumnsValues[key] = String(newColumnsValues[key]);
        } else if (
          res.table.columns.find((column) => column.name === key)?.type ===
          "list"
        ) {
          newColumnsValues[key] = newColumnsValues[key]?.join(", ");
        } else if (
          res.table.columns.find((column) => column.name === key)?.type ===
          "selector"
        ) {
          const column = res.table.columns.find((column) => column.name === key);
          newColumnsValues[key] = column.options.find(option => Number(option.value) === Number(newColumnsValues[key]));
        } else if (
          res.table.columns.find((column) => column.name === key)?.type ===
          "multiselector"
        ) {
          const column = res.table.columns.find((column) => column.name === key);
          const values = newColumnsValues[key]?.map((value) => {
            return column.options.find(option => option.value == value);
          });
          
          newColumnsValues[key] = values;
        } else {
          newColumnsValues[key] = newColumnsValues[key];
        }
      });

      const newColumnsValuesWithoutNull = {};
      Object.keys(newColumnsValues).forEach((key) => {
        if (newColumnsValues[key] !== null && newColumnsValues[key] !== undefined && newColumnsValues[key] !== "null") {
          newColumnsValuesWithoutNull[key] = newColumnsValues[key];
        } else {
          newColumnsValuesWithoutNull[key] = null;
        }
      });

      setColumnsValues(newColumnsValuesWithoutNull);

      if (table_name === PUBLISHER_INVENTORY) {
        getAllPublisherInventoryAltVersion(values.id).then((res) => {
          if (res.success) {
            const altVersions = res.data.map((placement) => {
              return {
                id: placement.id,
                name: placement.custom_name,
                anchor_name: placement.anchor_name,
                url: placement.custom_url,
                inventory_id: placement.inventory_id,
                category_id: placement.category_id,
                language_id: placement.language_id,
              };
            });
            setSecondaryPlacements(altVersions);
          }
        });
      }

      if (table_name === INVENTORY_CAMPAIGNS) {
        getAllInventoryCampaignAltVersion(values.id).then((res) => {
          if (res.success) {
            const altVersions = res.data.map((placement) => {
              return {
                id: placement.id,
                tagline: placement.tagline,
                custom_fallback_url: placement.custom_fallback_url,
                name: placement.custom_name,
                anchor_name: placement.anchor_name,
                url: placement.custom_url,
                inventory_campaign_id: placement.inventory_campaign_id,
                category_id: placement.category_id,
                language_id: placement.language_id,
              };
            });
            setSecondaryPlacements(altVersions);
          }
        });
      }
    });
  }, []);

  const roundToMinIncrement = (e, column)=>
    column.min_increment ? Math.round(Number(e.target.value) / column.min_increment) * column.min_increment : e.target.value

  const redirectAfterSubmit = () => {
    window.location.href = redirect;
  };

  const onSubmitInventoryCampaignPrimaryPlacement = async (e) => {
    const id = columnsValues.id;
    // delete columnsValues.id;

    if (table_name !== INVENTORY_CAMPAIGNS) {
      return;
    }

    // if (
    //   !columnsValues.custom_name ||
    //   !columnsValues.custom_url
    // ) {
    //   toast.error("Please fill all required fields!");
    //   return;
    // }

    let newColumnsValues = {
      tagline: columnsValues.tagline,
      custom_fallback_url: columnsValues.custom_fallback_url,
      custom_name: columnsValues.custom_name,
      anchor_name: columnsValues.anchor_name,
      custom_url: columnsValues.custom_url,
      primary_category_id: columnsValues?.primary_category_id?.value,
      primary_language_id: columnsValues?.primary_language_id?.value,
    };

    editRecord(id, tableStructure.db_name, newColumnsValues).then((res) => {
      if (res.success) {
        toast.success("Primary Placement updated successfully!");
      } else {
        toast.error("Primary Placement updating failed!");
      }
    })
  }

  const onSubmitInventoryPrimaryPlacement = async (e) => {
    const id = columnsValues.id;
    // delete columnsValues.id;

    if (table_name !== PUBLISHER_INVENTORY) {
      return;
    }

    // if (
    //   !columnsValues.name ||
    //   !columnsValues.url
    // ) {
    //   toast.error("Please fill all required fields!");
    //   return;
    // }

    let newColumnsValues = {
      name: columnsValues.name,
      url: columnsValues.url,
      anchor_name: columnsValues.anchor_name,
      primary_category_id: columnsValues?.primary_category_id?.value,
      primary_language_id: columnsValues?.primary_language_id?.value,
    };

    editRecord(id, tableStructure.db_name, newColumnsValues).then((res) => {
      if (res?.success) {
        toast.success("Primary Placement updated successfully!");
      } else {
        toast.error("Primary Placement updating failed!");
      }
    })
  }

  const onCreateInventoryCampaignSecondaryPlacement = async (placement) => {
    const id = columnsValues.id;

    if (table_name !== INVENTORY_CAMPAIGNS) {
      return;
    }

    // if (
    //   !placement.name ||
    //   !placement.url
    // ) {
    //   toast.error("Please fill all required fields!");
    //   return;
    // }

    let newColumnsValues = {
      tagline: placement.tagline,
      custom_fallback_url: placement.custom_fallback_url,
      custom_name: placement.name,
      anchor_name: placement.anchor_name,
      custom_url: placement.url,
      inventory_campaign_id: id,
      category_id: placement?.category_id,
      language_id: placement?.language_id,
    };

    createInventoryCampaignAltVerision(newColumnsValues).then((res) => {
      if (res.success) {
        toast.success("Secondary Placement created successfully!");
        getAllInventoryCampaignAltVersion(id).then((res) => {
          if (res.success) {
            const altVersions = res.data.map((placement) => {
              return {
                id: placement.id,
                tagline: placement.tagline,
                custom_fallback_url: placement.custom_fallback_url,
                name: placement.custom_name,
                anchor_name: placement.anchor_name,
                url: placement.custom_url,
                inventory_campaign_id: placement
                  ? placement.inventory_campaign_id
                  : null,
                category_id: placement
                  ? placement.category_id
                  : null,
                language_id: placement
                  ? placement.language_id
                  : null,
              };
            });

            setSecondaryPlacements(altVersions);
          }
        });
      }
    });
  }

  const onCreateInventorySecondaryPlacement = async (placement) => {
    const id = columnsValues.id;
  
    if (table_name !== PUBLISHER_INVENTORY) {
      return;
    }

    // if (
    //   !placement.name ||
    //   !placement.url
    // ) {
    //   toast.error("Please fill all required fields!");
    //   return;
    // }

    let newColumnsValues = {
      custom_name: placement.name,
      anchor_name: placement.anchor_name,
      custom_url: placement.url,
      inventory_id: id,
      category_id: placement?.category_id,
      language_id: placement?.language_id,
    };

    createPublisherInventoryAltVerision(newColumnsValues).then((res) => {
      if (res.success) {
        toast.success("Secondary Placement created successfully!");
        getAllPublisherInventoryAltVersion(id).then((res) => {
          if (res.success) {
            const altVersions = res.data.map((placement) => {
              return {
                id: placement.id,
                name: placement.custom_name,
                anchor_name: placement.anchor_name,
                url: placement.custom_url,
                inventory_id: placement
                  ? placement.inventory_id
                  : null,
                category_id: placement
                  ? placement.category_id
                  : null,
                language_id: placement
                  ? placement.language_id
                  : null,
              };
            });

            setSecondaryPlacements(altVersions);
          }
        });
      } else {
        toast.error("Secondary Placement creating failed!");
      }
    });
  }

  const onEditInventoryCampaignSecondaryPlacement = async (placement) => {
    if (table_name !== INVENTORY_CAMPAIGNS) {
      return;
    }

    // if (
    //   !placement.name ||
    //   !placement.url
    // ) {
    //   toast.error("Please fill all required fields!");
    //   return;
    // }

    let newColumnsValues = {
      custom_name: placement.name,
      anchor_name: placement.anchor_name,
      custom_url: placement.url,
      category_id: placement
        ? placement.category_id
        : null,
      language_id: placement
        ? placement.language_id
        : null,
    };

    editInventoryCampaignAltVersion(placement.id, newColumnsValues).then(
      (res) => {
        if (res.success) {
          toast.success("Secondary Placement updated successfully!");
        } else {
          toast.error("Secondary Placement updating failed!");
        }
      }
    );
  }

  const onEditInventorySecondaryPlacement = async (placement) => {
    if (table_name !== PUBLISHER_INVENTORY) {
      return;
    }

    // if (
    //   !placement.name ||
    //   !placement.url
    // ) {
    //   toast.error("Please fill all required fields!");
    //   return;
    // }

    let newColumnsValues = {
      custom_name: placement.name,
      anchor_name: placement.anchor_name,
      custom_url: placement.url,
      category_id: placement
        ? placement.category_id
        : null,
      language_id: placement
        ? placement.language_id
        : null,
    };

    editPublisherInventoryAltVersion(placement.id, newColumnsValues).then(
      (res) => {
        if (res.success) {
          toast.success("Secondary Placement updated successfully!");
        } else {
          toast.error("Secondary Placement updating failed!");
        }
      }
    );
  }

  const onSubmit = async (e) => {
    const id = columnsValues.id;
    // delete columnsValues.id;

    if (table_name === PUBLISHER_INVENTORY) {
      delete columnsValues.name;
      delete columnsValues.url;
      delete columnsValues.primary_category_id;
      delete columnsValues.primary_language_id;
    }

    columnsValues.position = columnsValues?.position === "" ? null : columnsValues.position
    columnsValues.ppc_bid_active = columnsValues?.ppc_bid_active === "" ? null : columnsValues.ppc_bid_active

    let newColumnsValues = {...columnsValues};
    await Promise.all(Object.keys(newColumnsValues).map(async (key) => {

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "date"
      ) {
        newColumnsValues[key] = new Date(newColumnsValues[key]).getTime();
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "string"
      ) {
        newColumnsValues[key] = String(newColumnsValues[key]);
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "number"
      ) {
        newColumnsValues[key] = Number(newColumnsValues[key]);
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "boolean"
      ) {
        newColumnsValues[key] = Boolean(newColumnsValues[key]);
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "text"
      ) {
        newColumnsValues[key] = String(newColumnsValues[key]);
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "list"
      ) {
        newColumnsValues[key] = newColumnsValues[key].split(",").map((item) => item.trim());
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "selector"
      ) {
        const column = tableStructure.columns.find((column) => column.name === key);
        newColumnsValues[key] = column.options.find(option => option.label === newColumnsValues[key].label)?.value;
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "multiselector"
      ) {
        // const column = tableStructure.columns.find((column) => column.name === key);
        
        if (newColumnsValues[key]?.length) {
          const values = newColumnsValues[key]?.map((item) => {
            // return column.options.find(option => option.label === value)?.value;
            // return column.options.find(option => option.label === value.value)?.value;
            return item.value;
          });

          newColumnsValues[key] = values;
        } else {
          newColumnsValues[key] = []
        }
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "files"
      ) {
        const imagesResponse = await loadImages(newColumnsValues[key]);

        if (imagesResponse?.success) {
          newColumnsValues[key] = imagesResponse.data?.map(img=>img.id);
        } else {
          newColumnsValues[key] = [];
        }
      }

      if (
        tableStructure.columns.find((column) => column.name === key)?.type ===
        "file"
      ) {
        const imagesResponse = await loadImages(newColumnsValues[key]);
        
        if (imagesResponse?.success) {
          newColumnsValues[key] = imagesResponse.data?.map(img=>img.id)[0];
        } else {
          newColumnsValues[key] = null;
        }
      }
    }));

    const newColumnsValuesWithoutNull = {};
    const invalidValues = [null, undefined, "null", "undefined", NaN, "NaN"]

    Object.keys(newColumnsValues).forEach((key) => {
      if (!invalidValues.includes(newColumnsValues[key])) {
        newColumnsValuesWithoutNull[key] = newColumnsValues[key];
      } else {
        delete newColumnsValues[key];
      }
    });

    const isValid = validateForRequired(tableStructure, newColumnsValuesWithoutNull);
    setValidationResults(isValid);

    if (Object.values(isValid).every(result => result)) {
      toast.success("Record updating...");

      setTimeout(() => {
        editRecord(id, tableStructure.db_name, newColumnsValuesWithoutNull)
        .then((res) => {
          if (res.success) {
            toast.success("Record updated successfully!");
            redirectAfterSubmit();
          } else {
            toast.error("Record updating failed!");
          }
        })
        .catch((err) => {
          toast.error("You don't have permission to update this record!");
        });
      }, 3000);
    }
  }

  const onDelete = () => {
    const id = columnsValues.id;

    if (
      window.confirm("Are you sure you want to delete this record?") == true
    ) {
      toast.success("Record deleting...");

      deleteRecord(id, tableStructure.db_name)
        .then((res) => {
          if (res.success) {
            toast.success("Record deleted successfully!");
            redirectAfterSubmit();
          } else {
            toast.error("Record deleting failed!");
          }
        })
        .catch((err) => {
          toast.error("You don't have permission to delete this record!");
        });
    }
  };

  const handleSecondaryPlacementChange = (e, column) => {
    const newSecondaryPlacements = secondaryPlacements.map(placement => {
      if (placement.id === column.id) {
        if (column.type === "selector") {
          placement[column.name] = e.label ? e.value : null;
        } else {
          placement[column.name] = e.target.value;
        }
      }
      return placement;
    });

    setSecondaryPlacements(newSecondaryPlacements);
  };

  const handleColumnChange = (e, column) => {
    let newColumnsValues = columnsValues;

    if (column?.type === "boolean") {
      newColumnsValues[column.name] = e.target.checked;
    } else if (column?.type === "date") {
      newColumnsValues[column.name] = new Date(e.target.value).getTime();
    } else if (column?.type === "number") {
      if(e.target.value>0){
        newColumnsValues[column.name] = roundToMinIncrement(e, column)
      } else{
        newColumnsValues[column.name] = 0
      }
    } else if (column?.type === "text") {
      newColumnsValues[column.name] = String(e.target.value);
    } else if (column?.type === "list") {
      newColumnsValues[column.name] = e.target.value
        .split(",")
        .map((item) => item.trim());
    } else if (column?.type === "selector") {
      newColumnsValues[column.name] = e.label ? e : null;
    } else if (column?.type === "multiselector") {
      if (e.length) {
        // const eventValues = e.map(value => value.label)
        const eventValues = e.map(value => value)
        
        newColumnsValues[column.name] = eventValues
      } else {
        newColumnsValues[column.name] = ''
      }
    } else if (column.type === "files") {
      if (e.target.files.length <= 4) {
        newColumnsValues[column.name] = e.target.files ??  null;
      } else {
        e.target.value = null
        toast.error("You can upload up to 4 files!");
      }
    } else if (column.type === "file") {
        newColumnsValues[column.name] = e.target.files ??  null;
    } else {
      newColumnsValues[column.name] = e.target.value;
    }

    const newTableColumnsStructure = tableStructure.columns.map((column) => {
      column.value = newColumnsValues[column.name];
      return column;
    });

    const newColumnsValuesWithoutNull = {};
    Object.keys(newColumnsValues).forEach((key) => {
      if (newColumnsValues[key] !== null && newColumnsValues[key] !== undefined && newColumnsValues[key] !== "null") {
        newColumnsValuesWithoutNull[key] = newColumnsValues[key];
      } else if(!tableStructure.create_columns.find((column) => column.name === key)){
        delete newColumnsValues[key]
      } else {
        newColumnsValuesWithoutNull[key] = "";
      }
    });
    
    setColumnsValues(newColumnsValuesWithoutNull);
    setTableStructure({ ...tableStructure, columns: newTableColumnsStructure });
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <Nav.Item>
                <Nav.Link
                  className="text-primary"
                  href="/pub/dashboard/revenue"
                  to="/pub/dashboard/revenue"
                >
                  PUB Home
                </Nav.Link>
              </Nav.Item>
              <span className="mx-2">/</span>
              <Nav.Item>
                <Nav.Link className="text-primary" href={redirect}>
                  {breadcrumb}
                </Nav.Link>
              </Nav.Item>
              <span className="mx-2">/</span>
              <Nav.Item>
                <Nav.Link href="#">Update</Nav.Link>
              </Nav.Item>
            </ol>
            <h2 className="main-title mb-0">{title}</h2>
          </div>
          <Col md="6">
            <div className="d-flex align-items-center justify-content-end m-4">
              <Button
                onClick={() => onDelete()}
                variant="outline-primary"
                className="me-2"
                size="sm"
              >
                Delete
              </Button>
              <Button
                onClick={() => onSubmit()}
                variant="primary"
                size="sm"
              >
                Save
              </Button>
            </div>
          </Col>
        </div>
        <Row className="g-3 justify-content-center">
          { (table_name === PUBLISHER_INVENTORY || table_name === INVENTORY_CAMPAIGNS) &&
            <Nav className="nav-line mb-0 ml-4 mt-5">  
              {<Nav.Link href="#main-information" className={tab === MAIN_INFORMATION_TAB ? "active" : ''} onClick={() => changeTab(MAIN_INFORMATION_TAB)}>Main Information</Nav.Link>}
              {<Nav.Link href="#languages" className={tab === LANGUAGES_TAB ? "active" : ''} onClick={() => changeTab(LANGUAGES_TAB)}>Secondary Languages</Nav.Link>}
            </Nav> 
          }
          <Col md="12">
            <Row>
              <Col sm="12" md="6">
                { tab === MAIN_INFORMATION_TAB && 
                  <Card className="card-settings mt-2">
                    <Card.Body className="p-0">
                      {tableStructure &&
                        tableStructure.columns &&
                        tableStructure.columns.map((column, index) => {
                          if (
                            tableStructure.edit_columns.find(
                              (edit_column) => edit_column === column.name
                            )
                          ) {
                            if (table_name === PUBLISHER_INVENTORY && (
                              column.name === "name" ||
                              column.name === "anchor_name" ||
                              column.name === "url" ||
                              column.name === "primary_category_id" ||
                              column.name === "primary_language_id"
                            )) {
                              return <></>;
                            }

                            if (table_name === INVENTORY_CAMPAIGNS && (
                              column.name === "tagline" ||
                              column.name === "custom_fallback_url" ||
                              column.name === "custom_name" ||
                              column.name === "anchor_name" ||
                              column.name === "custom_url" ||
                              column.name === "primary_category_id" ||
                              column.name === "primary_language_id"
                            )) {
                              return <></>;
                            }

                            switch (column?.type) {
                              case "text":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Control
                                          type="text"
                                          placeholder={column.placeholder}
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          value={columnsValues[column.name]}
                                          isInvalid={validationResults[column.name] === false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "string":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Control
                                          type="text"
                                          placeholder={column.placeholder}
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          value={columnsValues[column.name]}
                                          isInvalid={validationResults[column.name] === false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "list":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>
                                          {column.title}
                                        </h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Control
                                          type="text"
                                          placeholder={column.placeholder}
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          value={columnsValues[column.name]}
                                          isInvalid={validationResults[column.name] === false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "file":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Control
                                          type="file"
                                          placeholder={column.placeholder}
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          accept=".png,.jpg,.jpeg"
                                          isInvalid={validationResults[column.name] === false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "files":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Control
                                          type="file"
                                          multiple
                                          placeholder={column.placeholder}
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          accept=".png,.jpg,.jpeg"
                                          isInvalid={validationResults[column.name] === false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "boolean":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Check
                                          type="switch"
                                          label={column.placeholder}
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          checked={columnsValues[column.name]}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "date":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Control
                                          type="date"
                                          placeholder={column.placeholder}
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          value={columnsValues[column.name]}
                                          isInvalid={validationResults[column.name] === false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "number":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                      <Form.Control
                                        type="number"
                                        placeholder={column.placeholder}
                                        onChange={(e) => handleColumnChange(e, column)}
                                        value={!columnsValues[column.name] ? '' : columnsValues[column.name]}
                                        step={column.min_increment ?? 1} 
                                        isInvalid={validationResults[column.name] === false}
                                      />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "selector":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                      <Select 
                                          id="basic-select-single" 
                                          onChange={(e) =>{
                                            handleColumnChange(e, column)}
                                          }
                                          options={column.options.map((option) => ({
                                            label: option.label,
                                            value: option.value,
                                          }))} 
                                          placeholder={column.placeholder}
                                          value={[columnsValues[column.name]]}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              case "multiselector":
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>
                                          {column.title}
                                        </h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                      <Select 
                                          id="basic-typeahead-single" 
                                          onChange={(e) =>
                                            handleColumnChange(e, column)
                                          }
                                          options={column.options?.map((option) => { return {
                                            label: option.label,
                                            value: option.value,
                                          }})} 
                                          isSearchable={true} 
                                          placeholder={column.placeholder}
                                          selected={[columnsValues[column.name]]}
                                          defaultValue={columnsValues[column.name]}
                                          isMulti 
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              default:
                                return (
                                  <div className="setting-item" key={index}>
                                    <Row className="g-2 align-items-center">
                                      <Col md="3">
                                        <h6>{column.title}</h6>
                                        <p>
                                          {column.is_required ? "Required" : ""}
                                        </p>
                                      </Col>
                                      <Col md>
                                        <Form.Control
                                          type="text"
                                          placeholder={column.placeholder}
                                          isInvalid={validationResults[column.name] === false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                            }
                          } else {
                            return <></>;
                          }
                        })}
                    </Card.Body>
                  </Card>
                }
                {/* Special Case for Inventory Campaigns Placeholders */}
                {
                  tab === MAIN_INFORMATION_TAB &&
                  table_name === INVENTORY_CAMPAIGNS && (
                    <Card className="card-settings mt-4">
                      <Card.Header>
                        <div className="flex-fill d-flex justify-content-between">
                          <h5>Primary Placement</h5>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Tagline</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"Tagline"}
                                onChange={(e) => {
                                  const taglineColumn = tableStructure.columns.find((column) => column.name === "tagline");
                                  handleColumnChange(e, taglineColumn);
                                }}
                                value={columnsValues["tagline"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Custom Fallback URL</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"Custom Fallback URL"}
                                onChange={(e) => {
                                  const customFallbackUrlColumn = tableStructure.columns.find((column) => column.name === "custom_fallback_url");
                                  handleColumnChange(e, customFallbackUrlColumn);
                                }}
                                value={columnsValues["custom_fallback_url"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Custom Name</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"Name"}
                                onChange={(e) => {
                                  const nameColumn = tableStructure.columns.find((column) => column.name === "custom_name");
                                  handleColumnChange(e, nameColumn);
                                }}
                                value={columnsValues["custom_name"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Anchor Name</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"Anchor Name"}
                                onChange={(e) => {
                                  const nameColumn = tableStructure.columns.find((column) => column.name === "anchor_name");
                                  handleColumnChange(e, nameColumn);
                                }}
                                value={columnsValues["anchor_name"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Custom URL</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"URL"}
                                onChange={(e) => {
                                  const urlColumn = tableStructure.columns.find((column) => column.name === "custom_url");
                                  handleColumnChange(e, urlColumn);
                                }}
                                value={columnsValues["custom_url"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item" style={{display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) ? "block" : "none"}}>
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Category</h6>
                            </Col>
                            <Col md>
                            <Select 
                                id="basic-select-single" 
                                onChange={(e) => {
                                  const categoryColumn = tableStructure.columns.find((column) => column.name === "primary_category_id");
                                  handleColumnChange(e, categoryColumn);
                                }}
                                options={
                                  tableStructure?.columns?.find((column) => column.name === "primary_category_id")?.options?.map((option) => ({
                                    label: option.label,
                                    value: option.value,
                                  }))
                                } 
                                placeholder={"Select Category"}
                                value={columnsValues["primary_category_id"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Language</h6>
                            </Col>
                            <Col md>
                            <Select 
                                id="basic-select-single" 
                                onChange={(e) => {
                                  const languageColumn = tableStructure.columns.find((column) => column.name === "primary_language_id");
                                  handleColumnChange(e, languageColumn);
                                }}
                                options={
                                  tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.map((option) => ({
                                    label: option.label,
                                    value: option.value,
                                  }))
                                } 
                                placeholder={"Select Language"}
                                value={columnsValues["primary_language_id"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <Col md="12">
                          <div className="d-flex align-items-center justify-content-end m-4">
                            <Button
                              onClick={() => onSubmitInventoryCampaignPrimaryPlacement()}
                              variant="outline-primary"
                              size="sm"
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Card.Body>
                    </Card>
                  )
                }
                {/* Special Case for Publisher Inventory Placeholders */}
                {
                  tab === MAIN_INFORMATION_TAB &&
                  table_name === PUBLISHER_INVENTORY && (
                    <Card className="card-settings mt-4">
                      <Card.Header>
                        <div className="flex-fill d-flex justify-content-between">
                          <h5>Primary Placement</h5>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Name</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"Name"}
                                onChange={(e) => {
                                  const nameColumn = tableStructure.columns.find((column) => column.name === "name");
                                  handleColumnChange(e, nameColumn);
                                }}
                                value={columnsValues["name"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Anchor Name</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"Anchor Name"}
                                onChange={(e) => {
                                  const nameColumn = tableStructure.columns.find((column) => column.name === "anchor_name");
                                  handleColumnChange(e, nameColumn);
                                }}
                                value={columnsValues["anchor_name"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>URL</h6>
                            </Col>
                            <Col md>
                              <Form.Control
                                type="text"
                                placeholder={"URL"}
                                onChange={(e) => {
                                  const urlColumn = tableStructure.columns.find((column) => column.name === "url");
                                  handleColumnChange(e, urlColumn);
                                }}
                                value={columnsValues["url"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Category</h6>
                            </Col>
                            <Col md>
                            <Select 
                                id="basic-select-single" 
                                onChange={(e) => {
                                  const categoryColumn = tableStructure.columns.find((column) => column.name === "primary_category_id");
                                  handleColumnChange(e, categoryColumn);
                                }}
                                options={
                                  tableStructure?.columns?.find((column) => column.name === "primary_category_id")?.options?.filter((option) => {
                                    return columnsValues["related_categories_ids"].find((relatedCategory) => relatedCategory?.value === option?.value);
                                  }).map((option) => ({
                                    label: option.label,
                                    value: option.value,
                                  }))
                                } 
                                placeholder={"Select Category"}
                                value={columnsValues["primary_category_id"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="setting-item">
                          <Row className="g-2 align-items-center">
                            <Col md="3">
                              <h6>Language</h6>
                            </Col>
                            <Col md>
                            <Select 
                                id="basic-select-single" 
                                onChange={(e) => {
                                  const languageColumn = tableStructure.columns.find((column) => column.name === "primary_language_id");
                                  handleColumnChange(e, languageColumn);
                                }}
                                options={
                                  tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.map((option) => ({
                                    label: option.label,
                                    value: option.value,
                                  }))
                                } 
                                placeholder={"Select Language"}
                                value={columnsValues["primary_language_id"]}
                              />
                            </Col>
                          </Row>
                        </div>
                        <Col md="12">
                          <div className="d-flex align-items-center justify-content-end m-4">
                            <Button
                              onClick={() => onSubmitInventoryPrimaryPlacement()}
                              variant="outline-primary"
                              size="sm"
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      </Card.Body>
                    </Card>
                  )
                }
                {
                  tab === LANGUAGES_TAB &&
                  table_name === INVENTORY_CAMPAIGNS &&
                    secondaryPlacements.map((placement, index) => {
                      return (
                        <Card className="card-settings mt-4" key={index}>
                          <Card.Body className="p-0">
                            <div className="setting-item">
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Language</h6>
                                </Col>
                                <Col md>
                                <Select 
                                    id="basic-select-single" 
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "language_id", type: "selector"});
                                    }}
                                    options={
                                      tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options
                                      .filter((option) => {
                                        return (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || (columnsValues["primary_language_id"]?.value !== option?.value &&
                                        secondaryPlacements.find((secondaryPlacement) => secondaryPlacement.id !== placement.id && secondaryPlacement.language_id !== option.value))
                                      }).map((option) => ({
                                        label: option.label,
                                        value: option.value,
                                      }))
                                    }
                                    placeholder={"Select Language"}
                                    value={
                                      tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id)
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) ? "block" : "none"}}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Category</h6>
                                </Col>
                                <Col md>
                                <Select 
                                    id="basic-select-single" 
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "category_id", type: "selector"});
                                    }}
                                    options={tableStructure?.columns?.find((column) => column.name === "primary_category_id")?.options.map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                    }))} 
                                    placeholder={"Select Category"}
                                    value={
                                      tableStructure?.columns?.find((column) => column.name === "primary_category_id")?.options.find((option) => option.value === placement.category_id)
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Tagline</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"Tagline"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "tagline"});
                                    }}
                                    value={placement.tagline}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Custom Fallback URL</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"Custom Fallback URL"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "custom_fallback_url"});
                                    }}
                                    value={placement.custom_fallback_url}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Custom Name</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"Name"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "name"});
                                    }}
                                    value={placement.name}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Anchor Name</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"Anchor Name"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "anchor_name"});
                                    }}
                                    value={placement.anchor_name}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Custom URL</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"URL"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "url"});
                                    }}
                                    value={placement.url}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <Col md="12">
                              <div className="d-flex align-items-center justify-content-end m-4">
                                <Button
                                  onClick={() => removeInventoryCampaignSecondaryPlacement(placement)}
                                  variant="m-2"
                                  size="sm"
                                >
                                  Delete Placement
                                </Button>
                                {
                                  placement.is_new ? (
                                    <Button onClick={() => onCreateInventoryCampaignSecondaryPlacement(placement)} variant="outline-primary" size="sm">
                                      Create
                                    </Button>
                                  ) : (
                                    <Button onClick={() => onEditInventoryCampaignSecondaryPlacement(placement)} variant="outline-primary" size="sm">
                                      Save
                                    </Button>
                                  )
                                }
                              </div>
                            </Col>
                          </Card.Body>
                        </Card>
                      )
                    })
                }
                { 
                  tab === LANGUAGES_TAB &&
                  table_name === PUBLISHER_INVENTORY &&
                    secondaryPlacements.map((placement, index) => {
                      return (
                        <Card className="card-settings mt-4" key={index}>
                          <Card.Body className="p-0">
                            <div className="setting-item">
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Language</h6>
                                </Col>
                                <Col md>
                                <Select 
                                    id="basic-select-single" 
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "language_id", type: "selector"});
                                    }}
                                    options={
                                      tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options
                                      .filter((option) => {
                                        return (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || (columnsValues["primary_language_id"]?.value !== option?.value &&
                                        secondaryPlacements.find((secondaryPlacement) => secondaryPlacement?.id !== placement?.id && secondaryPlacement?.language_id !== option?.value))
                                      }).map((option) => ({
                                        label: option.label,
                                        value: option.value,
                                      }))
                                    }
                                    placeholder={"Select Language"}
                                    value={
                                      tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id)
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) ? "block" : "none"}}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Category</h6>
                                </Col>
                                <Col md>
                                <Select 
                                    id="basic-select-single" 
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "category_id", type: "selector"});
                                    }}
                                    options={columnsValues?.related_categories_ids?.map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                    }))} 
                                    placeholder={"Select Category"}
                                    value={
                                      tableStructure?.columns?.find((column) => column.name === "primary_category_id")?.options.find((option) => option.value === placement.category_id)
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Name</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"Name"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "name"});
                                    }}
                                    value={placement.name}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>Anchor Name</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"Name"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "anchor_name"});
                                    }}
                                    value={placement.anchor_name}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div className="setting-item" style={{ display: (publisher_id === STAGE_HTR_ID || publisher_id === PRODUCTION_HTR_ID) || tableStructure?.columns?.find((column) => column.name === "primary_language_id")?.options.find((option) => option.value === placement.language_id) ? "block" : "none" }}>
                              <Row className="g-2 align-items-center">
                                <Col md="3">
                                  <h6>URL</h6>
                                </Col>
                                <Col md>
                                  <Form.Control
                                    type="text"
                                    placeholder={"URL"}
                                    onChange={(e) => {
                                      handleSecondaryPlacementChange(e, {id: placement.id, name: "url"});
                                    }}
                                    value={placement.url}
                                  />
                                </Col>
                              </Row>
                            </div>
                            <Col md="12">
                              <div className="d-flex align-items-center justify-content-end m-4">
                                <Button
                                  onClick={() => removeInventorySecondaryPlacement(placement)}
                                  variant="m-2"
                                  size="sm"
                                >
                                  Delete
                                </Button>
                                {
                                  placement.is_new ? (
                                    <Button onClick={() => onCreateInventorySecondaryPlacement(placement)} variant="outline-primary" size="sm">
                                      Create
                                    </Button>
                                  ) : (
                                    <Button onClick={() => onEditInventorySecondaryPlacement(placement)} variant="outline-primary" size="sm">
                                      Save
                                    </Button>
                                  )
                                }
                              </div>
                            </Col>
                          </Card.Body>
                        </Card>
                      )
                    }
                  )  
                }
                { 
                  tab === LANGUAGES_TAB &&
                  table_name === PUBLISHER_INVENTORY &&
                    <Row className="g-2 align-items-center">
                      <Button
                        variant="btn-outline-primary"
                        onClick={
                          () => addInventorySecondaryPlacement()
                        }
                        className={`d-flex align-items-center gap-2 btn btn-outline-primary mt-4 justify-content-center`}
                      >
                        Add Secondary Placement
                      </Button>
                    </Row>
                }
                {
                  tab === LANGUAGES_TAB &&
                  table_name === INVENTORY_CAMPAIGNS && (
                    <Row className="g-2 align-items-center">
                      <Button
                        variant="btn-outline-primary"
                        onClick={
                          () => addInventoryCampaignSecondaryPlacement()
                        }
                        className={`d-flex align-items-center gap-2 btn btn-outline-primary mt-4 justify-content-center`}
                      >
                        Add Secondary Placement
                      </Button>
                    </Row>
                  )
                }
              </Col>
              {/* Special Case for Publisher Inventory */}
              {table_name === PUBLISHER_INVENTORY && tab !== LANGUAGES_TAB && (
                <Col xs="12" sm="6" lg="3" xl="3">
                  {columnsValues?.box_types?.find((type) => type?.value === 1) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={simpleTopList}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Simple Top List
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          1 embed block
                        </Card.Subtitle>
                        <Card.Text>
                          Simple Top List block with custom number of products.
                          Compatible with live or interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                  {columnsValues?.box_types?.find((type) => type?.value === 2) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={topListOverview}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Top List + Product Overview
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          2 embed blocks
                        </Card.Subtitle>
                        <Card.Text>
                          Simple Top List & Overview blocks that stay in sync.
                          Compatible with live or interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                  {columnsValues?.box_types?.find((type) => type?.value === 3) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={simpleDynamicBanner}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Simple Dynamic Banner
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          1 embed block
                        </Card.Subtitle>
                        <Card.Text>
                          Simple Dynamic Banner block. Compatible with live or
                          interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                  {columnsValues?.box_types?.find((type) => type?.value === 4) && (
                    <Card className="mt-4">
                      <Card.Img
                        type="image/svg+xml"
                        src={dynamic3PackBanner}
                        variant="top"
                      />
                      <Card.Body>
                        <Card.Title className="text-dark">
                          Multi-Item Dynamic Banner
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          1 embed block
                        </Card.Subtitle>
                        <Card.Text>
                          Dynamic Multi-Item Banner blocks. Compatible with live
                          or interval auctions.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
