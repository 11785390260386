import React, { useEffect, useState } from "react";
import { getAllWebsites } from "../../services/pub";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import TablePagePlaceholder from "../../components/TablePagePlaceholder";
import { getToken } from "../../helpers/get-token";
import { useNavigate } from "react-router-dom";

export default function PublisherWebsite() {
  const [data, setData] = useState([]);
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/publisher-websites/all?token=${token}`;

  const navigate = useNavigate();
  
  useEffect(() => {
    getAllWebsites().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("You don't have permission to access this page.");
    });
  }, []);

  return (
    <React.Fragment>
      { data?.table ?
        <DataTable 
          title="Websites"
          data={data}
          endpoint={endpoint}
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/websites"
          new_btn_title="New Website"
          new_btn_redirect="/pub/website/new"
          edit_redirect="/pub/website/edit"
          redirect="/pub/websites"
        />
        :
        <TablePagePlaceholder
          title="Websites"
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/websites"
          new_btn_title="New Website"
          new_btn_redirect="/pub/website/new"
          edit_redirect="/pub/website/edit"
          redirect="/pub/websites"
        />
      }
    </React.Fragment>
  )
}