import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { startOfDayUTC } from "../../helpers/start-of-day-utc";
import { endOfDayUTC } from "../../helpers/end-of-day-utc";

async function getPeriodCampaignImpressionsAndClicks(currentPeriod, campaignId, current_token) {
    try {
        const token = current_token || getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/campaign/period/impressions-and-clicks', {
            current_period: [startOfDayUTC(new Date(currentPeriod[0])), endOfDayUTC(new Date(currentPeriod[1]))],
            campaign_id: campaignId
        })
        return response.data?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodCampaignImpressionsAndClicks;
