import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getPeriodInventoryTotals(currentPeriod, previousPeriod) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/inventory/period/totals', {
            current_period: [new Date(currentPeriod[0]).getTime(), new Date(currentPeriod[1]).getTime()],
            previous_period: [new Date(previousPeriod[0]).getTime(), new Date(previousPeriod[1]).getTime()]
        })
        return response.data?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodInventoryTotals;
