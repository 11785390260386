import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from "../components/DataTable";
import TablePagePlaceholder from "../components/TablePagePlaceholder";
import { useSelector, useDispatch } from 'react-redux';
import { getUserName } from "../helpers/user-name";
import { getUserRole } from "../helpers/user-role";
import { Button, Modal } from "react-bootstrap";
import { getAllCustomers } from "../services/adv";
import { getAllRoles, getRecentNotifications } from "../services/micro";
import { toast } from "react-toastify";
import { setAdvertiser, clearAdvertiser } from "../features/advertiser";
import readNotifications from "../services/micro/read_notifications";
import { getToken } from "../helpers/get-token";
import { useNavigate } from "react-router-dom";
import { LocalStorageKeyNames, useLocalStorageContext } from "../contexts/LocalStorageContext";

const PUBLISHER = 'Publisher';
const ADMIN = 'Admin';
const ADVERTISER = 'Client';

export default function Header({ onSkin=()=>{} }) {
  const [modalShow, setModalShow] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [data, setData] = useState([]);
  const [userAccesses, setUserAccesses] = useState([]);
  const [subRoles, setSubRoles] = useState([]);
  const [newNotificationAmount, setNewNotificationAmount] = useState(0);

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localStorageData, updateLocalStorageProperty, removeLocalStorageProperty } = useLocalStorageContext()

  const userRole = getUserRole(user)
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/clients-accounts/all?token=${token}`;

  const modalHandleClose = () => setModalShow(false);

  useEffect(() => {
    getAllCustomers().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("Server error");
    });

    getAllRoles().then((res) => {
      if (res.success) {
        setSubRoles(res.data);

        const userAccessesData = res.data.find((role) => role.name === user.role)?.avaliable_accesses;
        setUserAccesses(userAccessesData);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        // check if the clicked element is not the settings icon
        // if ((children.props.className !== "ri-settings-3-line")) {
        //   e.preventDefault();
        // }
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));
  

  useEffect(() => {
    getRecentNotifications().then(res => {
      setNewNotificationAmount(res.data.new);
      setNotifications(res.data.notifications);
    }).catch(e => {
      toast.error("Server error");
      navigate('/pages/signin');
    });
  }, []);

  // todo: move it back
  // useEffect(() => {
  //   if (notifications && notifications.length && Notification.permission === "granted") {
  //     notifications.forEach((notif) => {
  //       if (!sentNotifications.has(notif.id) && notif.status !== "read" && notif.is_notified_via_push) {
  //         const newNotification = new Notification(notif.text, {
  //           icon: notif.icon_url ?? "https://revbox-email-icons.s3.eu-central-1.amazonaws.com/logo_icon-white.png",
  //         });
  //         newNotification.onclick = () => window.open("https://github.com/revbox/revbox-hub/tree/develop");
  //         setSentNotifications(prev => new Set(prev).add(notif.id));
  //       }
  //     })
  //   }
  // }, [notifications, sentNotifications]);

  const openPage = (url) => {
    window.open(url, '_blank');
  }

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  const onReadNotification = (id, link) => {
    readNotifications([id]).then(res => {
      if (link) {
        window.location.replace(link)
      } else {
        navigate('/notifications');
      }
    }).catch(() => {
      toast.error("Server error");
      navigate('/pages/signin');
    });
  }

  function NotificationList() {
    const notiList = notifications.map((item, key) => {
      return (
        <li className="list-group-item" key={key} style={item.status === "read" ? { opacity: 0.35 } : {}} onClick={() => onReadNotification(item.id, item.link)}>
          { item.icon_url ?
            <div className={(item.status === "unread") ? "avatar online" : "avatar"} style={{ backgroundImage: 'url(' + item.icon_url + ')', backgroundPosition: 'center', backgroundSize: 'contain' }}/>
            :
            <div className={(item.status === "unread") ? "avatar online" : "avatar"}>
              <span class="avatar-initial">{item.title ? item.title[0] : item.text[0]}</span>
            </div> 
          }
          <div className="list-group-body">
            <p>{item.title ? item.title : item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      )
    });

    return (
      <ul className="list-group">
        {notiList.length > 0 ? notiList : <li className="list-group-item">You don't have any messages yet</li>}
      </ul>
    );
  }

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      updateLocalStorageProperty(LocalStorageKeyNames.skinMode, skin);

      onSkin(skin);

    } else {
      HTMLTag.removeAttribute("data-skin");
      removeLocalStorageProperty(LocalStorageKeyNames.skinMode);
      HTMLTag.removeAttribute("data-sidebar");
      onSkin('');
    }

  };

  const systemMode = (e) => {
    e.preventDefault();

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== undefined && skin !== "advertiser") {
      HTMLTag.setAttribute("data-sidebar", skin);
      updateLocalStorageProperty(LocalStorageKeyNames.sidebarSkin, skin);
    } else {
      removeLocalStorageProperty(LocalStorageKeyNames.sidebarSkin);
    }

    if (skin === "advertiser") {
      console.log("show Advertiser model");
      setModalShow(true);
    } else {
      removeLocalStorageProperty(LocalStorageKeyNames.sidebarSkin);
      dispatch(clearAdvertiser());
      removeLocalStorageProperty(LocalStorageKeyNames.advertiserId);
      modalHandleClose();

      if (user.role !== "developer" && user.role !== "content_manager" && user.role !== "sales") {
        navigate("/pub/dashboard/revenue");
      } else {
        navigate("/pub/inventory/queue");
      }
    }
  };

  const modalHandleSwith = (id) => {
    if (id) {
      dispatch(setAdvertiser({ id: id }));
      updateLocalStorageProperty(LocalStorageKeyNames.advertiserId, id);
      updateLocalStorageProperty(LocalStorageKeyNames.sidebarSkin, "advertiser");

      navigate('/adv/home');
    }

    modalHandleClose();
  }

  // avaliable_accesses
  // "user_account_management_access"
  // "finance_access"
  // "dashboards_access"
  // "inventory_access"
  // "advertiser_access"
  // "customers_access"
  // "user_roles_management_access"
  // "global_variables_access"
  // "webhook_access"
  // "api_key_access"
  // "notifications_access"

  const customizedColumns = [
    {
      name: "Name",
      rename: "Campaign Name",
    },
    {
      name: "Legal Name",
      hidden: true
    },
    {
      name: "Country",
      hidden: true
    },
    {
      name: "VAT",
      hidden: true
    },
    {
      name: "Created At",
      rename: "Created",
    }
  ]
  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      <div className="me-auto"></div>

      <Modal show={modalShow} onHide={modalHandleClose} size="xl" className="accounts-modal">
        <Modal.Header closeButton>
          <Modal.Title>Advertisers Accounts Access</Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          {data?.table ?
            <DataTable
              is_frame={true}
              handle_click={modalHandleSwith}
              title="Accounts"
              data={data}
              endpoint={endpoint}
              breadcrumb="Customers"
              customized_columns={customizedColumns}
              breadcrumb_redirect="/pub/customers/accounts"
              new_btn_title="New Acccount"
              new_btn_redirect="/pub/customer/new"
              edit_redirect="/pub/customer/edit"
              redirect="/pub/customers/accounts"
            />
            :
            <TablePagePlaceholder
              is_frame={true}
              handle_click={modalHandleSwith}
              title="Accounts"
              data={data}
              breadcrumb="Accounts"
              breadcrumb_redirect="/pub/customers/accounts"
              new_btn_title="Customers"
              new_btn_redirect="/pub/customer/new"
              edit_redirect="/pub/customer/edit"
              redirect="/pub/customers/accounts"
            />
          }
        </Modal.Body>
      </Modal>

      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f header-dropdown-menu">
          <label>Skin Mode</label>
          <nav className="nav nav-skin">
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"}>Light</Link>
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
          <hr />
          <label>System Mode</label>
          <nav id="systemMode" className="nav nav-skin">
            <div onClick={systemMode} className={!localStorageData[LocalStorageKeyNames.sidebarSkin] ? "nav-link active" : "nav-link"}>Publisher</div>
            {(
              userAccesses.includes("advertiser_access")
            ) && <div onClick={systemMode} className={localStorageData[LocalStorageKeyNames.sidebarSkin] === "advertiser" ? "nav-link active" : "nav-link"}>Advertiser</div>}
            {(
              userAccesses.includes("admin_access")
            ) && <div onClick={systemMode} className={localStorageData[LocalStorageKeyNames.sidebarSkin] === "admin" ? "nav-link active" : "nav-link"}>Admin</div>}
          </nav>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        {newNotificationAmount > 0 &&
          <Dropdown.Toggle as={CustomToggle}>
            <small>{newNotificationAmount > 9 ? '9+' : newNotificationAmount}</small><i className="ri-notification-3-line"></i>
          </Dropdown.Toggle>
        }
        {newNotificationAmount === 0 &&
          <Dropdown.Toggle as={CustomToggle}>
            <i className="ri-notification-3-line"></i>
          </Dropdown.Toggle>
        }
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h6 className="dropdown-menu-title">Notifications</h6>
            <Button variant="outline-primary" size="sm" onClick={() => openPage('/notifications/emails')}>Mail Center</Button>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer"><Link to="/notifications">Show all Notifications</Link></div>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <span className="avatar-initial bg-primary">{getUserName(user)[0]}</span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><span className="avatar-initial bg-primary">{getUserName(user)[0]}</span></div>
            <h5 className="mb-1 text-dark fw-semibold">{getUserName(user)}</h5>
            <p className="fs-sm text-secondary">{getUserRole(user)}</p>

            <nav className="nav">
            { userAccesses.includes("api_key_access") &&
              <Link to="https://docs.revbox.co/docs/intro/?code=XrUo7zIhhU<" target="_blank"><i className="ri-question-line"></i> Documentation</Link>
            }
            </nav>
            <hr />
            <nav className="nav">
              {/* <Link to="/adv/settings"><i className="ri-refund-line"></i> Revbox Billing</Link> */}
              <Link to="/adv/settings"><i className="ri-user-settings-line"></i> Account Settings</Link>
              {/* {
                userAccesses.includes("customers_access") && <Link to="/pub/customer/campaign/new"><i className="ri-add-line"></i> New Campaign</Link>
              } */}
            </nav>
            <hr />
            <nav className="nav">
              <Link to={`/pages/signin?pub=${user.publisher_id}&refresh=true`}><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}