import React, { useEffect, useState, useRef } from "react";
import { getPeriodCampaignInventoryStats } from "../../../services/micro";
import { Card, Col, Nav, Placeholder } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { Grid } from "gridjs-react";
import { compareStrNumbers } from "../../../helpers/compare-str-numbers";

const chartOption = {
  indexAxis: "y",
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      max: 100,
      grid: {
        borderColor: "#000",
        color: "#f3f5f9",
      },
      ticks: {
        color: "#212830",
        font: {
          size: 10,
          weight: "500",
        },
      },
    },
    y: {
      grid: {
        borderWidth: 0,
        color: "#f3f5f9",
      },
      ticks: {
        color: "#212830",
        font: {
          size: 12,
        },
      },
    },
  },
};

export default function CampaignInventoryStats(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  
  const { currentPeriod, previousPeriod, skin, campaignId, isIframe, view } = props;
  const [response, setResponse] = useState();

  const navigate = useNavigate();
  const gridRef = useRef();

  if (skin === "dark") {
    chartOption.scales["x"].grid.color = "#222b41";
    chartOption.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    chartOption.scales["x"].grid.borderColor = "#222b41";
    chartOption.scales["y"].grid.color = "#222b41";
    chartOption.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    chartOption.scales["x"].grid.color = "#edeff6";
    chartOption.scales["x"].ticks.color = "#42484e";
    chartOption.scales["x"].grid.borderColor = "#edeff6";
    chartOption.scales["y"].grid.color = "#edeff6";
    chartOption.scales["y"].ticks.color = "#42484e";
  }

  const openInventoryInsights = (id) => {
    if (!isIframe) {
      navigate("/pub/dashboard/inventory/insights?id=" + id);
    }
  };

  useEffect(() => {
    if (response) {
      const gridjsInstance = gridRef.current.getInstance();

      gridjsInstance.on("rowClick", (...args) => {
        const values = args[1]._cells.map((cell, index) => {
          return {
            value: cell.data,
            column: response.columns[index],
          };
        });
        
        const id = values[0].value;
        openInventoryInsights(id);
      });
    }
  }, [gridRef, response]);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      getPeriodCampaignInventoryStats(
        currentPeriod,
        previousPeriod,
        campaignId,
        token,
        view
      ).then((res) => {
        setResponse(res);
      });
    }
  }, [props]);

  const resultColumns = response?.columns.map((column, index) => {
    if (column !== "ID" && column !== "Name") {
      return {
        name: column,
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      };
    } else {
      return {
        name: column,
      };
    }
  });

  if (response) {
    return (
      <React.Fragment>
        <Col xs="12">
          <Card className="card-one">
            <Card.Header>
              <div class="d-flex align-items-center fs-xs text-secondary mb-0 mt-1">
                <i class="ri-information-line me-1"></i>
                It can take up to 10m for Inventory Stats to update and reflect the period spend above.
              </div>
            </Card.Header>
            <Card.Header>
              <div>
                <Card.Title as="h6">Inventory Stats</Card.Title>
                <div class="fs-xs text-secondary mb-0 mt-1">
                  {new Date(currentPeriod[0]).toLocaleString("default", {
                    month: "short",
                  })}{" "}
                  {new Date(currentPeriod[0]).getDate()}{" "}
                  {new Date(currentPeriod[0]).getFullYear()} -{" "}
                  {new Date(currentPeriod[1]).toLocaleString("default", {
                    month: "short",
                  })}{" "}
                  {new Date(currentPeriod[1]).getDate()}{" "}
                  {new Date(currentPeriod[1]).getFullYear()}
                </div>
              </div>
              <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
            </Card.Header>
            <Card.Body className="p-3 p-xl-3">
              <div
                className="table-responsive cursor-pointer"
              >
                <Grid
                  data={response.rows}
                  columns={resultColumns}
                  search={true}
                  ref={gridRef}
                  pagination={{
                    enabled: true,
                    limit: 15,
                  }}
                  resizable={true}
                  sort={true}
                  className={{
                    table: "table table-hover mb-0",
                  }}
                  rowClick={(_, row) => {
                    window.location.href = `your-link-here/${row.cells[0].data}`; // replace 'your-link-here' with the base URL
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
