import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { endOfDay } from 'date-fns';
import { startOfDayUTC } from "../../helpers/start-of-day-utc";

async function getPeriodRevenue(currentPeriod, previousPeriod) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/revenue/period', {
            current_period: [startOfDayUTC(new Date(currentPeriod[0])), endOfDay(new Date(currentPeriod[1]))],
            previous_period: [startOfDayUTC(new Date(previousPeriod[0])), endOfDay(new Date(previousPeriod[1]))]
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodRevenue;
