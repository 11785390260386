import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Nav, Placeholder } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { Grid } from "gridjs-react";
import { getPeriodInventoryPerformance } from "../../../services/micro";
import { abbreviationsMap } from "../../../modules/maps";
import SplittedTable from "../../../components/SplittedTable";
import { html } from "gridjs";
import { compareStrNumbers } from "../../../helpers/compare-str-numbers";

// Function to get the CSS variable value
const getCSSVariableValue = (variableName) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
};

export default function InventoryPerformance(props) {
  const { currentPeriod } = props;
  const [response, setResponse] = useState();
  const [reset, setReset] = useState(0);
  const [colors, setColors] = useState({ chartPrimary: '', chartSecondary: '', chartTertiary: '' });
  const [isOneDayPicked, setIsOneDayPicked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setColors({
      chartPrimary: getCSSVariableValue('--primary'),
      chartSecondary: getCSSVariableValue('--chart-secondary'),
      chartTertiary: getCSSVariableValue('--chart-tertiary')
    });
  }, []);

  useEffect(() => {
    if (response && response.periods[0].data.length === 1) {
      setIsOneDayPicked(true);
    } else {
      setIsOneDayPicked(false);
    }
  }, [response])

  const optionMetrics = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: false,
      zoom: {
        enabled: false,
      }
    },
    colors: [colors.chartSecondary, colors.chartPrimary],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      yaxis: {
        lines: { show: false },
      },
      padding: { top: -20 },
    },
    stroke: {
      curve: "smooth",
      width: [2, 0.25],
    },

    yaxis: {
      min: 0,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      decimalsInFloat: 0,
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
        formatter: function (val) {
          const date = new Date(val);
          return `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, dataPointIndex, w }) {
        const date = new Date(w.globals.seriesX[0][dataPointIndex]);
        const formattedDate = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;
        return (
          `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
            <span class="apexcharts-tooltip-marker" style="background-color: ${colors.chartSecondary};"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">Views: </span>
                <span class="apexcharts-tooltip-text-y-value">${Number(series[0][dataPointIndex] || 0).toLocaleString()}</span>
              </div>
            </div>
          </div>
          <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 2; display: flex;">
            <span class="apexcharts-tooltip-marker" style="background-color: ${colors.chartPrimary};"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">Clicks: </span>
                <span class="apexcharts-tooltip-text-y-value">${Number(series[1][dataPointIndex] || 0).toLocaleString()}</span>
              </div>
            </div>
          </div>`
        );
      },
    },
  };



  const optionFour = {
    chart: {
      parentHeightOffset: 20,
      stacked: false,
      toolbar: { show: false },
      animations: {
        enabled: true,
      },
      type: "line",
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        bottom: 0,
        left: -10,
        right: -10,
      },
    },
    colors: [colors.chartPrimary, colors.chartSecondary, colors.chartTertiary],
    plotOptions: {
      bar: { columnWidth: "50%" },
    },
    stroke: {
      curve: ["smooth", "smooth", "smooth"],
      width: [1.5, 1.5, 2],
    },
    fill: {
      type: ["solid", "gradient", "solid"],
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.35,
        opacityTo: 0.2,
        stops: [0, 100],
        gradientToColors: ["#f3f5fc"],
      },
    },
    yaxis: [
      {
        seriesName: "Clicks",
        opposite: true,
        max: 10,
        labels: {
          show: false,
          style: {
            colors: [colors.chartPrimary],
            fontSize: "11px",
            fontWeight: "bold",
          },
          formatter: function (val) {
            return val + " clicks";
          },
        },
      },
      {
        seriesName: "Views",
        opposite: false,
        labels: {
          show: false,
          style: {
            colors: ["rgba(204, 204, 204, 0.5)"],
            fontSize: "11px",
          },
        },
      },
      {
        seriesName: "Campaign Spend",
        opposite: false,
        max: 3000,
        labels: {
          show: false,
          style: {
            colors: [colors.chartTertiary],
            fontSize: "11px",
            fontWeight: "bold",
          },
          formatter: function (val) {
            return "$" + val + ".00";
          },
        },
      },
    ],
    dataLabels: { enabled: false },
    xaxis: {
      type: "numeric",
      decimalsInFloat: 0,
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "9px",
        },
        formatter: function (val) {
          const date = new Date(val);
          return `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;
        },
      },
      axisBorder: { show: false },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const date = new Date(w.globals.seriesX[0][dataPointIndex]);
        const formattedDate = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;
        return (
          `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
            <span class="apexcharts-tooltip-marker" style="background-color: ${colors.chartPrimary};"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              <div class="apexcharts-tooltip-y-group">
                <span class="apexcharts-tooltip-text-y-label">${w.config.series[seriesIndex].name}: </span>
                <span class="apexcharts-tooltip-text-y-value">$${Number(series[seriesIndex][dataPointIndex] || 0).toLocaleString()}.00</span>
              </div>
            </div>
          </div>`
        );
      },
    },
  };

  const openInventoryInsights = (id) => {
    navigate("/pub/dashboard/inventory/insights?id=" + id);
  };

  useEffect(() => {
    if (currentPeriod) {
      if (currentPeriod[0] && currentPeriod[1]) {
        getPeriodInventoryPerformance(currentPeriod).then(
          (res) => {
            setResponse(res);
          }
        );
      }
    }
  }, [props]);


  const onPerformanceReset = () => {
    setReset(reset + 1);
  };

  const handleOnClick = (args) => {
    const values = args[1]._cells.map((cell, index) => {
      return {
        value: cell.data,
        column: response.columns[index],
      };
    });

    const id = values[0].value;
    openInventoryInsights(id);
  }

  if (response) {
    optionFour.yaxis[0].max = response.maxClicks;
    optionFour.yaxis[1].max = response.maxViews;
    optionFour.yaxis[2].max = response.maxRevenue;
    optionFour.xaxis.tickAmount = response.periods[0].data.length > 10 ? 10 : response.periods[0].data.length;

    const currentColumns = response.columns.slice(0, 3);
    const periodColumns = response.columns.slice(3);

    const customizedColumns = [
      {
        name: "Type",
        hidden: true,
      },
      {
        name: "Categories",
        hidden: true,
      },
      {
        name: "Views",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Bids",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Free Clicks",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Paid Clicks",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Average CPC",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Top CPC",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Revenue",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      }
    ];

    return (
      <React.Fragment>
        <Col xs="12">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Inventory Performance</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto">
                <Nav.Link href="" onClick={onPerformanceReset}>
                  <i className="ri-refresh-line"></i>
                </Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body className="p-3 p-xl-3">
              <ReactApexChart
                key={reset}
                series={response.periods}
                options={optionMetrics}
                type="area"
                height={250}
                className="apex-chart-two chart-container"
              />
              <SplittedTable
                rows={response.rows}
                currentColumns={currentColumns}
                periodColumns={periodColumns}
                customizedColumns={customizedColumns}
                onRowClick={handleOnClick}
              />
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
