import React, { useEffect, useState } from "react";
import { getPubUsers } from "../../services/micro";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import TablePagePlaceholder from "../../components/TablePagePlaceholder";
import { getToken } from "../../helpers/get-token";
import { useNavigate } from "react-router-dom";

export default function PublisherUsers() {
  const [data, setData] = useState([]);
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/users/pub?token=${token}`;

  const navigate = useNavigate();
  
  useEffect(() => {
    getPubUsers().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("You don't have permission to access this page.");
    });
  }, []);

  
  return (
    <React.Fragment>
      { data?.table ?
        <DataTable 
          title="Users"
          data={data}
          endpoint={endpoint}
          breadcrumb="Customers"
          // breadcrumb="Customers"
          // breadcrumb_redirect="/pub/customers/users"
          new_btn_title="New User"
          new_btn_redirect="/pub/customer/user/new"
          edit_redirect="/pub/customer/user/edit"
          redirect="/pub/customers/users"
        />
        :
        <TablePagePlaceholder
          title="Users"
          breadcrumb="Customers"
          // breadcrumb="Customers"
          // breadcrumb_redirect="/pub/customers/users"
          new_btn_title="New User"
          new_btn_redirect="/pub/customer/user/new"
          edit_redirect="/pub/customer/user/edit"
          redirect="/pub/customers/users"
        />
      }
    </React.Fragment>
  )
}