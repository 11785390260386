import React, { useEffect, useState } from "react";
import { getAllCampaigns } from "../../services/adv";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import TablePagePlaceholder from "../../components/TablePagePlaceholder";
import { getToken } from "../../helpers/get-token";
import { useNavigate } from "react-router-dom";
import { h, html } from "gridjs";
import { GetPubAbbr } from "../../helpers/get-pub-abbr";
import { placeholderLogoModule } from "../../modules/placeholderLogo";
import { formatThousand } from "../../helpers/thousand-number-format";

export default function PublisherCampaigns() {
  const [data, setData] = useState([]);
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/client-campaigns/all?token=${token}`;
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const publisherIdByAbbr = GetPubAbbr();

  const navigate = useNavigate();

  useEffect(() => {
    getAllCampaigns().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("You don't have permission to access this page.");
    });
  }, []);

  const customizedColumns = [
    {
      name: "Created At",
      hidden: true,
    },
    {
      name: "Related Categories",
      hidden: true,
    },
    {
      name: "Logo",
      formatter: (value) => {
        if (value !== "Unknown") {
          return html(
            `<img src="${value}" style="max-width: 50px; max-height: 50px;" />`
          )
        } else {
          return html(
            `
              <img src="${placeholderLogoModule(publisherIdByAbbr, currentSkin).props.src}" style="max-width: 30px; max-height: 30px; border-radius: 5px;" />
            `
          )
        }
      },
    },
    {
      name: "Billing Type",
      formatter: (value) => {
        return html(
          `<div class="badge bg-primary text-white px-2 py-1 rounded">${value}</div>`
        )
      },
    },
    {
      name: "PPC Monthly Budget",
      rename: "Current Budget",
      formatter: (value, row) => {
        const row_monthly_spend = row._cells[10].data.replace(/[$,]/g, '');
        const row_monthly_budget = row._cells[9].data.replace(/[$,]/g, '');

        const ppc_monthly_spend = Number(row_monthly_spend);
        const ppc_monthly_budget = Number(row_monthly_budget);

        let spendPercentage = 0;

        if (ppc_monthly_budget > 0) spendPercentage = (ppc_monthly_spend / ppc_monthly_budget) * 100;
        if (ppc_monthly_budget === 0) spendPercentage = 0;
        if (ppc_monthly_spend === 0) spendPercentage = 0;

        return html(
          `<div class="revenue-item">
            <div class="progress mt-1" style="height: '6px'">
              ${
                spendPercentage > 100 ? 
                  `<div role="progressbar" class="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: ${spendPercentage + "%"}"></div>` 
                : spendPercentage > 75 ? 
                  `<div role="progressbar" class="progress-bar bg-warning" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: ${spendPercentage + "%"}"></div>`
                : `<div role="progressbar" class="progress-bar bg-success" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: ${spendPercentage + "%"}"></div>`
              }
            </div>
            <div class="revenue-item-body mb-0 mt-1">
              <span class="d-block fs-11 ff-secondary" style="min-width: 100px">$${formatThousand(ppc_monthly_spend)} / $${formatThousand(ppc_monthly_budget)}</span>
              <span class="d-block fs-11 ff-secondary" style="color: ${
                spendPercentage > 100 ? "var(--bs-danger)" : spendPercentage > 75 ? "var(--bs-warning)" : "var(--bs-success)"
              }">${spendPercentage.toFixed(0)}%</span>
            </div>
          </div>`
        );
      }
    },
    {
      name: "PPC Monthly Spend",
      rename: "Status",
      formatter: (value, row) => {
        const ppc_active = row._cells[5].data;
        const ppc_paused = row._cells[6].data;

        let status = "Inactive"

        if (ppc_active === "Yes") status = "Active";
        if (ppc_paused === "Yes") status = "Paused";

        if (status === "Paused") {
          return html(
            `<span class="badge bg-warning ms-2">
              Paused
            </span>`
          );
        }

        if (status === "Inactive") {
          return html(
            `<span class="badge bg-danger ms-2">
              Inactive
            </span>`
          );
        }

        return html(
          `<span class="badge bg-success ms-2">
            Active
          </span>`
        );
      },
    },
  ];
  
  return (
    <React.Fragment>
      { data?.table ?
        <DataTable 
          title="Campaigns"
          data={data}
          endpoint={endpoint}
          customized_columns={customizedColumns}
          breadcrumb="Customers"
          // breadcrumb="Customers"
          // breadcrumb_redirect="/pub/customers/campaigns"
          new_btn_title="New Campaign"
          new_btn_redirect="/pub/customer/campaign/new"
          edit_redirect="/pub/customer/campaign/edit"
          redirect="/pub/customer/campaigns"
        />
        :
        <TablePagePlaceholder
          title="Campaigns"
          breadcrumb="Customers"
          // breadcrumb="Customers"
          // breadcrumb_redirect="/pub/customers/campaigns"
          new_btn_title="New Campaign"
          new_btn_redirect="/pub/customer/campaign/new"
          edit_redirect="/pub/customer/campaign/edit"
          redirect="/pub/customer/campaigns"
        />
      }
    </React.Fragment>
  )
}