import { toast } from "react-toastify";

export default function validateForRequired(tableStructure, newRecord) {
    const validationResults = {};
  
    tableStructure.columns.forEach((column) => {
      if (column.is_required && (newRecord[column.name] === undefined || newRecord[column.name] === null || newRecord[column.name] === 0 || newRecord[column.name] === '')) {
        toast.error(`Error while creating record: ${column.title} is required!`);
        validationResults[column.name] = false;
      } else {
        validationResults[column.name] = true;
      }
    });
  
    return validationResults;
}