import React, { useEffect, useState, useRef } from "react";
import { getPeriodInventoryInsightsCampaignsAndPlaceholders } from "../../../../services/micro";
import { Card, Col, Nav, Placeholder } from "react-bootstrap";
import { html } from "gridjs";
import { useNavigate } from "react-router-dom";
import SplittedTable from "../../../../components/SplittedTable";
import { compareStrNumbers } from "../../../../helpers/compare-str-numbers";

const chartOption = {
  indexAxis: "y",
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      max: 100,
      grid: {
        borderColor: "#000",
        color: "#f3f5f9",
      },
      ticks: {
        color: "#212830",
        font: {
          size: 10,
          weight: "500",
        },
      },
    },
    y: {
      grid: {
        borderWidth: 0,
        color: "#f3f5f9",
      },
      ticks: {
        color: "#212830",
        font: {
          size: 12,
        },
      },
    },
  },
};

const optionFour = {
  chart: {
    parentHeightOffset: 20,
    stacked: false,
    toolbar: { show: false },
    animations: {
      enabled: true,
    },
    zoom: {
      enabled: false,
    },
    type: "line", // Set chart type to line
  },
  grid: {
    borderColor: "rgba(72,94,144, 0.07)",
    padding: {
      top: -20,
      bottom: 0,
      left: -10,
      right: -10,
    },
  },
  colors: ["#c1ccf1", "#2845f9", "#0cb785"], // Update colors for the first two series
  plotOptions: {
    bar: { columnWidth: "50%" },
  },
  stroke: {
    curve: ["smooth", "smooth", "smooth"], // Smooth curve for all series
    width: [1.5, 1.5, 2], // Update stroke width for the first two series
  },
  fill: {
    type: ["solid", "gradient", "solid"],
    opacity: 1,
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.35,
      opacityTo: 0.2,
      stops: [0, 100],
      gradientToColors: ["#f3f5fc"], // Apply gradient similar to optionCPC for the second series
    },
  },
  xaxis: {
    type: "numeric", // Match x-axis type to optionCPC
    tickAmount: 8,
    decimalsInFloat: 0,
    labels: {
      style: {
        colors: "#6e7985", // Match label style to optionCPC
        fontSize: "9px",
      },
    },
    axisBorder: { show: false },
  },
  yaxis: [
    {
      seriesName: "Clicks",
      opposite: true,
      tickAmount: 8,
      max: 500,
      labels: {
        show: false,
        style: {
          colors: ["#2845f9"],
          fontSize: "11px",
          fontWeight: "bold",
        },
        formatter: function (val) {
          return val + " clicks";
        },
      },
    },
    {
      seriesName: "Views",
      opposite: false,
      tickAmount: 8,
      labels: {
        show: false,
        style: {
          colors: ["rgba(204, 204, 204, 0.5)"],
          fontSize: "11px",
        },
      },
    },
    {
      seriesName: "Campaign Spend",
      opposite: false,
      tickAmount: 8,
      max: 3000,
      labels: {
        show: false,
        style: {
          colors: ["#0cb785"],
          fontSize: "11px",
          fontWeight: "bold",
        },
        formatter: function (val) {
          return "$" + val + ".00";
        },
      },
    },
  ],
  dataLabels: { enabled: false },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: function (value, { seriesIndex }) {
        if (seriesIndex === 2) {
          return "$" + value.toFixed(0) + ".00";
        }
        return value.toFixed(0);
      },
    },
  },
  legend: { show: true },
};

export default function InventoryInsightsCampaignsAndPlaceholders(props) {
  const { currentPeriod, previousPeriod, skin, inventoryId } = props;
  const [response, setResponse] = useState();

  const navigate = useNavigate();
  const gridRef = useRef();

  if (skin === "dark") {
    chartOption.scales["x"].grid.color = "#222b41";
    chartOption.scales["x"].ticks.color = "rgba(255,255,255,.65)";
    chartOption.scales["x"].grid.borderColor = "#222b41";
    chartOption.scales["y"].grid.color = "#222b41";
    chartOption.scales["y"].ticks.color = "rgba(255,255,255,.65)";
  } else {
    chartOption.scales["x"].grid.color = "#edeff6";
    chartOption.scales["x"].ticks.color = "#42484e";
    chartOption.scales["x"].grid.borderColor = "#edeff6";
    chartOption.scales["y"].grid.color = "#edeff6";
    chartOption.scales["y"].ticks.color = "#42484e";
  }

  const openCampaign = (id) => {
    navigate("/adv/campaign/analytics?campaignId=" + id);
  };

  const handleOnClick = (args) => {
    const values = args[1]._cells.map((cell, index) => {
      return {
        value: cell.data,
        column: response.columns[index],
      };
    });

    const id = values[0].value;
    openCampaign(id);
  };


  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (currentPeriod[0] && currentPeriod[1] && previousPeriod[0] && previousPeriod[1]) {
        getPeriodInventoryInsightsCampaignsAndPlaceholders(
          currentPeriod,
          previousPeriod,
          inventoryId
        ).then((res) => {
          setResponse(res);
        });
      }
    }
  }, [props]);

  if (response) {
    optionFour.yaxis[0].max = response.maxClicks;
    optionFour.yaxis[1].max = response.maxViews;
    optionFour.yaxis[2].max = response.maxRevenue;

    const currentColumns = response.columns.slice(0, 5);
    const periodColumns = response.columns.slice(5);

    const customizedColumns = [
      {
        name: "PPC",
        formatter: (value) => {
          if (value === "Paused") {
            return html(
              `<span class="badge bg-warning ms-2">
                Paused
              </span>`
            );
          }

          if (value === "Inactive") {
            return html(
              `<span class="badge bg-danger ms-2">
                Inactive
              </span>`
            );
          }

          return html(
            `<span class="badge bg-success ms-2">
              Active
            </span>`
          );
        },
      },
      {
        name: "Position",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "CPC",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Impressions",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Free Clicks",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Paid Clicks",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Average Pos",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Average CPC",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Total Spend",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
    ];

    return (
      <React.Fragment>
        <Col xs="12">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Campaigns & Placeholders</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
            </Card.Header>
            <Card.Body className="p-3 p-xl-3">
              <SplittedTable
                rows={response.rows}
                currentColumns={currentColumns}
                periodColumns={periodColumns}
                onRowClick={handleOnClick}
                customizedColumns={customizedColumns}
              />
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{color: "var(--bs-body-color)"}}>
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
