import { Form, Card, Table } from "react-bootstrap";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { editNotificationSettings, editUser } from "../services/micro";

export default function NotificationsCard({ user, showOnlyEmails, updateByNotificationToken, userAccesses }) {
  const [emailNotifications, setEmailNotifications] = useState(
    user.email_notifications
  );
  const [pushNotifications, setPushNotifications] = useState(
    user.push_notifications
  );

  const userAllowedNotifications = Notification.permission === "granted";

  const handleEmailNotificationsChange = (key) => {
    let newEmailNotifications = emailNotifications;
    if (emailNotifications.includes(key)) {
      newEmailNotifications = emailNotifications.filter(
        (item) => item !== key
      );
    } else {
      newEmailNotifications = [...emailNotifications, key];
    }

    if (!updateByNotificationToken) {
      editUser(user.id, { email_notifications: newEmailNotifications }).then(
        (response) => {
          if (response.success === false) {
            setEmailNotifications(user.email_notifications);
            toast.error("Error updating email notifications");
          } else {
            setEmailNotifications(newEmailNotifications);
            toast.success("Email notifications updated successfully");
          }
        }
      );
    } else {
      editNotificationSettings({
        email_notifications: newEmailNotifications,
      }).then((response) => {
        if (response.success === false) {
          setEmailNotifications(user.email_notifications);
          toast.error("Error updating email notifications");
        } else {
          setEmailNotifications(newEmailNotifications);
          toast.success("Email notifications updated successfully");
        }
      });
    }
  };

  const handlePushNotificationsChange = (key) => {
    let newPushNotifications = pushNotifications;
    if (pushNotifications.includes(key)) {
      newPushNotifications = pushNotifications.filter((item) => item !== key);
    } else {
      newPushNotifications = [...pushNotifications, key];
    }

    if (!updateByNotificationToken) {
      editUser(user.id, { push_notifications: newPushNotifications }).then(
        (response) => {
          if (response.success === false) {
            setPushNotifications(user.push_notifications);
            toast.error("Error updating push notifications");
          } else {
            setPushNotifications(newPushNotifications);
            toast.success("Push notifications updated successfully");
          }
        }
      );
    } else {
      editNotificationSettings({
        push_notifications: newPushNotifications,
      }).then((response) => {
        if (response.success === false) {
          setPushNotifications(user.push_notifications);
          toast.error("Error updating push notifications");
        } else {
          setPushNotifications(newPushNotifications);
          toast.success("Push notifications updated successfully");
        }
      });
    }
  };

  return (
    <Card id="notifications" className="card-settings mt-3" style={showOnlyEmails ? { maxWidth: 500 } : {}}>
      <Card.Header>
        <Card.Title className="mb-0">Notifications</Card.Title>
      </Card.Header>
      <Card.Body className="">
        <Table className="table-pricing" responsive>
          <thead>
            <tr>
              <th className="w-32"></th>
              <th className="w-17 p-0">
                <span className="mb-0">Notify me via</span>
                <div className="pricing-price">Email</div>
              </th>
              {!showOnlyEmails && (
                <th className='w-17 p-0'>
                  <span className={`mb-0 ${!userAllowedNotifications ? "op-50" : ''}`}>Notify me via</span>
                  <div className={`pricing-price ${!userAllowedNotifications ? "op-50" : ''}`}>Push</div>
                  {
                    !userAllowedNotifications ?
                      <div style={{ color: "red", fontWeight: "700" }}>
                        Enable Push Notifications In Your Browser*
                      </div>
                      :
                      null}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr className="title">
              <td>General</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            {
              user && user.role === "CUSTOMER" && (
                <tr>
                  <td>
                    <p className="mb-1">Updates from Publishers</p>
                    <small>
                      Occasional messages from publishers with updates or offers
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("publisher_updates")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("publisher_updates")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              )}
            <tr>
              <td>
                <p className="mb-1">Important System Alerts</p>
                <small>
                  We'll always notify you of important system or campaign
                  changes
                </small>
              </td>
              <td>
                <div className="inline-block">
                  <div className="inline-block">
                    <Form.Check
                      type="switch"
                      className="inline-block"
                      disabled
                      checked={
                        emailNotifications &&
                        emailNotifications.includes("important_system_alerts")
                      }
                      onChange={() =>
                        handleEmailNotificationsChange(
                          "important_system_alerts"
                        )
                      }
                    />
                  </div>
                </div>
              </td>
              {!showOnlyEmails && (
                <td>
                  <div className="inline-block">
                    <div className="inline-block">
                      <Form.Check
                        type="switch"
                        className="inline-block"
                        disabled
                        checked={
                          pushNotifications &&
                          pushNotifications.includes("important_system_alerts")
                        }
                        onChange={() =>
                          handlePushNotificationsChange(
                            "important_system_alerts"
                          )
                        }
                      />
                    </div>
                  </div>
                </td>
              )}
            </tr>
            {
              user && user.role !== "CUSTOMER" && userAccesses && userAccesses.includes("inventory_access") && (
                <tr className="title">
                  <td>Inventory</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              )
            }
            {
              user && user.role !== "CUSTOMER" && userAccesses && userAccesses.includes("inventory_access") && (
                <tr>
                  <td>
                    <p className="mb-1">Queue Updates</p>
                    <small>
                      Get notified when queue item position is updated
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("queue_updates")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("queue_updates")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            disabled={!userAllowedNotifications}
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("queue_updates")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("queue_updates")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )
            }
            {
              user && user.role !== "CUSTOMER" && userAccesses && userAccesses.includes("inventory_access") && (
                <tr>
                  <td>
                    <p className="mb-1">Inventory Updates</p>
                    <small>
                      Get notified when inventory is updated
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("inventory_updates")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("inventory_updates")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            disabled={!userAllowedNotifications}
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("inventory_updates")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("inventory_updates")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )
            }
            {
              user && user.role !== "CUSTOMER" && userAccesses && userAccesses.includes("customers_access") && (
                <tr className="title">
                  <td>Customers</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              )
            }
            {
              user && user.role !== "CUSTOMER" && userAccesses && userAccesses.includes("customers_access") && (
                <tr>
                  <td>
                    <p className="mb-1">Account Updates</p>
                    <small>
                      Get notified when new accounts are added
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("account_updates")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("account_updates")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            disabled={!userAllowedNotifications}
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("account_updates")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("account_updates")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )
            }
            {
              user && user.role !== "CUSTOMER" && userAccesses && userAccesses.includes("customers_access") && (
                <tr>
                  <td>
                    <p className="mb-1">User Updates</p>
                    <small>
                      Get notified when new users are added
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("users_updates")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("users_updates")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            disabled={!userAllowedNotifications}
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("users_updates")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("users_updates")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )
            }
            {
              user && user.role !== "CUSTOMER" && userAccesses && userAccesses.includes("customers_access") && (
                <tr>
                  <td>
                    <p className="mb-1">Campaign Updates</p>
                    <small>
                      Get notified when new campaigns are updated
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("campaign_updates")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("campaign_updates")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            disabled={!userAllowedNotifications}
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("campaign_updates")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("campaign_updates")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )
            }
            {
              user && user.role === "CUSTOMER" && (
                <tr className="title">
                  <td>Transactional</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              )}
            {
              user && user.role === "CUSTOMER" && (
                <tr>
                  <td>
                    <p className="mb-1">Invoice & Transaction Receipts</p>
                    <small>
                      We'll always notify you of charges or new invoices
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          disabled
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("invoice_receipts")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("invoice_receipts")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            disabled
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("invoice_receipts")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("invoice_receipts")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )}
            {
              user && user.role === "CUSTOMER" && (
                <tr className="title">
                  <td>PPC Auctions</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              )}
            {
              user && user.role === "CUSTOMER" && (
                <tr>
                  <td>
                    <p className="mb-1">Outbid Notifications</p>
                    <small>
                      We'll send you a daily roundup email if there were position
                      drops
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("outbid_notifications")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("outbid_notifications")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("outbid_notifications")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("outbid_notifications")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )}
            {
              user && user.role === "CUSTOMER" && (
                <tr>
                  <td>
                    <p className="mb-1">Bid Approved Notifications</p>
                    <small>
                      We'll send you a notification when your bid is approved
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("bid_approved")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("bid_approved")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("bid_approved")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("bid_approved")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )}
            {
              user && user.role === "CUSTOMER" && (
                <tr>
                  <td>
                    <p className="mb-1">Budget Warnings</p>
                    <small>
                      We'll send you a heads-up when you reach 75% of your budget
                      and are likely to run out
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("budget_warnings")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("budget_warnings")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("budget_warnings")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("budget_warnings")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )}
            {
              user && user.role === "CUSTOMER" && (
                <tr>
                  <td>
                    <p className="mb-1">Budget Exhaution</p>
                    <small>
                      We'll notify you when your budget runs out and your campaign
                      pauses
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes("budget_exhaustion")
                          }
                          onChange={() =>
                            handleEmailNotificationsChange("budget_exhaustion")
                          }
                        />
                      </div>
                    </div>
                  </td>
                  {!showOnlyEmails && (
                    <td>
                      <div className="inline-block">
                        <div className="inline-block">
                          <Form.Check
                            type="switch"
                            className="inline-block"
                            checked={
                              pushNotifications &&
                              pushNotifications.includes("budget_exhaustion")
                            }
                            onChange={() =>
                              handlePushNotificationsChange("budget_exhaustion")
                            }
                          />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              )}
            {
              user && user.role === "CUSTOMER" && (
                <tr>
                  <td>
                    <p className="mb-1">Monthly Performance Roundups</p>
                    <small>
                      Send me a monthly overview of my campaign performance
                    </small>
                  </td>
                  <td>
                    <div className="inline-block">
                      <div className="inline-block">
                        <Form.Check
                          type="switch"
                          className="inline-block"
                          checked={
                            emailNotifications &&
                            emailNotifications.includes(
                              "monthly_performance_roundups"
                            )
                          }
                          onChange={() =>
                            handleEmailNotificationsChange(
                              "monthly_performance_roundups"
                            )
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}