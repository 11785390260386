import React, { forwardRef } from 'react';

const CustomContextMenu = forwardRef(({ x, y, options, onSelect }, ref) => {
    return (
        <div
            ref={ref}
            className='custom-context-menu-container'
            style={{
                position: 'absolute',
                top: y,
                left: x,
            }}
        >
            <ul>
                {options.map((option, index) => (
                    <React.Fragment key={index}>
                        <li onClick={() => onSelect(option)}>
                            {option.label}
                        </li>
                        {index === 0 && <hr className="context-menu-divider" />}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
});

export default CustomContextMenu;
