import mixpanel from 'mixpanel-browser';

const token = process.env.REACT_APP_MIXPANEL_TOKEN;

mixpanel.init(token, {
    debug: false,
    track_pageview: false,
    persistence: 'localStorage'
});

export const mixpanelInstance = mixpanel;
