export const compareStrNumbers = (a, b) => {
    if (a && b) {
        if (a === "-") return -1;
        if (b === "-") return 1;

        if (
            typeof a === "string" &&
            a.includes("$")
        ) {
            a = a.replace(/[$,]/g, '');
        }

        if (
            typeof b === "string" &&
            b.includes("$")
        ) {
            b = b.replace(/[$,]/g, '');
        }

        if (
            typeof a === "string" &&
            a.includes(",")
        ) {
            a = a.replace(/[$,]/g, '');
        }

        if (
            typeof b === "string" &&
            b.includes(",")
        ) {
            b = b.replace(/[$,]/g, '');
        }
        
        if (
            typeof a === "string" &&
            a.includes("<")
        ) {
            a = a.replace(/<[^>]*>?/gm, '');
        }

        if (
            typeof b === "string" &&
            b.includes("<")
        ) {
            b = b.replace(/<[^>]*>?/gm, '');
        }

        if (
            typeof a === "string" &&
            a.includes("#")
        ) {
            a = a.replace(/#/g, '');
        }

        if (
            typeof b === "string" &&
            b.includes("#")
        ) {
            b = b.replace(/#/g, '');
        }

        const aBudget = parseFloat(a);
        const bBudget = parseFloat(b);

        if (aBudget > bBudget) return 1;
        if (aBudget < bBudget) return -1;

        return 0;
    }
}