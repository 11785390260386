import { logosMap } from '../maps';
import defaultLogo from './default';
import lightLogo from '../../assets/svg/revbox-logo.svg';
import darkLogo from '../../assets/svg/revbox-logo-white.svg';

export function logosModule(publisher_id, theme, custom_style) {
    try {
        const isPublisherIdPresent = Object.keys(logosMap).includes(String(publisher_id))

        if (isPublisherIdPresent) {
          return logosMap[publisher_id]()
        } else {
          return (
            theme === "dark" ? 
              custom_style ?
                <img src={darkLogo} style={custom_style}/> :
                <img src={darkLogo} className='logoDarkClass'/>
              : 
              custom_style ? 
                <img src={lightLogo} style={custom_style}/> :
                <img src={lightLogo} className='logoLightClass'/>
          )
        }

    } catch (error) {
        console.error('Error loading logo module:', error);
        return defaultLogo(theme);
    }
}
