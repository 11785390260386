import { abbreviationsMap } from "../modules/maps";

export function GetPubAbbr() {
    const urlParams = new URLSearchParams(window.location.search);
    const pubParam = urlParams.get('pub');
    const publisher_id = localStorage.getItem('publisher_id');
    
    if (
        pubParam !== null &&
        pubParam !== undefined &&
        pubParam !== '' &&
        pubParam !== 'undefined' &&
        pubParam !== 'null'
    ) {
        return abbreviationsMap[pubParam];
    }
    
    return publisher_id;
}