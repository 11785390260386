import React, { useEffect, useState, useLayoutEffect } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Col, Nav, OverlayTrigger, Form, Row, Tooltip } from "react-bootstrap";
import { editCampaign, getCampaign } from "../../services/adv";
import { toast } from "react-toastify";
import { loadImages } from "../../services/micro";
import { mixpanelInstance } from "../../mixpanel/instance";

export default function AdvertiserProduct() {
  const urlParams = new URLSearchParams(window.location.search);
  const campaignId = urlParams.get("campaignId");
  const [campaign, setCampaign] = useState({});

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const trackInputChange = (message, e)=>{
    mixpanelInstance.track(message, {
      name: e.target.value,
      campaignId,
    });
  }

  const trackFilesChange = (message, files)=>{
    mixpanelInstance.track(message, {
      files,
      campaignId,
    });
  }

  useLayoutEffect(() => {
    getCampaign(campaignId).then(res => {
      setCampaign(res.data);
    });
  }, []);

  const onNameChange = (e) => {
    setCampaign({...campaign, name: e.target.value});

   trackInputChange(`Change name for campaign ${campaign.name}`, e)
  }

  const onPpcDefaultUrlChange = (e) => {
    setCampaign({...campaign, ppc_default_url: e.target.value});

    trackInputChange(`Change ppc default url for campaign ${campaign.name}`, e)
  }

  const onScreenshotsChange = async (e) => {
    if (e.target.files.length <= 4) {
      let screenshotFilesIds = null;
      const screenshotsResponse = await loadImages(e.target.files) 

      if (screenshotsResponse?.success) {
        screenshotFilesIds = screenshotsResponse.data.map(img => Number(img.id))
      }

      setCampaign({...campaign, default_screenshot_files: screenshotFilesIds});

      trackFilesChange(`Change screenshot files for campaign ${campaign.name}`, screenshotFilesIds)
    } else {
      e.target.value = null
      toast.error("You can upload up to 4 files!");
    }
  }

  const onLogoChange = async (e) => {
    const logoResponse = await loadImages(e.target.files)
    let logoId;

    if (logoResponse?.success) {
      logoId =  Number(logoResponse.data[0].id);
    }

    setCampaign({...campaign, logo_file: logoId});
    trackFilesChange(`Change logo file for campaign ${campaign.name}`, logoId)
  }

  // const switchSkin = (skin) => {
  //   if (skin === 'dark') {
  //     const btnWhite = document.getElementsByClassName('btn-white');

  //     for (const btn of btnWhite) {
  //       btn.classList.add('btn-outline-primary');
  //       btn.classList.remove('btn-white');
  //     }
  //   } else {
  //     const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

  //     for (const btn of btnOutlinePrimary) {
  //       btn.classList.remove('btn-outline-primary');
  //       btn.classList.add('btn-white');
  //     }
  //   }
  // };

  const trackSaveChanges = (message)=>{
    mixpanelInstance.track(message, {
      name: campaign.name,
      ppc_default_url: campaign.ppc_default_url,
      logo_file: campaign.logo_file,
      default_screenshot_files: campaign.default_screenshot_files
    })
  }
  
  const handleSaveChanges = () => {
    editCampaign(campaignId, {
      name: campaign.name,
      ppc_default_url: campaign.ppc_default_url,
      logo_file: campaign.logo_file,
      default_screenshot_files: campaign.default_screenshot_files
    }).then(res => {
      toast.success('Changes saved successfully');

      trackSaveChanges(`Save changes for campaign ${campaign.id}`)
    }).catch(err => {
      toast.error('Error saving changes');

      trackSaveChanges(`Error saving changes for campaign ${campaign.id}`)
    });
  }

  // switchSkin(skin);

  // useEffect(() => {
  //   switchSkin(skin);
  // }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">ADV Home</Link></li>
              <li className="breadcrumb-item">{campaign.name}</li>
              <li className="breadcrumb-item active" aria-current="page">Product Details</li>
            </ol>
            <h2 className="main-title mb-0">Product Details</h2>
          </div>
        </div>

        <Row className="g-3 justify-content-center mt-3">
  
          <Col md="12">
            <Card className="card-settings">
              <Card.Header>
                <Card.Title>Product Information</Card.Title>
                <Card.Text>This information is used globally for your campaign.</Card.Text>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Product Name</h6>
                      <p>Required</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Enter name" value={campaign?.name} onChange={onNameChange} />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2">
                    <Col md="2">
                      <h6>Main Landing Page</h6>
                      <p>Required</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="http(s)://" value={campaign?.ppc_default_url} onChange={onPpcDefaultUrlChange} />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Product Logo</h6>
                      <p>Required<br/>150x150px, JPG/PNG</p>
                    </Col>
                    <Col md>
                      <Form.Control
                        type="file"
                        placeholder="Upload Logo"
                        onChange={(e) =>{onLogoChange(e)}}
                        accept=".png,.jpg,.jpeg"
                       />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Screenshots</h6>
                      <p>1 min, add up to 4<br/>1600x900px, JPG/PNG</p>
                    </Col>
                    <Col md>
                      <Form.Control
                        type="file"
                        multiple
                        placeholder="Upload Screenshots"
                        onChange={(e) =>{onScreenshotsChange(e)}}
                        accept=".png,.jpg,.jpeg"
                       />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Billing</h6>
                      <p>Required</p>
                    </Col>
                    <Col md>
                      <Form.Check type="checkbox" checked label="Monthly Net 30 - you will be billed at the end of each billing period and are required to pay via bank transfer within 30 days." className="mt-3" />
                      <Form.Check type="checkbox" label="Credit Card [option coming soon]" disabled="disabled" className="mt-1" />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item d-flex justify-content-end">
                  <Button variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}