import React from 'react';
import lightLogo from '../../../assets/svg/revbox-logo.svg';
import darkLogo from '../../../assets/svg/revbox-logo-white.svg';

export default function defaultLogo() {
    return( <>
        <img src={darkLogo} className='logoDarkClass'/> 
        <img src={lightLogo} className='logoClass'/>
    </>)
}
