export const addPubParamToUrl = (url, addPub = false) => {
  const abbreviation = localStorage.getItem("abbreviation");
  if (!abbreviation || !addPub) return url;

  const urlObj = new URL(url, window.location.origin);
  const searchParams = new URLSearchParams(urlObj.search);

  if (!searchParams.has('pub')) {
      searchParams.append('pub', addPub);
      urlObj.search = searchParams.toString();
  }

  return urlObj.pathname + urlObj.search;
};
