import React, { useEffect, useState } from "react";
import { getAllCategories } from "../../services/pub";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import TablePagePlaceholder from "../../components/TablePagePlaceholder";
import { getToken } from "../../helpers/get-token";
import { useNavigate } from "react-router-dom";

export default function PublisherCategories() {
  const [data, setData] = useState([]);
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/publisher-categories/all?token=${token}`;

  const navigate = useNavigate();
  
  useEffect(() => {
    getAllCategories().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("You don't have permission to access this page.");
    });
  }, []);

  // TODO: add 'table-responsive' class for "gridjs-wrapper"
  return (
    <React.Fragment>
      { data?.table ?
        <DataTable 
          title="Categories"
          data={data}
          endpoint={endpoint}
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/categories"
          new_btn_title="New Category"
          new_btn_redirect="/pub/category/new"
          edit_redirect="/pub/category/edit"
          redirect="/pub/categories"
        />
        :
        <TablePagePlaceholder
          title="Categories"
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/categories"
          new_btn_title="New Category"
          new_btn_redirect="/pub/category/new"
          edit_redirect="/pub/category/edit"
          redirect="/pub/categories"
        />
      }
    </React.Fragment>
  )
}