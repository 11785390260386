import React, { useEffect, useState } from "react";
import { getPeriodAdvertiserPerformance } from "../../../services/micro";
import { Card, Col, Nav, Placeholder } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SplittedTable from "../../../components/SplittedTable";
import { formatThousand } from "../../../helpers/thousand-number-format";
import { html } from "gridjs";
import { compareStrNumbers } from "../../../helpers/compare-str-numbers";

export default function AdvertiserPerformance(props) {
  const { currentPeriod, previousPeriod, skin } = props;
  const [response, setResponse] = useState();

  const navigate = useNavigate();

  const openCampaign = (id) => {
    navigate("/adv/campaign/analytics?campaignId=" + id);
  };

  const handleOnClick = (args) => {
    const values = args[1]._cells.map((cell, index) => {
      return {
        value: cell.data,
        column: response.columns[index],
      };
    });

    const id = values[0].value;
    openCampaign(id);
  };

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (currentPeriod[0] && currentPeriod[1] && previousPeriod[0] && previousPeriod[1]) {
        getPeriodAdvertiserPerformance(currentPeriod, previousPeriod).then(
          (res) => {
            setResponse(res);
          }
        );
      }
    }
  }, [props]);

  if (response) {
    const currentColumns = response.columns.slice(0, 5);
    const periodColumns = response.columns.slice(5);

    console.log(response);

    const customizedColumns = [
      {
        name: "Status",
        formatter: (value) => {
          if (value === "Paused") {
            return html(
              `<span class="badge bg-warning ms-2">
                Paused
              </span>`
            );
          }

          if (value === "Inactive") {
            return html(
              `<span class="badge bg-danger ms-2">
                Inactive
              </span>`
            );
          }

          return html(
            `<span class="badge bg-success ms-2">
              Active
            </span>`
          );
        },
      },
      {
        name: "Budget",
        sort: {
          compare: (a, b) => {
            if (a && b) {
              const aSpend = Number(a.split("/")[0]);
              const aBudget = Number(a.split("/")[1]);
              const bSpend = Number(b.split("/")[0]);
              const bBudget = Number(b.split("/")[1]);
              const aPercentage = (aSpend / aBudget) * 100;
              const bPercentage = (bSpend / bBudget) * 100;

              return aPercentage - bPercentage;
            }
          }
        },
        formatter: (value) => {
          const ppc_monthly_spend = Number(value.split("/")[0]);
          const ppc_monthly_budget = Number(value.split("/")[1]);
          const spendPercentage = (ppc_monthly_spend / ppc_monthly_budget) * 100;

          return html(
            `<div class="revenue-item">
              <div class="progress mt-1" style="height: '6px'">
                ${
                  spendPercentage >= 100 ? 
                    `<div role="progressbar" class="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: ${spendPercentage + "%"}"></div>` 
                  : spendPercentage > 75 ? 
                    `<div role="progressbar" class="progress-bar bg-warning" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: ${spendPercentage + "%"}"></div>`
                  : `<div role="progressbar" class="progress-bar bg-success" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: ${spendPercentage + "%"}"></div>`
                }
              </div>
              <div class="revenue-item-body mb-0 mt-1">
                <span class="d-block fs-11 ff-secondary">$${formatThousand(ppc_monthly_spend)} / $${formatThousand(ppc_monthly_budget)}</span>
                <span class="d-block fs-11 ff-secondary" style="color: ${
                  spendPercentage >= 100 ? "var(--bs-danger)" : spendPercentage > 75 ? "var(--bs-warning)" : "var(--bs-success)"
                }">${spendPercentage.toFixed(0)}%</span>
              </div>
            </div>`
          );
        },
      },
      {
        name: "Impressions",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Free Clicks",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Paid Clicks",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Average Pos",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Average CPC",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Top CPC",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      },
      {
        name: "Total Spend",
        sort: {
          compare: (a, b) => compareStrNumbers(a, b)
        },
      }
    ];

    return (
      <React.Fragment>
        <Col xs="12">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Campaign Performance</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
            </Card.Header>
            <Card.Body className="p-3 p-xl-3">
              <SplittedTable
                rows={response.rows}
                currentColumns={currentColumns}
                periodColumns={periodColumns}
                onRowClick={handleOnClick}
                customizedColumns={customizedColumns}
                tableName="AdvertiserPerformance"
              />
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{color: "var(--bs-body-color)"}}>
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
