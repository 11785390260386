import React, { useEffect, useState } from "react";
import { getPeriodInventoryInsightsRevenue } from "../../../../services/micro";
import { Card, Placeholder, Col } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

function getCSSVariableValue(variableName) {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
}

export default function InventoryInsightsRevenue(props) {
  const { currentPeriod, previousPeriod, inventoryId } = props;
  const [response, setResponse] = useState();
  const [isOneDayPicked, setIsOneDayPicked] = useState(false);
  const [colors, setColors] = useState({
    primary: "",
    chartSecondary: "",
    columnChart: "",
  });


  useEffect(() => {
    const primary = getCSSVariableValue('--chart-color-3');
    const chartSecondary = getCSSVariableValue('--chart-color-2');
    const columnChart = getCSSVariableValue('--chart-color-1');
    setColors({ primary, chartSecondary, columnChart });
  }, []);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (
        currentPeriod[0] &&
        currentPeriod[1] &&
        previousPeriod[0] &&
        previousPeriod[1]
      ) {
        getPeriodInventoryInsightsRevenue(
          currentPeriod,
          previousPeriod,
          inventoryId
        ).then((response) => {
          setResponse(response);
        });
      }
    }
  }, [props]);

  useEffect(() => {
    const currentDate = new Date(response?.periods[0].data[0][0]);
    const previousDate = new Date(response?.periods[1].data[0][0]);

    if ((currentDate - previousDate) / (1000 * 60 * 60 * 24) === 0) {
      setIsOneDayPicked(true);
    } else {
      setIsOneDayPicked(false);
    }
  }, [response])

  const optionFour = {
    chart: {
      parentHeightOffset: 0,
      stacked: false,
      toolbar: { show: false },
      animations: {
        enabled: true,
      },
      type: "line",
      zoom: {
        enabled: false,
      }
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: 100,
        bottom: 0,
        left: 5,
      },
    },
    colors: [colors.columnChart, colors.chartSecondary, colors.primary],
    plotOptions: {
      bar: { columnWidth: "50%" },
    },
    stroke: {
      curve: ["smooth", "smooth", "smooth"],
      width: [1.5, 1.5, 2],
    },
    fill: {
      type: ["solid", "gradient", "solid"],
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.35,
        opacityTo: 0.2,
        stops: [0, 100],
        gradientToColors: ["#f3f5fc"],
      },
    },
    xaxis: {
      type: "datetime",
      axisBorder: { show: false },
      tickAmount: isOneDayPicked ? 1 : 15, // Use the condition here
      labels: {
        hideOverlappingLabels: false,
        style: {
          colors: "#6e7985",
          fontSize: "9px",
        },
        rotate: -45,
        rotateAlways: true,
        formatter: function (value, timestamp) {
          const date = new Date(timestamp);
          return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric"
          });
        },
      },
      min: isOneDayPicked ? new Date(response.periods[0].data[0][0]).getTime() : undefined,
      max: isOneDayPicked ? new Date(response.periods[0].data[0][0]).getTime() : undefined,
    },
    yaxis: [
      {
        seriesName: "Clicks",
        opposite: true,
        tickAmount: 8,
        labels: {
          show: false,
          style: {
            colors: [colors.primary],
            fontSize: "11px",
            fontWeight: "bold",
          },
          formatter: function (val) {
            return val + " clicks";
          },
        },
      },
      {
        seriesName: "Views",
        opposite: false,
        tickAmount: 8,
        labels: {
          show: false,
          style: {
            colors: ["rgba(204, 204, 204, 0.5)"],
            fontSize: "11px",
          },
        },
      },
      {
        seriesName: "Campaign Spend",
        opposite: false,
        tickAmount: 8,
        labels: {
          show: false,
          style: {
            colors: [colors.columnChart],
            fontSize: "11px",
            fontWeight: "bold",
          },
          formatter: function (val) {
            return "$" + val + ".00";
          },
        },
      },
    ],
    dataLabels: { enabled: false },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (value, { seriesIndex }) {
          if (seriesIndex === 2) {
            return "$" + value.toFixed(0) + ".00";
          }
          return value.toFixed(0);
        },
      },
    },
    legend: { show: true },
  };



  if (response) {
    return (
      <React.Fragment>
        <Col md="6" xl="6">
          <Card className="card-one overflow-hidden">
            <Card.Body className="px-0 pb-3">
              <div className="total-sales-body">
                <h2 className="card-value mb-2 ls--1">
                  <span>$</span>
                  {response.total_revenue_for_current_period}
                </h2>
                {response.diff_sign === "down" ? (
                  <small>
                    <span className="d-inline-flex text-danger">
                      {response.diff_in_percentage}{" "}
                      <i className="ri-arrow-down-line"></i>
                    </span>
                    than last period
                  </small>
                ) : (
                  <small>
                    <span className="d-inline-flex text-success">
                      {response.diff_in_percentage}{" "}
                      <i className="ri-arrow-up-line"></i>
                    </span>
                    than last period
                  </small>
                )}
              </div>

              <ReactApexChart
                series={response.periods}
                options={optionFour}
                type="bar"
                height={340}
              />
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Col md="6" xl="6">
          <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
            <Placeholder style={{ height: 380, width: "100%" }} />
          </Placeholder>
        </Col>
      </React.Fragment>
    );
  }
}
