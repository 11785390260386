import React, { useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
  Placeholder,
} from "react-bootstrap";

export default function TablePagePlaceholder({
  title,
  redirect,
  breadcrumb,
  breadcrumb_redirect,
  new_btn_title,
  new_btn_redirect,
  edit_redirect,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="/pub/dashboard/revenue" to="/pub/dashboard/revenue">
                  PUB Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                { breadcrumb_redirect && breadcrumb ?
                  <Link href={breadcrumb_redirect} to={breadcrumb_redirect}>
                    {breadcrumb}
                  </Link>
                  :
                  breadcrumb
                }
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {title}
              </li>
            </ol>
            <h2 className="main-title mb-0">{title}</h2>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>{new_btn_title}</Tooltip>}>
              <Nav.Link href={new_btn_redirect}>
                <i className="ri-add-line"></i>
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <div style={{ color: "var(--bs-body-color)" }}>
          <Col className="mt-1">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder style={{ height: 400, width: "100%" }} />
            </Placeholder>
          </Col>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
