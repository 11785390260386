import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import AdvertiserTotals from "./analytics/advertiser/AdvertiserTotals";
import AdvertiserPerformance from "./analytics/advertiser/AdvertiserPerformance";
import DatePicker from "./components/DatePicker";

const Ranges = [
  {
    label: 'today',
    value: [startOfDay(new Date()), endOfDay(new Date())]
  },
  {
    label: 'yesterday',
    value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
  },
  {
    label: 'Last 30 Days',
    value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]
  }
];

export default function PublisherCustomerMonitoring() {
  // get 30 days back for begin date
  const [currentPeriod, setCurrentPeriod] = useState([null, null]);
  // get 60 days back for begin date
  const [previousPeriod, setPreviousPeriod] = useState([null, null]);

  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);

  const loadDateRange = () => {
    const storedCurrentPeriod = localStorage.getItem('currentPeriod');
    const storedPreviousPeriod = localStorage.getItem('previousPeriod');

    if (storedCurrentPeriod && storedPreviousPeriod) {
      return {
        currentPeriod: JSON.parse(storedCurrentPeriod).map(date => new Date(date)),
        previousPeriod: JSON.parse(storedPreviousPeriod).map(date => new Date(date)),
      };
    }

    const defaultCurrentPeriod = [subDays(new Date(), 30), new Date()];
    const defaultPreviousPeriod = [subDays(new Date(), 60), subDays(new Date(), 30)];
    return {
      currentPeriod: defaultCurrentPeriod,
      previousPeriod: defaultPreviousPeriod,
    };
  };


  useEffect(() => {
    const { currentPeriod, previousPeriod } = loadDateRange();
    setCurrentPeriod(currentPeriod);
    setPreviousPeriod(previousPeriod);
  }, []);

  useEffect(() => {
    if (currentPeriod && currentPeriod.length === 2 && currentPeriod[0] && currentPeriod[1]) {
      localStorage.setItem('currentPeriod', JSON.stringify(currentPeriod));
      localStorage.setItem('previousPeriod', JSON.stringify(previousPeriod));
    }
  }, [currentPeriod, previousPeriod]);

  const formatDate = (date) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-UK', options).format(date);
  };

  const handleOpenDateRangePicker = () => {
    setIsDateRangePickerOpen(true);
  };

  const handleDateRange = (e) => {
    const curBegin = e[0];
    const curEnd = e[1];

    const diff = curEnd - curBegin;

    const prevBegin = new Date(curBegin - diff);
    const prevEnd = new Date(curEnd - diff);

    setPreviousPeriod([prevBegin, prevEnd]);
    setCurrentPeriod([curBegin, curEnd]);
  };

  // Changing necessary options between dark and light skin
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">PUB Home</Link></li>
              <li className="breadcrumb-item">Dashboard</li>
              <li className="breadcrumb-item active" aria-current="page">Advertiser</li>
            </ol>
            <h2 className="main-title mb-0">Advertiser Dashboard</h2>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="btn-outline-primary"
              className={`d-flex align-items-center gap-2 btn btn-outline-primary`}
              onClick={handleOpenDateRangePicker}
            >
              <span className="fw-semibold">Date Range</span>
              <i className="ri-arrow-right-s-line"></i>
              {currentPeriod && currentPeriod.length === 2 ? (
                ` ${formatDate(currentPeriod[0])} - ${formatDate(currentPeriod[1])}`
              ) : (
                ''
              )}
            </Button>

            {isDateRangePickerOpen &&
              <DatePicker
                isDateRangePickerOpen={isDateRangePickerOpen}
                setIsDateRangePickerOpen={setIsDateRangePickerOpen}
                ranges={Ranges} onChange={handleDateRange}
                currentPeriod={currentPeriod}
              />
            }
          </div>
        </div>

        <Row className="g-3">
          <AdvertiserTotals currentPeriod={currentPeriod} previousPeriod={previousPeriod} />
          <AdvertiserPerformance currentPeriod={currentPeriod} previousPeriod={previousPeriod} />
          {/* <AdvertiserMap currentPeriod={currentPeriod} previousPeriod={previousPeriod} /> */}
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}