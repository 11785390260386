import React, { useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import { Grid } from "gridjs-react";
import { html } from "gridjs";
import { toast } from "react-toastify";
import { placeholderLogoModule } from "../modules/placeholderLogo";
import { GetPubAbbr } from "../helpers/get-pub-abbr";

const STATUS_ACTIVE = "ACTIVE";
const STATUS_INACTIVE = "INACTIVE";
const STATUS_SHOULD_BE_LOADED = "SHOULD_BE_LOADED";

export default function DataTable({
  title,
  data,
  is_frame,
  endpoint,
  redirect,
  handle_click,
  breadcrumb,
  breadcrumb_redirect,
  new_btn_title,
  new_btn_redirect,
  edit_redirect,
  on_search,
  on_sort,
  on_pagination,
  customized_columns = []
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const publisherIdByAbbr = GetPubAbbr()

  const [columns, setColumns] = useState([]);
  const [status, setStatus] = useState(3);
  const [skin, setSkin] = useState(currentSkin);
  const tableName = data.table.db_name

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    if (data.success) {
      if (data.table) {
        let newColumnsData = []

        if (data.table.table_columns) {
          newColumnsData = data.table.table_columns.map((column_name) => {
            const ex_column = data.table.columns.find((c) => c.name === column_name);
            return ex_column;
          });
        } else {
          newColumnsData = data.table.columns.filter(
            (column) =>
              (column.status === STATUS_INACTIVE && column.name === "id") ||
              column.status === STATUS_ACTIVE ||
              column.status === STATUS_SHOULD_BE_LOADED
          );
        }

        setColumns(
          newColumnsData.map((column, index_of_column) => {
            if (column.name.includes("logo")) {
              return {
                name: column.title,
                formatter: (_, row) => {
                  if (row?.cells[index_of_column]?.data !== "Unknown") {
                    return html(
                      `<img src="${row.cells[index_of_column].data}" alt="${column.title}" style="max-width: 50px; max-height: 50px;" />`
                    )
                  } else {
                    return html(
                      `
                          <img src="${placeholderLogoModule(publisherIdByAbbr, currentSkin).props.src}" alt="${column.title}" style="max-width: 30px; max-height: 30px; border-radius: 5px;" />
                        `
                    )
                  }
                }
              };
            }

            return { name: column.title, label: column.name };
          })
        );
      } else if (data.table && data.data.length === 0) {
        setColumns(data.table.columns.map((column) => column.title));
      }
    } else {
      toast.error(data.message || "Something went wrong!");
    }
  }, []);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const gridRef = useRef();

  useEffect(() => {
    const gridjsInstance = gridRef.current.getInstance();
    gridjsInstance.on("rowClick", (event, row) => {
      const values = row._cells.map((cell, index) => {
        return {
          value: cell.data,
          column: columns[index],
        };
      });

      if (handle_click) {
        handle_click(values.map((value) => value.value)[0], event);
        return;
      } else {
        const url = edit_redirect + "?id=" + values.map((value) => value.value)[0];

        if (event.metaKey || event.ctrlKey) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      }
    });
  }, [gridRef, columns]);

  const statusMap = {
    1: data.liveRecords,
    2: data.rejectedRecords,
    3: data.reviewRecords,
    4: data.draftRecords,
  }

  const formatQueueColumns = (columns) => {
    const formatedColumns = columns.map(column => {
      const customizedColumn = customized_columns?.find((customizedColumn) => customizedColumn?.name === column.name);

      if (column.name === 'Status') {
        return {
          name: (customizedColumn && customizedColumn.rename) ? customizedColumn.rename : column.name,
          formatter: (_, row) => {
            const status = row.cells[5].data;

            if (status === 'DRAFT') {
              return html(`<span class="badge bg-secondary">Draft</span>`);
            } else if (status === 'REVIEW') {
              return html(`<span class="badge bg-warning">Review</span>`);
            } else if (status === 'REJECTED') {
              return html(`<span class="badge bg-danger">Rejected</span>`);
            } else {
              return html(`<span class="badge bg-success">Live</span>`);
            }
          }
        };
      } else {
        return {
          name: (customizedColumn && customizedColumn.rename) ? customizedColumn.rename : column.name,
          hidden: column.name === "ID",
        }
      }
    });
    return formatedColumns;
  }


  const formatColumns = (columns) => {
    return columns.map((column) => {
      const customizedColumn = customized_columns?.find((customizedColumn) => customizedColumn?.name === column.name);

      if (customizedColumn && customizedColumn.hidden) {
        return {
          name: (customizedColumn && customizedColumn.rename) ? customizedColumn.rename : column.name,
          hidden: true
        };
      }

      return {
        name: (customizedColumn && customizedColumn.rename) ? customizedColumn.rename : column.name,
        hidden: column.name === "ID" || column.name === "Active" || column.name === "PPC Paused",
        formatter: (_, row) => {
          const col = row.cells[columns.indexOf(column)];
          const colValue = col.data;
          const customizedColumn = customized_columns?.find((customizedColumn) => customizedColumn?.name === column.name);
          const formattedValue = (customizedColumn && customizedColumn?.formatter) ? customizedColumn.formatter(colValue, row).props.content : colValue;

          return html(`
              <div class="period-column">
                  ${formattedValue}
              </div>
          `);
        }
      };
    });
  }

  return (
    <React.Fragment>
      {is_frame ? null : (
        <Header onSkin={setSkin} />
      )}
      <div className={is_frame ? "" : "main main-app p-3 p-lg-4"}>
        {is_frame ? null : (
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link href="/pub/dashboard/revenue" to="/pub/dashboard/revenue">
                    PUB Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  {breadcrumb_redirect && breadcrumb ? (
                    <Link href={breadcrumb_redirect} to={breadcrumb_redirect}>
                      {breadcrumb}
                    </Link>
                  ) : breadcrumb}

                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {title}
                </li>
              </ol>
              <h2 className="main-title mb-0">{title}</h2>
            </div>

            <Nav as="nav" className="nav-icon nav-icon-lg">
              <OverlayTrigger overlay={<Tooltip>{new_btn_title}</Tooltip>}>
                <Nav.Link href={new_btn_redirect}>
                  <i className="ri-add-line"></i>
                </Nav.Link>
              </OverlayTrigger>
            </Nav>
          </div>
        )
        }

        <Row className="g-3 justify-content-center">
          {tableName === "inventory_campaigns" ?
            <Nav className="nav-line mb-4 ml-4">
              {<Nav.Link href="#draft" onClick={() => setStatus(4)}>Draft ({data.draftRecords ?? 0})</Nav.Link>}
              {<Nav.Link href="#review" onClick={() => setStatus(3)} className={status === 3 ? "active" : ''}>Review ({data.reviewRecords ?? 0})</Nav.Link>}
              {<Nav.Link href="#live" onClick={() => setStatus(1)}>Live ({data.liveRecords ?? 0})</Nav.Link>}
              {<Nav.Link href="#rejected" onClick={() => setStatus(2)}>Rejected ({data.rejectedRecords ?? 0})</Nav.Link>}
              {<Nav.Link href="#all" onClick={() => setStatus(null)}>All ({data.total})</Nav.Link>}
            </Nav>
            : null
          }

          <Col md="12">
            <Card className="card-one">
              <Card.Body>
                <div className="table-responsive cursor-pointer">
                  <Grid
                    ref={gridRef}
                    columns={tableName === "inventory_campaigns" ? formatQueueColumns(columns) : formatColumns(columns)}
                    search={{
                      server: {
                        url: (prev, keyword) => {
                          return `${prev}&search=${keyword}`
                        }
                      }
                    }}
                    pagination={{
                      enabled: true,
                      limit: 10,
                      server: {
                        url: (prev, page, limit) => `${prev}&limit=${limit}&page=${page + 1}&status=${status}`
                      }
                    }}
                    sort={{
                      multiColumn: false,
                      server: {
                        url: (prev, cols) => {
                          if (!cols.length) return prev;

                          const col = cols[0];

                          const dir = col.direction === 1 ? 'asc' : 'desc';
                          const colName = columns[col.index].label;

                          return `${prev}&column_name=${colName}&column_order=${dir}`;
                        }
                      }
                    }}
                    server={{
                      url: endpoint,
                      then: (res_data) => {
                        if (res_data.success) {
                          if (res_data.data.length) {
                            let newColumnsData = []
                            if (res_data.table.table_columns) {
                              newColumnsData = res_data.table.table_columns.map((column_name) => {
                                const ex_column = res_data.table.columns.find((c) => c.name === column_name);
                                return ex_column;
                              });
                            } else {
                              newColumnsData = res_data.table.columns.filter(
                                (column) =>
                                  (column.status === STATUS_INACTIVE &&
                                    column.name === "id") ||
                                  column.status === STATUS_ACTIVE ||
                                  column.status === STATUS_SHOULD_BE_LOADED
                              );
                            }

                            const newValues = res_data.data.map((row) => {
                              const newRow = [];
                              newColumnsData.forEach((column) => {
                                if (row[column.name]) {
                                  newRow.push(String(row[column.name]));
                                } else {
                                  newRow.push("");
                                }
                              });
                              return newRow;
                            });

                            return newValues
                          } else if (res_data.table && res_data.data.length === 0) {
                            return []
                          }
                        } else {
                          toast.error(data.message || "Something went wrong!");
                        }
                      },
                      total: (res_data) => {
                        if (tableName === "inventory_campaigns") {
                          if (status) {
                            return statusMap[status]
                          } else {
                            return res_data.total;
                          }
                        }
                        return res_data.total;
                      },
                    }}
                    resizable={false}
                    className={{
                      table: "table table-hover mb-0",
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {is_frame ? null : (
          <Footer />
        )}
      </div>
    </React.Fragment>
  );
}
