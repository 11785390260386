import React, { useEffect, useState } from "react";
import { getPublisherInventories } from "../../services/pub";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import TablePagePlaceholder from "../../components/TablePagePlaceholder";
import { getToken } from "../../helpers/get-token";
import { useNavigate } from "react-router-dom";

export default function PublisherInventory() {
  const [data, setData] = useState([]);
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/publisher-inventory/all?token=${token}`;

  const navigate = useNavigate();
  
  useEffect(() => {
    getPublisherInventories().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("You don't have permission to access this page.");
    });
  }, []);

  // /pub/inventory/type
  return (
    <React.Fragment>
      { data?.table ?
        <DataTable 
          title="Inventories"
          data={data}
          endpoint={endpoint}
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/all"
          new_btn_title="New Inventory"
          new_btn_redirect="/pub/inventory/new"
          edit_redirect="/pub/inventory/edit"
          redirect="/pub/inventory/all"
        />
        :
        <TablePagePlaceholder
          title="Inventories"
          breadcrumb="Inventory"
          // breadcrumb="Inventory"
          // breadcrumb_redirect="/pub/inventory/all"
          new_btn_title="New Inventory"
          new_btn_redirect="/pub/inventory/new"
          edit_redirect="/pub/inventory/edit"
          redirect="/pub/inventory/all"
        />
      }
    </React.Fragment>
  )
}