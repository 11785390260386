import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getPeriodInventoryInsightsTotals(currentPeriod, previousPeriod, inventoryId) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/inventory/insights/period/totals', {
            current_period: [new Date(currentPeriod[0]).getTime(), new Date(currentPeriod[1]).getTime()],
            previous_period: [new Date(previousPeriod[0]).getTime(), new Date(previousPeriod[1]).getTime()],
            inventory_id: inventoryId
        })
        return response.data?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodInventoryInsightsTotals;
