import React, { useEffect, useRef, useState } from "react";
import { Grid } from "gridjs-react";
import { h, html } from "gridjs";
import CustomContextMenu from "./CustomContextMenu";
import { LocalStorageKeyNames, useLocalStorageContext } from "../contexts/LocalStorageContext";
import { useNavigate } from "react-router-dom";
import getCampaing from "../services/adv/get_campaign";

export default function SplittedTable({ rows, currentColumns, periodColumns, onRowClick, customizedColumns = [], tableName }) {
    const gridRef = useRef();
    const [contextMenu, setContextMenu] = useState(null);
    const contextMenuRef = useRef(null);
    const { updateLocalStorageProperty } = useLocalStorageContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (rows.length > 0) {
            const gridjsInstance = gridRef.current.getInstance();

            const handleRowClick = (event, row) => {
                const values = row._cells.map((cell, index) => ({
                    value: cell.data,
                    column: currentColumns.concat(periodColumns)[index],
                }));

                const id = values[0].value;

                if (event.metaKey || event.ctrlKey) {
                    window.open("/adv/campaign/analytics?campaignId=" + id, "_blank");
                } else {
                    onRowClick([event, row]);
                }
            };

            gridjsInstance.on("rowClick", handleRowClick);

            if (tableName === 'AdvertiserPerformance') {
                const handleContextMenu = (event) => {
                    event.preventDefault();

                    const clickedRowElement = event.target.closest(".gridjs-tr");

                    if (clickedRowElement) {
                        const clickedRowName = clickedRowElement.querySelector('.first-current-column').textContent.trim();
                        const clickedRowInfo = rows.find((row) => row[1] === clickedRowName);

                        setContextMenu({
                            x: event.layerX,
                            y: event.layerY,
                            options: [
                                { label: "Open In New Tab", action: () => openCampaignEdit(clickedRowInfo[0]) },
                                { label: "Open Advertiser Auctions", action: async () => await openAdvertiserAuctions(clickedRowInfo[0]) },
                                { label: "Open Advertiser Analytics", action: () => openAdvertiserAnalytics(clickedRowInfo[0]) },
                            ],
                        });
                    }
                };

                const handleClickOutside = (event) => {
                    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                        setContextMenu(null);
                    }
                };


                document.addEventListener("contextmenu", handleContextMenu);
                document.addEventListener("mousedown", handleClickOutside);

                return () => {
                    document.removeEventListener("contextmenu", handleContextMenu);
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }
        }
    }, [gridRef, rows, onRowClick, currentColumns, periodColumns]);

    const openCampaignEdit = (campaignId) => {
        window.open(`/pub/customer/campaign/edit?id=${campaignId}`, "_blank");
    };

    const openAdvertiserPage = async (campaignId, path) => {
        const campaign = await getCampaing(campaignId);

        updateLocalStorageProperty(LocalStorageKeyNames.sidebarSkin, 'advertiser');
        updateLocalStorageProperty(LocalStorageKeyNames.advertiserId, campaign.data.account_id);
        navigate(`/adv/campaign/${path}?campaignId=${campaignId}`);
    };

    const openAdvertiserAuctions = (campaignId) => {
        return openAdvertiserPage(campaignId, 'ppc');
    };

    const openAdvertiserAnalytics = (campaignId) => {
        return openAdvertiserPage(campaignId, 'analytics');
    };


    const formatColumns = (currentColumns, periodColumns) => {
        let columns = [];
        let tempColumns = [...currentColumns, ...periodColumns];

        let modifiedCurrentColumns = currentColumns.map((column) => {
            const isFirstColumn = currentColumns.indexOf(column) === 1;
            const isLastColumn = currentColumns
            .filter((column) => !customizedColumns?.find((customizedColumn) => customizedColumn?.name === column)?.hidden)
            .indexOf(column) === currentColumns.length - 1;

            const colName = isLastColumn
                ? html(`<div class="last-current-column">${column}</div>`)
                : isFirstColumn
                    ? html(`<div class="first-current-column">${column}</div>`)
                    : column;

            return {
                name: colName,
                hidden: (column === "ID" || customizedColumns?.find((customizedColumn) => customizedColumn?.name === column)?.hidden),
                sort: customizedColumns?.find((customizedColumn) => customizedColumn?.name === column)?.sort,
                formatter: (_, row) => {
                    const col = row.cells[tempColumns.indexOf(column)];
                    const colValue = col.data;
                    const customizedColumn = customizedColumns?.find((customizedColumn) => customizedColumn?.name === column);
                    const formattedValue = customizedColumn?.formatter ? customizedColumn.formatter(colValue, row).props.content : colValue;

                    if (isLastColumn) {
                        return html(`
              <div class="current-column last-current-column">
                ${formattedValue}
              </div>
            `);
                    } else if (isFirstColumn) {
                        return html(`
              <div class="current-column first-current-column">
                ${formattedValue}
              </div>
            `);
                    } else {
                        return html(`
              <div class="current-column">
                ${formattedValue}
              </div>
            `);
                    }
                }
            };
        });

        let modifiedPeriodColumns = periodColumns.map((column) => {
            const isFirstColumn = periodColumns
            .filter((column) => !customizedColumns?.find((customizedColumn) => customizedColumn?.name === column)?.hidden)
            .indexOf(column) === 0;
            const colName = isFirstColumn ? html(`<div class="first-period-column">${column}</div>`) : column;

            return {
                name: colName,
                sort: customizedColumns?.find((customizedColumn) => customizedColumn?.name === column)?.sort,
                hidden: customizedColumns?.find((customizedColumn) => customizedColumn?.name === column)?.hidden,
                formatter: (_, row) => {
                    const col = row.cells[tempColumns.indexOf(column)];
                    const colValue = col.data;
                    const customizedColumn = customizedColumns?.find((customizedColumn) => customizedColumn?.name === column);
                    const formattedValue = customizedColumn?.formatter ? customizedColumn.formatter(colValue, row).props.content : colValue;

                    return html(`
            <div class="period-column">
              ${formattedValue}
            </div>
          `);
                }
            };
        });

        columns = [...modifiedCurrentColumns, ...modifiedPeriodColumns];
        
        return columns;
    };

    return (
        <div className="table-responsive cursor-pointer">
            <Grid
                data={rows}
                ref={gridRef}
                columns={formatColumns(currentColumns, periodColumns)}
                search={true}
                pagination={{
                    enabled: true,
                    limit: 10,
                }}
                resizable={false}
                sort={true}
                className={{
                    table: "table table-hover mb-0 splitted-table",
                }}
            />
            {contextMenu && (
                <CustomContextMenu
                    ref={contextMenuRef} // Attach ref to CustomContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    options={contextMenu.options}
                    onSelect={(option) => {
                        option.action();
                        setContextMenu(null);
                    }}
                />
            )}
        </div>
    );
}
