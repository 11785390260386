import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getInventoriesClicksDistribution(currentPeriod) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/missed-clicks/inventories-clicks-distribution', {
            current_period: currentPeriod
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getInventoriesClicksDistribution;
