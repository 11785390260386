import { editCustomer, editCampaign } from "../services/adv";
import { editInventoryCampaign, editUser, loadImages } from "../services/micro";
import { editCategory, editInventory, editWebsite } from "../services/pub";

async function editRecord(id, table_name, values) {
    try {
        if (!table_name) {
            throw new Error("Table name is required");
        }

        if (!values) {
            throw new Error("Values are required");
        }

        for (let key in values) {
            if (values[key] === null) {
                delete values[key];
            }
        }
        
        let response;

        if (table_name === 'publisher_categories') {
            response = await editCategory(id, values);
        }   
        
        if (table_name === 'publisher_websites') {
            response = await editWebsite(id, values);
        }

        if (table_name === 'publisher_inventory') {
            response = await editInventory(id, values);
        }

        if (table_name === 'clients_accounts') {
            response = await editCustomer(id, values);
        }

        if (table_name === 'client_campaigns') {
            response = await editCampaign(id, values);
        }

        if (table_name === 'users') {
            response = await editUser(id, values);
        }

        if (table_name === 'inventory_campaigns') {
            const imagesResponse = await loadImages(values.screenshot_files)
            
            if (imagesResponse?.success) {
                values.screenshot_files = imagesResponse.data.map(img=>img.id)
            }

            response = await editInventoryCampaign(id, values);
        }

        return response;
    } catch (error) {
        console.log(error);
    }
}

export default editRecord;
