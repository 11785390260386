import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Accordion, Button, Card, Col, Nav, Offcanvas, Form, OverlayTrigger, Modal, Row, Table, Badge, Tooltip } from "react-bootstrap";
import { Grid } from "gridjs-react";
import Avatar from "../../components/Avatar";

import img1 from "../../assets/img/img1.jpg";
import { getCampaign } from "../../services/adv";

function getShortMonthName(date) {
  // get next month
  date.setMonth(date.getMonth() + 1);
  return date.toLocaleString('default', { month: 'short' });
}

function getDaysDiff(date1, date2) {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export default function AdvertiserLeads() {
  // get 'campaignId' query value from url
  const campaignId = window.location.search.split('=')[1];
  const [campaign, setCampaign] = useState({});

  useLayoutEffect(() => {
    getCampaign(campaignId).then(res => {
      setCampaign(res.data);
    });
  }, []);

  const [showBottom, setShowBottom] = useState(false);
  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = () => setShowBottom(true);
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeTab, setActiveTab] = useState('#category_auctions');

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // change day2 for campaign.ppc_invoice_day
  const day2 = new Date().setMonth(new Date().getMonth() + 1);
  const days_diff = getDaysDiff(new Date(), new Date(day2).setDate(campaign.ppc_invoice_day));

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">ADV Home</Link></li>
              <li className="breadcrumb-item">{campaign.name}</li>
              <li className="breadcrumb-item active" aria-current="page">Lead Auctions</li>
            </ol>
            <h2 className="main-title mb-0">Lead Auctions</h2>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href=""><i className="ri-printer-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href=""><i className="ri-bar-chart-2-line"></i></Nav.Link>
            </OverlayTrigger>
          </Nav>

        </div>

        <Row className="g-3">
          <Col md="12" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <div className="pull-left" style={{ float: 'left', marginRight: '14px' }}>
                    <Avatar img={img1} size="xl" shape="rounded"  />
                    </div>
                    <h3 className="card-value fw-bold mb-0">{campaign.name}</h3>
                    <label className="card-title fw-medium text-dark mb-1">Promoted Product</label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Categories: <b>Trade Finance</b>, <b>US</b>, <b>+2 more</b></span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Please <strong>contact us</strong> to alter or cancel this campaign.
                </Tooltip>
              }
            >
              <Card className="card-one">
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <h3 className="card-value fw-bold text-success mb-0"><b>ACTIVE</b></h3>
                      <label className="card-title fw-medium text-dark mb-1">Campaign Status</label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">Your next billing day: <b>{campaign.ppc_invoice_day}. {getShortMonthName(new Date())}</b> (in {days_diff} days)</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </OverlayTrigger>
          </Col>
          <Col md="6" xl="4">
            <OverlayTrigger overlay={<Tooltip>Click to adjust your budget</Tooltip>}>
              <Card className="card-one cursor-pointer" onClick={handleShowBottom}>
                <Card.Body>
                  <Row>
                    <Col xs="12">
                      <h3 className="card-value mb-0"><b>${campaign.ppc_monthly_budget}</b></h3>
                      <label className="card-title fw-medium text-dark mb-1">Monthly Budget</label>
                      <span className="d-block text-muted fs-11 ff-secondary lh-4">Your spend this month: <b>${campaign.ppc_monthly_spend}</b></span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </OverlayTrigger>
          </Col>

  
          <Col md="12">
            <Card className="card-one card-one-single">
              <Card.Header className="border-bottom-0">
                <Card.Title as="h6">Configuration</Card.Title>
              </Card.Header>
            </Card>
          </Col>
          <Col md="12" className="mt-0">
            <Card className="border-radius-0">
              <Card.Header>
                <Nav 
                  variant="tabs" 
                  defaultActiveKey="#category_auctions"
                  onSelect={(selectedKey) => setActiveTab(selectedKey)}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="#category_auctions">Category Auctions</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#notifications">Notifications</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#disabled" disabled>
                      Integrations [v2]
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>

              {activeTab === '#category_auctions' && (
                
                <Card.Body>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Security Awareness Training Leads<Badge bg="success ms-2">Active</Badge></Accordion.Header>
                      <Accordion.Body>
                        <Row className="mb-3">
                          <Col sm="6">
                            <Form>
                              <Table bordered striped className="mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col" className="tw-bold">Users</th>
                                    <th scope="col" className="bid-column">Bid</th>
                                    <th scope="col">Position</th>
                                    <th scope="col">Min Bid</th>
                                    <th scope="col">Activate</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$100.00"
                                      style={{ maxWidth: '100px', minWidth: '75px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$100.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                        defaultChecked 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2 - 9</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$200.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-warning">2nd</td>
                                    <td>$200.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                        defaultChecked 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">10 - 24</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$300.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-danger">6th</td>
                                    <td>$300.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                        defaultChecked 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">25 - 50</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$400.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$400.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                        defaultChecked 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">50 - 100</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$500.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$500.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                        defaultChecked 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">100+</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$600.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$600.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                        defaultChecked 
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Form>
                          </Col>
                          <Col sm="6">
                            <Card>
                              <Card.Header className="fw-bold">Auto-Match Filters</Card.Header>
                              <Card.Body>
                                <Card.Text>
                                  <div className="mb-2">
                                    <Form.Label htmlFor="requirements">Requirements</Form.Label>
                                    <Form.Control 
                                      type="text" 
                                      value="Implementing Cyber Insurance, Industy/Legal Compliance, Phishing Simulations" 
                                      disabled 
                                      readOnly
                                      style={{ overflow: 'hidden' }} 
                                    />
                                  </div>
                                  <div className="mb-2">
                                    <Form.Label htmlFor="industries">Industries</Form.Label>
                                    <Form.Control 
                                      type="text" 
                                      value="Commercial, Non-Profit, Education, MSP/Reseller" 
                                      disabled 
                                      readOnly
                                      style={{ overflow: 'hidden' }} 
                                    />
                                  </div>
                                  <div>
                                    <Form.Label htmlFor="timeframe">Timeframe</Form.Label>
                                    <Form.Control 
                                      type="text" 
                                      value="1 - 6 Months, 7 - 12 Months, 12 - 18 Months, 18+ Months" 
                                      disabled 
                                      readOnly
                                      style={{ overflow: 'hidden' }} 
                                    />
                                  </div>
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer className="fst-italic">
                                To change any of these filters please <Link href="" className="">contact us</Link>.
                              </Card.Footer>
                            </Card>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Email Security Leads<Badge bg="danger ms-2">Inactive</Badge></Accordion.Header>
                      <Accordion.Body>
                        <Row className="mb-3">
                          <Col sm="6">
                            <Form>
                              <Table bordered striped className="mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col" className="tw-bold">Users</th>
                                    <th scope="col" className="bid-column">Bid</th>
                                    <th scope="col">Position</th>
                                    <th scope="col">Min Bid</th>
                                    <th scope="col">Activate</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$100.00"
                                      style={{ maxWidth: '100px', minWidth: '75px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$100.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation"  
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2 - 9</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$200.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-warning">2nd</td>
                                    <td>$200.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">10 - 24</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$300.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-danger">6th</td>
                                    <td>$300.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">25 - 50</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$400.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$400.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">50 - 100</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$500.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$500.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">100+</th>
                                    <td className="bid-column">
                                    <Form.Control 
                                      type="text" 
                                      id="user_tier_bid" 
                                      placeholder="$600.00"
                                      style={{ maxWidth: '100px' }}
                                      onInput={e => {
                                          e.target.value = e.target.value.replace(/[^0-9.,$]/g, '');
                                      }}
                                      onBlur={e => {
                                          let value = parseFloat(e.target.value.replace(/[$,]/g, ''));
                                          if (!isNaN(value)) {
                                              value = Math.round(value * 2) / 2; // Rounds to the nearest 0.5
                                              e.target.value = `$${value.toFixed(2)}`; // Format back to USD
                                          }
                                      }}
                                      pattern="^(\$)?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$"
                                      title="Please enter a valid USD amount (e.g., $1,234.56)"
                                    />
                                    </td>
                                    <td className="text-success">1st</td>
                                    <td>$600.00</td>
                                    <td>
                                      <Form.Check 
                                        type="checkbox" 
                                        name="user_tier_activation" 
                                        id="user_tier_activation" 
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Form>
                          </Col>
                          <Col sm="6">
                            <Card>
                              <Card.Header className="fw-bold">Auto-Match Filters</Card.Header>
                              <Card.Body>
                                <Card.Text>
                                  <div className="mb-2">
                                    <Form.Label htmlFor="requirements">Requirements</Form.Label>
                                    <Form.Control 
                                      type="text" 
                                      value="Implementing Cyber Insurance, Industy/Legal Compliance, Phishing Simulations" 
                                      disabled 
                                      readOnly
                                      style={{ overflow: 'hidden' }} 
                                    />
                                  </div>
                                  <div className="mb-2">
                                    <Form.Label htmlFor="industries">Industries</Form.Label>
                                    <Form.Control 
                                      type="text" 
                                      value="Commercial, Non-Profit, Education, MSP/Reseller" 
                                      disabled 
                                      readOnly
                                      style={{ overflow: 'hidden' }} 
                                    />
                                  </div>
                                  <div>
                                    <Form.Label htmlFor="timeframe">Timeframe</Form.Label>
                                    <Form.Control 
                                      type="text" 
                                      value="1 - 6 Months, 7 - 12 Months, 12 - 18 Months, 18+ Months" 
                                      disabled 
                                      readOnly
                                      style={{ overflow: 'hidden' }} 
                                    />
                                  </div>
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer className="fst-italic">
                                To change any of these filters please <Link href="" className="">contact us</Link>.
                              </Card.Footer>
                            </Card>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              )}

              {activeTab === '#notifications' && (
                <Card.Body>
                  <Col sm="6">
                    <Form>
                      <Row className="mb-3">
                        <Form.Label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Lead Notification Email(s)</Form.Label>
                        <Col sm="8">
                          <Form.Control type="email" id="inputEmail3" />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Form.Label htmlFor="inputPassword3" className="col-sm-4 col-form-label">Sales Phone Number</Form.Label>
                        <Col sm="8">
                          <Form.Control type="password" id="inputPassword3" />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Card.Body>
              )}

              {/* You can add more conditions for more tabs as needed. */}
            </Card>
          </Col>
  
          <Col md="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">{campaign.name} Leads</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
              <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Top 5 Trade Finance Providers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3">
                    <Col sm="12">
                      <Form.Label htmlFor="blockCpc" className="col-form-label">Inventory URL(s)</Form.Label><br/>
                      <Link href="https://revbox.co/sample-inventory"><Badge bg="primary">https://revbox.co/sample-inventory</Badge></Link>
                    </Col>
                    <Col sm="12">
                      <div className="divider mt-4"><span>Bid Management</span></div>
                    </Col>
                    <Col sm="12">
                      <Form>
                        <Row className="">
                          <Col sm="4">
                            <Form.Label htmlFor="blockCpc" className="col-form-label">Your Bid</Form.Label>
                            <Form.Control type="number" id="blockCpc" placeholder="$8.00" />
                          </Col>
                          <Col sm="8">
                            <Card className="mt-2">
                              <Card.Body className="card-modal">
                                <Row className="justify-content-end text-end text-muted">
                                  <Col xl="4">Est. Pos.:<br/><span className="text-success">#3</span></Col>
                                  <Col xl="4">Min Bid:<br/><span className=" text-muted">$8.00</span></Col>
                                  <Col xl="4">Top Bid:<br/><span className="text-muted">$32.00</span></Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col sm="12">
                          <div className="divider"><span>Customization</span></div>
                          </Col>
                          <Col sm="12">
                            <Form.Label htmlFor="landingPageLink">Landing Page URL</Form.Label>
                            <Form.Control type="url" id="landingPageLink" placeholder="https://example.com/langing-page" />
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save Bid
                  </Button>
                </Modal.Footer>
              </Modal>
                <Offcanvas show={showBottom} onHide={handleCloseBottom} placement="bottom">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="m-auto">ABC Bank - Monthly Budget</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Row className="mb-3">
                      <Col xl="12" sm="10" md="6" className="m-auto">
                        <Form>
                          <Row className="mb-3">
                            <Form.Label htmlFor="blockCpc" className="col-sm-3 col-form-label">New Budget</Form.Label>
                            <Col sm="5">
                              <Form.Control type="number" id="blockCpc" placeholder="$20,000.00" />
                            </Col>
                            <Col sm="4">
                              <Row className="justify-content-end">
                                <Col xl="12"><Button type="submit" variant="primary"  className="float-end block">Save Budget</Button></Col>
                              </Row>
                            </Col>
                            <Col sm="12">
                              <Row className="justify-content-end">
                                <Col xl="12"><span className="d-block text-muted fs-11 ff-secondary lh-4 mt-4 text-center">Your spend this month: <b>$24,543.00</b></span></Col>
                                <span className="d-block text-muted fs-11 ff-secondary lh-4 text-center">Your next billing day: <b>1. Sep</b> (in 28 days)</span>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Offcanvas.Body>
                </Offcanvas>
                <div className="table-responsive cursor-pointer" onClick={handleShow}>
                  <Grid
                    data={[
                      ['Jane Doe', 'Apple Inc.', 'Commercial', 'jane@sample.com', '+1 23456789', '24', 'Security Awareness Training', 'Phishing Simulations', '1 - 6 Months']
                    ]}
                    columns={['Name', 'Company', 'Industry', 'Email', 'Phone', 'Users', 'Category', 'Requirements', 'Timeframe']}
                    search={true}
                    pagination={{
                      enabled: true,
                      limit: 15
                    }}
                    resizable={true}
                    sort={true}
                    className={{
                      table: 'table table-hover mb-0'
                    }}
                    rowClick={(_, row) => {
                      window.location.href = `your-link-here/${row.cells[0].data}`; // replace 'your-link-here' with the base URL
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}