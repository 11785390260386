import React, { useEffect, useState } from "react";
import { getAllCustomers } from "../../services/adv";
import DataTable from "../../components/DataTable";
import { toast } from "react-toastify";
import TablePagePlaceholder from "../../components/TablePagePlaceholder";
import { getToken } from "../../helpers/get-token";
import { useNavigate } from "react-router-dom";

export default function PublisherAccounts() {
  const [data, setData] = useState([]);
  const token = getToken();
  const endpoint = process.env.REACT_APP_HUB_URL + `/api/clients-accounts/all?token=${token}`;

  const navigate = useNavigate();

  useEffect(() => {
    getAllCustomers().then(res => {
      setData(res);
    }).catch(e => {
      toast.error("You don't have permission to access this page.");
    });
  }, []);
  
  return (
    <React.Fragment>
      { data?.table ?
        <DataTable 
          title="Accounts"
          data={data}
          endpoint={endpoint}
          // breadcrumb="Customers"
          // breadcrumb_redirect="/pub/customers/accounts"
          breadcrumb="Customers"
          new_btn_title="New Acccount"
          new_btn_redirect="/pub/customer/new"
          edit_redirect="/pub/customer/edit"
          redirect="/pub/customers/accounts"
        />
        :
        <TablePagePlaceholder
          title="Accounts"
          data={data}
          breadcrumb="Customers"
          // breadcrumb="Accounts"
          // breadcrumb_redirect="/pub/customers/accounts"
          new_btn_title="Customers"
          new_btn_redirect="/pub/customer/new"
          edit_redirect="/pub/customer/edit"
          redirect="/pub/customers/accounts"
        />
      }
    </React.Fragment>
  )
}